@import '../../../styles/common/media_queries';

.modal_wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(112, 112, 112, 0.7);
  z-index: 10;
  .delete_modal {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    background-color: #fff;
    padding: 20px 30px 15px 30px;
    @include media_query(XS) {
      min-width: 315px;
      min-height: 230px;
      max-width: 90%;
      max-height: 450px;
    }
    @include media_query(M) {
      min-width: 390px;
      min-height: 230px;
      max-width: 400px;
      max-height: 400px;
    }
    .header_wrapper {
      padding: 0 10px 35px 0;

      .heading {
        font-family: 'SourceSansPro';
        font-size: 24px;
        font-weight: 700;
        color: #2c2c2c;
      }
      &.cst_header_wrapper {
        display: flex;
        align-items: center;
        height: 72px;
        padding: 20px 35px 20px 30px;
        margin: -20px -30px 24px -30px;
        box-shadow: 0px 2px 16px rgb(44 44 44 / 10%);
      }
    }

    .close_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 32px;
      background-color: #2c2c2c;
      cursor: pointer;

      .close_icon {
        width: 10px;
        height: 10px;
        background-image: url(../../../styles/images/close-white.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .warning_section {
      display: flex;
      background: #fff9f0;
      border: 1px solid #d1b278;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 30px;

      .warning_icon {
        width: 18px;
        height: 18px;
        background-image: url(../../../styles/images/warning-icon.svg);
        background-repeat: no-repeat;
        background-size: contain;
        &.cst_warning {
          @include media_query(XS) {
            position: relative;
            top: 5px;
            width: 27px;
            height: 18px;
          }
          @include media_query(M) {
            top: 3px;
            width: 25px;
            height: 18px;
          }
          @include media_query(L) {
            width: 25px;
            height: 18px;
          }
        }
      }

      .message_section {
        padding-left: 5px;

        .mb_5 {
          margin-bottom: 5px;
        }

        .heading {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 700;
          color: #a36a00;
        }

        .sub_heading {
          font-family: 'SourceSansPro';
          font-size: 14px;
          color: #a36a00;
        }

        &.cst_delete {
          .heading {
            font-weight: 500;
            color: #a66400;
          }
          .bold_txt {
            font-weight: 700;
            color: #a66400;
            font-style: italic;
          }
        }
      }
    }

    .confirm_wrapper {
      padding-bottom: 45px;

      .txt {
        font-family: 'SourceSansPro';
        font-size: 14px;
        color: #2c2c2c;
        margin-bottom: 10px;
      }

      .input_wrapper {
        position: relative;
        margin-bottom: 10px;
        .input {
          width: 100%;
          height: 35px;
          line-height: 36px;
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 500;
          color: #2c2c2c;
          padding: 0 10px;
          border: 2px solid #2c2c2c;
          border-radius: 10px;
          &.border_bold {
            border: 3px solid #2c2c2c;
            &.error_wrapper {
              border: 3px solid #d91734;
            }
          }
          &::placeholder {
            color: #707070;
          }
          &.error_wrapper {
            border: 2px solid #d91734;
            border-radius: 10px;
          }
        }
      }
    }

    .confirm_msg {
      text-align: center;

      .txt {
        display: inline-block;
        vertical-align: middle;
        font-family: 'SourceSansPro';
        font-size: 16px;
        color: #2c2c2c;
      }

      .bold {
        font-family: 'SourceSansPro';
        font-weight: 700;
      }
    }

    .btn_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      &.border_bold {
        .btn {
          border: 3px solid #2c2c2c;
          @include media_query(XS) {
            line-height: 3px;
          }
          @include media_query(M) {
            line-height: initial;
          }
          &.active {
            border: 3px solid transparent;
          }
          &.disabled {
            border: 3px solid transparent;
          }
          &.save_changes {
            border: 3px solid transparent;
          }
        }
      }
      .btn {
        min-width: 120px;
        height: 35px;
        font-family: 'SourceSansPro';
        font-size: 16px;
        font-weight: 700;
        border: 2px solid #2c2c2c;
        background-color: #fff;
        border-radius: 24px;
        margin: 0 6px;
        padding: 0 30px;
        cursor: pointer;
        &.border_bold {
          border: 3px solid #2c2c2c;
        }
        &.active {
          background-color: #d91734;
          border: 2px solid transparent;
          color: #fff;
        }

        &.save_changes {
          background-color: #2c2c2c;
          border: 2px solid transparent;
          color: #fff;
        }

        &.disabled {
          pointer-events: none;
          background-color: #5e5e5e;
          color: #fff;
          border: 2px solid transparent;
        }
      }
    }
  }
}
.error_icon_red {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 20px;
  height: 20px;
  background-image: url(../../../styles/images/error_icon_red.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.error_message {
  color: #d91734;
  font-family: 'SourceSansPro';
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
