.badge {
    display: inline-block;
    padding: 0px 8px;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    font-weight: 500;

    &.badge-warning {
        color: #62625A;
        background-color: #F8F8E7;
    }
    
    &.badge-success {
        color: #107F47;
        background-color: #F0FAE5;
    }
}
