@import '../../../styles/common/media_queries';

.labelContainer {
  display: flex;
  justify-content: space-between;

  span {
    color: #707070;
  }
}

.drop_down {
  position: relative;
  &.drop_down_margin_left {
    margin-left: 12px;
  }
  .selected_arrow_item {
    border-radius: 12px;
    border: 2px solid var(--primary-1-amway-black, #2C2C2C);
    background: var(--primary-2-pure-white, #FFF);  
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    &.selected_arrow_item_with_min_width {
      // min-width: 320px;
      width: 320px;
    }
    &.isDisabled {
      border: 1px solid var(--gray-30-light-gray-rule-lines, #E4E4E4);
      background: var(--gray-28-light-gray-small-bgs, #F4F4F4);
      .arrow_icon {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .selected_item {
      // color: var(--gray-33-dark-gray-text-on-white-bg, #707070);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    .selected_item_placeholder {
      color: #707070;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      &.active {
        color: #2C2C2C
      }
    }
    .arrow_icon {
      width: 18px;
      height: 10px;
      //   background-image: url('../../../styles/images/down_arrow_black.svg');
      background-image: url('../../../styles/images/down_arrow_2.svg');
      background-repeat: no-repeat;
      cursor: pointer;
      margin-left: 20px;
    }
    .up_arrow_icon {
      width: 18px;
      height: 10px;
      background-image: url('../../../styles/images/up_arrow_black.svg');
      background-repeat: no-repeat;
      cursor: pointer;
      margin-left: 20px;
    }

    @media screen and (max-width: 992px) {
      height: 18px;
      margin-bottom: 24px;
    }
    
  }
  .list {
    margin-top: 10px;
    position: absolute;
    z-index: 1000;
    display: flex;
    min-width: 100%;
    padding: 2px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    border: 2px solid var(--3-Dark-Purple, #38539a);
    background: var(--2-Pure-White, #fff);
    overflow: hidden;

    @media screen and (max-width: 992px) {
      margin-top: -21px;
    }

    &.list_with_min_width {
      min-width: 320px;
    }
    .list_item {
      display: flex;
      height: 48px;
      padding: 8px 12px;
      align-items: center;
      gap: 20px;
      width: 100%;
    }
    .list_item_selected {
      display: flex;
      height: 48px;
      padding: 8px 12px;
      align-items: center;
      gap: 20px;
      color: var(--3-Dark-Purple, #38539a);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      width: 100%;
    }
  }
  .list :hover {
    cursor: pointer;
    // min-width: 320px;
    background-color: var(--28-Light-Gray-Small-Backgrounds, #f4f4f4);
  }
}

.filterContainer {
  display: none;
  @include media_query(ML) {
    display: flex;
    margin-bottom: 10px;
  }
  // @include media_query(ML) {
  //   display: flex;
  // }
}

.inputField {
  //     border-radius: 12px;
  // border: 1px solid var(--gray-30-light-gray-rule-lines, #E4E4E4);
  // background: var(--primary-2-pure-white, #FFF);
  display: flex;
  padding: 16px;
  width: 0px;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--gray-30-light-gray-rule-lines, #E4E4E4);
  background: var(--primary-2-pure-white, #FFF);
  min-width: 84px;
  color: #2C2C2C;
  font-weight: 500;

  @media screen and (max-width: 992px) {
    height: 18px;
    margin-bottom: 24px;
    width: 100%;
  }
  &.disabled{
    border: 1px solid var(--gray-30-light-gray-rule-lines, #e4e4e4);
    background: var(--gray-28-light-gray-small-bgs, #f4f4f4);
    pointer-events: none;
    cursor: not-allowed;
  }
  &.active {
    color: #2C2C2C;
    border: 2px solid var(--primary-1-amway-black, #2C2C2C);
  }
  &:not(.disabled)::placeholder { 
    color: #2C2C2C;
    font-size: 16px;
    font-weight: 500;
  }
}

.buttonStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border: none;
  background-color: #2C2C2C;
  color: #fff;
  padding: 16px 32px;
  cursor: pointer;

  @media screen and (max-width: 992px) {
    padding: 6px 32px;
    margin-bottom: 12px;
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
    color: #5e5e5e;
  }
}

.secondaryBtn {
  border-radius: 100px;
  border: 2px solid var(--primary-1-amway-black, #2C2C2C);
  background: var(--primary-2-pure-white, #FFF);
  color: #2C2C2C;
}

.actionBtnContainer {
  cursor: pointer;
  margin-left: 8px;
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f1f2ff;
  &.disabled{
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.add_delete_icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsiveBtnStyleWhiteThemed {
  width: 100%;
  border-radius: 100px;
  border: 2px solid var(--primary-1-amway-black, #2c2c2c);
  background: var(--primary-2-pure-white, #fff);
  .responsiveBtnTxt {
    color: var(--primary-1-amway-black, #2c2c2c);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
  }
}

.responsiveBtnStyleDarkThemed {
  width: 100%;
  border-radius: 100px;
  background: var(--primary-1-amway-black, #2c2c2c);

  .responsiveBtnTxt {
    color: var(--primary-2-pure-white, #fff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
  }
}

.commonBtnStyle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 32px;
    margin-bottom: 12px;
}

.filterMobile{
    position: fixed;
    background-color: #fff;
    bottom: 0px;
    left: 0px;
    margin-left: 0;
    width: 100%;
    padding: 16px 30px 10px 120px;
    filter: drop-shadow(0px -2px 6px rgba(44, 44, 44, 0.10));
    background: white;
    @include media_query(ML){
        display: none;
    }
}
