@import '../../../styles/common/media_queries';

.table_container {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  width: 100%;
  @include media_query(XS) {
    //  height: calc(100vh - 190px);
    padding: 0 16px;
    padding-top: 4px;
  }

  @include media_query(M) {
    height: fit-content;
    padding: 0 40px;
  }
  &.sticky_header_user_grp {
    position: relative;
    overflow: initial;
    height: fit-content;
    .tbl_header_white {
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: #fff;
      padding: 35px 60px 25px 60px;
      height: 85px;
    }
    .tbl_body {
      overflow: hidden !important;
      padding-top: 0 !important;
      padding: 0 20px;
      .tbl_row {
        padding: 0 40px;
      }
    }
  }
  &.is_drawer_open {
    @include media_query(M) {
      padding: 0 32px 0 40px;
    }
  }
  &.is_white_header {
    @include media_query(M) {
      padding: 0px;
    }
    .tbl_body {
      @include media_query(S) {
        height: calc(100% - 260px) !important ;
      }
      @include media_query(M) {
        height: 100% !important ;
      }
      @include media_query(S) {
        height: calc(100% - 260px) !important ;
      }
    }
  }
  // Category Skills Tags Start
  &.category_main_wrapper {
    position: relative;
    @include media_query(L) {
      height: calc(100vh - 200px);
      overflow: auto;
    }
    .tbl_header {
      .tbl_head {
        &:first-child {
          display: flex;
          align-items: center;
          gap: 8px;
          img {

          }
          .type_heading{
            @include media_query(XS){
              display: none;
            }
            @include media_query(ML){
              display: block;
            }
          }
          @include media_query(XS) {
            width: 5%;
          }
          @include media_query(M) {
            display: flex;
            width: 12%;
          }
          &.drawer_open {
            @include media_query(M) {
              display: flex;
              width: 17%;
            }
          }
        }
        // &:nth-child(2) {
        //   width: 20%;
        //   @include media_query(XS) {
        //     // display: none;
        //     // width: 0%;
        //     width: 19%;
        //   }
        //   @include media_query(M) {
        //     display: flex;
        //     width: 20%;
        //   }
        // }
        &:last-child {
          @include media_query(XS) {
            width: 20%;
          }
          @include media_query(M) {
            width: 20%;
          }
        }
      }
    }

    .tbl_body {
      .tbl_row_wrapper {
        .tbl_row {
          .tbl_data {
            .courses_icon {
              display: inline-block;
              vertical-align: middle;
              width: 15px;
              height: 17px;
              background-image: url(../../../styles/images/files.svg);
              background-size: contain;
              background-repeat: no-repeat;
              margin-right: 4px;
            }
            .learning_path_icon {
              display: inline-block;
              vertical-align: middle;
              width: 19px;
              height: 18px;
              background-image: url(../../../styles/images/folder.svg);
              background-size: cover;
              background-repeat: no-repeat;
              margin-right: 4px;
            }
            .inner_data {
              display: inline-block;
              @include media_query(M){
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .txt {
                display: inline;
                @include media_query(S){
                  word-wrap: break-word;
                  overflow-wrap: break-word;
                }
              }
            }
            &:first-child {
              align-items: center;
              .inner_data{
                display: flex;
                text-overflow: ellipsis;
              }
              img{
                margin-right: 8px;
                min-width: 16px;
              }
              
              @include media_query(XS) {
                width: 5%;
              }
              @include media_query(M) {
                display: flex;
                width: 12%;
              }
              .inner_data {
                padding-right: 0;
                margin-right: 10px;
              }
              .txt {
                font-weight: 700;
                @include media_query(XS){
                display: none;
              }
                @include media_query(ML){
                  display: flex;
                }
              }
              &.drawer_open {
                @include media_query(M) {
                  display: flex;
                  width: 17%;
                }
              }
            }
            // &:nth-child(2) {
            //   order: initial;
            //   @include media_query(XS) {
            //     width: 19%;
            //   }
            //   @include media_query(M) {
            //     display: flex;
            //     width: 20%;
            //   }
            //   .inner_data {
            //     @include media_query(M) {
            //       margin: 0 6px;
            //       &:first-child {
            //         margin-left: 0;
            //       }
            //     }
            //     .txt {
            //       font-weight: 500;
            //     }
            //   }
            // }
            // &:nth-child(3) {
            //   @include media_query(XS) {
            //     width: 19%;
            //   }
            //   @include media_query(M) {
            //     width: 20%;
            //   }
            //   .inner_data {
            //     @include media_query(XS) {
            //       padding-right: 20px;
            //     }
            //     @include media_query(L) {
            //       padding-right: 40px;
            //     }
            //     .txt {
            //       font-weight: 500;
            //     }
            //   }
            // }
            // &:nth-child(4) {
            //   @include media_query(XS) {
            //     width: 19%;
            //   }
            //   @include media_query(M) {
            //     width: 20%;
            //   }
            //   .inner_data {
            //     @include media_query(XS) {
            //       padding-right: 20px;
            //     }
            //     @include media_query(L) {
            //       padding-right: 40px;
            //     }
            //     .txt {
            //       font-weight: 500;
            //     }
            //   }
            // }
            &:last-child {
              @include media_query(XS) {
                display: flex;
                flex-direction: column;
                width: 19%;
              }
              @include media_query(M) {
                display: flex;
                flex-direction: row;
                width: 20%;
              }
              .inner_data {
                @include media_query(XS) {
                  padding-right: 20px;
                }
                @include media_query(L) {
                  padding-right: 40px;
                }
                .txt {
                  font-weight: 500;
                }
                &:last-child {
                  @include media_query(XS) {
                    padding-right: 0px;
                  }
                  @include media_query(L) {
                    padding-right: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // Category Skills Tags Ends

  .tbl_header {
    display: flex;
    align-items: center;
    width: 100%;
    background: #2c2c2c;
    box-shadow: 2px 2px 4px rgba(44, 44, 44, 0.1);
    border-radius: 12px;
    padding: 0 15px;
    // margin-bottom: 15px;

    @include media_query(XS) {
      height: 40px;
    }

    @include media_query(M) {
      padding: 0 40px;
      // margin: 0 40px 0;
      height: 65px;
    }

    .people_icon {
      width: 22px;
      height: 16px;
      // background-image: url(../../styles/images/people-white.svg);
      background-image: url(../../../styles/images/people-white.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    .courses_icon {
      width: 14px;
      height: 16px;
      background-image: url(../../../styles/images/files-white.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    .learning_icon {
      width: 18px;
      height: 16px;
      background-image: url(../../../styles/images/folder-white.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    .tbl_head {
      display: flex;
      align-items: center;
      font-family: 'gtWalsheim';
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: #fff;
      text-transform: capitalize;
      @include media_query(M) {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      .txt {
        @include media_query(M) {
          // display: none;
        }

        @include media_query(L) {
          display: block;
          font-family: 'gtWalsheim';
          font-size: 16px;
          font-weight: 500;
          color: #fff;
        }
      }

      &:nth-child(1) {
        @include media_query(XS) {
          width: 70%;
        }
        @include media_query(M) {
          width: 80%;
        }
      }

      &:nth-child(2) {
        @include media_query(XS) {
          width: 30%;
        }
        @include media_query(M) {
          width: 20%;
        }
        .txt {
          padding-left: 10px;
        }
      }
    }
  }
  .tbl_header_white {
    display: flex;
    align-items: center;
    width: 100%;
    background: #ffffff;
    // box-shadow: 2px 2px 4px rgba(44, 44, 44, 0.1);
    border-radius: 12px;
    padding: 0 15px;
    // margin-bottom: 15px;

    @include media_query(XS) {
      height: 40px;
    }

    @include media_query(M) {
      padding: 0 40px;
      // margin: 0 40px 0;
      height: 65px;
    }

    .people_icon {
      width: 22px;
      height: 16px;
      // background-image: url(../../styles/images/people-white.svg);
      background-image: url(../../../styles/images/people-white.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    .courses_icon_white {
      width: 14px;
      height: 16px;
      background-image: url(../../../styles/images/file_black.svg);
      background-size: contain;
      background-repeat: no-repeat;
      padding-right: 8px;
    }

    .learning_icon {
      width: 18px;
      height: 16px;
      background-image: url(../../../styles/images/folder-white.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    .tbl_head_white {
      display: flex;
      align-items: center;
      font-family: 'gtWalsheim';
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: #2c2c2c;
      text-transform: capitalize;
      @include media_query(M) {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
      .txt_white {
        @include media_query(M) {
          // display: none;
          padding-left: 8px;
        }

        @include media_query(L) {
          display: block;
          font-family: 'gtWalsheim';
          font-size: 16px;
          font-weight: 700;
          color: #2c2c2c;
          padding-left: 8px;
        }
        @include media_query(XS) {
          padding-left: 8px;
        }
      }

      &:nth-child(1) {
        @include media_query(XS) {
          width: 70%;
        }
        @include media_query(M) {
          width: 80%;
        }
      }

      &:nth-child(2) {
        @include media_query(XS) {
          width: 30%;
        }
        @include media_query(M) {
          width: 20%;
        }
        .txt {
          padding-left: 10px;
        }
      }
    }
  }
  .tbl_body {
    width: 100%;
    height: calc(100vh - 230px);
    overflow-y: auto;
    @include media_query(XS) {
      padding: 0;
      padding-bottom: 115px;
    }

    @include media_query(M) {
      padding: 16px 0 0;
      padding-bottom: initial;
      height: calc(100vh - 270px);
    }

    .courses_icon {
      @include media_query(XS) {
        width: 14px;
        height: 16px;
        background-image: url(../../../styles/images/files.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 12px;
      }

      @include media_query(M) {
        display: none;
      }
    }
    .courses_icon_white {
      @include media_query(XS) {
        width: 14px;
        height: 16px;
        background-image: url(../../../styles/images/file_black.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 12px;
      }

      @include media_query(M) {
        display: none;
      }
    }

    .learning_icon {
      @include media_query(XS) {
        width: 18px;
        height: 16px;
        background-image: url(../../../styles/images/folder.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 12px;
      }

      @include media_query(M) {
        display: none;
      }
    }

    .people_icon {
      @include media_query(XS) {
        width: 20px;
        height: 16px;
        background-image: url(../../../styles/images/people.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 12px;
        opacity: 0.7;
      }

      @include media_query(M) {
        display: none;
      }
    }

    .tbl_row_wrapper {
      @include media_query(XS) {
        display: block;
        min-height: 60px;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid #e4e4e4;
        padding: 24px 16px 16px;
        //  margin: 0 15px;
        // margin-bottom: 15px;
      }
      @include media_query(M) {
        display: block;
        min-height: 65px;
        padding: 0;
        margin: 0;
        padding-bottom: 0;
        margin: 0 4px;
        margin-bottom: 16px;
        border: none;
        // background: #FFFFFF;
        // box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
        border-radius: 12px;
      }
      &:first-child {
        @include media_query(XS) {
          // padding-bottom: 0;
        }
        @include media_query(M) {
          padding: 0;
        }
      }

      &.selected {
        background: #f4f4f4;
        @include media_query(XS) {
          padding: 15px;
          border-bottom: none;
          margin: 0;
          margin-bottom: 15px;
          border-radius: 0;
        }
        @include media_query(M) {
          padding: 0;
          border-radius: 12px;
        }
      }
      //  .row_title{
      //    font-family: "gtWalsheim";
      //    font-size: 16px;
      //    font-weight: 700;
      //    line-height: 24px;
      //    color: #2c2c2c;
      //    margin-bottom: 20px;
      //    @include media_query(M){
      //      margin-bottom: 8px;
      //    }

      //  }
    }

    .action_wrapper_xs {
      @include media_query(XS) {
        display: flex;
        width: 100%;
        padding: 25px 0;
        margin-top: 25px;
        border-top: 1px solid #e4e4e4;
        border-bottom: 1px solid #e4e4e4;
      }

      @include media_query(M) {
        display: none;
        width: auto;
      }

      .btn_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 6px;
        margin-top: auto;
        width: 50%;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 120px;
          width: 100%;
          height: 35px;
          font-family: 'gtWalsheim';
          font-size: 16px;
          font-weight: 700;
          border: 2px solid #2c2c2c;
          background-color: #fff;
          border-radius: 24px;
          padding: 0 30px;
          cursor: pointer;

          &.active {
            background-color: #2c2c2c;
            border: 2px solid transparent;
            color: #fff;
          }

          &.disabled {
            pointer-events: none;
            background-color: #d9d9d9;
            color: #5e5e5e;
            border: 2px solid transparent;
          }
        }

        .delete_icon {
          width: 16px;
          height: 18px;
          background-image: url(../../../styles/images/delete-black.svg);
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 10px;

          &:hover {
            background-image: url(../../../styles/images/delete-red.svg);
          }
        }

        .edit_icon {
          width: 21px;
          height: 18px;
          background-image: url(../../../styles/images/pencil.svg);
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 10px;

          &:hover {
            background-image: url(../../../styles/images/edit-blue.svg);
          }
        }
      }
    }

    .tbl_row {
      position: relative;
      display: flex;
      align-items: center;
      // flex-wrap: wrap;
      border-radius: 8px;

      // padding: 0 20px;
      // margin-bottom: 15px;
      &:hover {
        @include media_query(XS) {
          background-color: transparent;
        }

        @include media_query(M) {
          background: #f4f4f4;
          cursor: pointer;
        }
      }
      &.selected {
        @include media_query(M) {
          background-color: #f1f2ff;
        }
      }

      @include media_query(XS) {
        padding: 0;
        width: 100%;
        min-height: auto;
      }

      @include media_query(M) {
        padding: 0 40px;
        width: auto;
        min-height: 65px;
        background: #ffffff;
        box-shadow: 0px 2px 11px rgba(44, 44, 44, 0.1);
        border-radius: 12px;
      }

      &:last-child {
        // margin-bottom: 0;
        // border-bottom: none;
      }

      .user_icon {
        width: 21px;
        height: 15px;
        background-image: url(../../../styles/images/people.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }

      .blue_book {
        width: 14px;
        height: 16px;
        background-image: url(../../../styles/images/blue-book.svg);
        background-repeat: no-repeat;
      }

      .delete_icon {
        width: 16px;
        height: 18px;
        background-image: url(../../../styles/images/delete-black.svg);
        background-repeat: no-repeat;
        background-size: contain;

        &:hover {
          background-image: url(../../../styles/images/delete-red.svg);
        }
      }

      .edit_icon {
        width: 21px;
        height: 18px;
        background-image: url(../../../styles/images/edit-black.svg);
        background-repeat: no-repeat;
        background-size: contain;

        &:hover {
          background-image: url(../../../styles/images/edit-blue.svg);
        }
      }

      .blue_files {
        width: 18px;
        height: 16px;
        background-image: url(../../../styles/images/blue-files.svg);
        background-repeat: no-repeat;
      }

      .down_arrow_slim {
        background-image: url(../../../styles/images/down-arrow-slim.svg);
        background-repeat: no-repeat;
        background-size: contain;
        @include media_query(XS) {
          width: 15px;
          height: 14px;
        }
        @include media_query(M) {
          width: 21px;
          height: 15px;
        }
        &:hover {
          background-image: url(../../../styles/images/down-arrow-slim-blue.svg);
        }
      }

      .tbl_data {
        display: flex;
        align-items: center;

        .inner_data {
          display: flex;
          align-items: center;
          cursor: pointer;

          &.full_width {
            width: 100%;
            cursor: default;
          }
        }

        .txt {
          font-family: 'gtWalsheim';
          font-size: 16px;
          color: #2c2c2c;
        }

        .pr_5 {
          padding-right: 5px;
        }

        .pl_10 {
          padding-left: 10px;
        }

        .pl_15 {
          padding-left: 15px;
        }

        .tbl_buttton {
          display: flex;
          align-items: center;
          cursor: pointer;

          &:nth-child(1) {
            margin-right: 24px;
          }

          &:nth-child(3) {
            margin-left: 24px;
            // border-left: 1px solid #2c2c2c;
          }

          &.collapsed {
            .down_arrow_slim {
              transform: rotate(180deg);
            }
          }
        }

        .divider {
          width: 1px;
          height: 25px;
          border: 1px solid #2c2c2c;
          opacity: 0.5;
        }

        &.highlight_blue {
          &.border_none {
            .inner_data {
              border-bottom: none;

              &:hover {
                border-bottom: none;
              }
            }

            .txt {
              font-family: 'gtWalsheim';
              font-weight: 400;
            }
          }

          .inner_data {
            @include media_query(XS) {
              border-bottom: none;
              padding-bottom: 0;
            }

            @include media_query(M) {
              // padding-bottom: 4px;
              // border-bottom: 2px solid #2c2c2c;
            }
          }

          .txt {
            color: #2c2c2c;
            font-family: 'gtWalsheim';
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
          }
        }

        &:nth-child(1) {
          @include media_query(XS) {
            width: 70%;
            .inner_data {
              padding-right: 16px;
            }
            .txt {
              color: #2c2c2c;
              font-family: 'gtWalsheim';
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
            }
          }
          @include media_query(M) {
            width: 80%;
            margin-bottom: 0;

            .inner_data {
              padding-right: 30px;
            }

            .txt {
              color: #2c2c2c;
              font-family: 'gtWalsheim';
              font-weight: 500;
              font-size: 16px;
            }
          }
          @include media_query(L) {
            margin-bottom: 0;

            .inner_data {
              padding-right: 64px;
            }

            .txt {
              color: #2c2c2c;
              font-family: 'gtWalsheim';
              font-weight: 500;
            }
          }
        }

        &:nth-child(2) {
          width: 15%;
          @include media_query(XS) {
            width: auto;
            //   margin-right: 40px;
            order: 1;
          }
          @include media_query(M) {
            width: 20%;
            margin: 0;
            // order: initial;
          }
          @include media_query(L) {
            margin: 0;
            // order: initial;
          }
        }
      }
    }

    .row_filter_wrapper {
      display: flex;
      align-items: center;

      // margin: 25px 0 40px 0;
      @include media_query(XS) {
        align-items: flex-start;
        flex-direction: column;
        padding: 0;
      }

      @include media_query(M) {
        padding: 15px 40px 20px 40px;
      }
      @include media_query(L) {
        flex-direction: row;
      }

      .tags_wrapper {
        padding-left: 55px;
        // width: 40%;
        border-left: 1px solid #e4e4e4;

        @include media_query(XS) {
          padding: 25px 0 20px 0;
          border-left: none;
          width: 100%;
          border-bottom: 1px solid #e4e4e4;
        }

        @include media_query(M) {
        }
        @include media_query(L) {
          width: auto;
          padding: 0;
          border-left: 1px solid #e4e4e4;
          border-bottom: none;
        }

        &:nth-child(2) {
          padding-left: 0;
          border-left: none;

          @include media_query(XS) {
            width: 100%;
          }

          @include media_query(L) {
            width: 42%;
          }
        }

        &:last-child {
          @include media_query(XS) {
            padding-left: 0;
            padding-bottom: 5px;
            border-bottom: 0;
          }

          @include media_query(M) {
            width: 58%;
            padding-bottom: 0;
          }
          @include media_query(L) {
            padding-left: 40px;
          }
        }

        &.w_60 {
          @include media_query(XS) {
            width: 100%;
          }

          @include media_query(L) {
            width: 60%;
          }
        }

        .label {
          font-family: 'gtWalsheim';
          font-size: 16px;
          font-weight: 500;
          color: #2c2c2c;
          padding-bottom: 10px;

          &.bold {
            font-weight: 700;
          }
        }

        .input_wrapper {
          margin-bottom: 12px;

          input {
            width: 345px;
            height: 36px;
            padding: 0 12px;
            border-radius: 10px;
            border: 2px solid #2c2c2c;
            background-color: #fff;
          }
        }

        .filter_main_wrapper {
          position: relative;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 12px;

          .filter_wrapper {
            position: relative;
            margin: 0 6px;

            &:first-child {
              margin-left: 0;
            }

            .filter {
              display: flex;
              align-items: center;
              height: 36px;
              padding: 0 12px;
              border: 2px solid #2c2c2c;
              border-radius: 10px;
              cursor: pointer;

              &.w_345 {
                width: 345px;
              }

              &.w_185 {
                width: 185px;
              }

              .txt {
                font-family: 'gtWalsheim';
                font-size: 16px;
                font-weight: 500;
                color: #707070;
              }
            }
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e4e4e4;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }

  .gridView{
      width: 100%;
      margin-top: 7px;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      height: fit-content;
      padding-bottom: 25px;
    }
}