@import '../../../styles/common/media_queries';

.label_wrapper {
  font-family: 'SourceSansPro';
  font-size: 16px;
  color: #2c2c2c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  .tag_text {
    font-family: 'SourceSansPro';
    font-size: 14px;
    color: #707070;

    @include media_query(M) {
      font-size: 16px;
    }
  }
  @include media_query(M) {
    font-size: 21px;
  }
}
