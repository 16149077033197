@import '../../../styles/common/media_queries';

.modal_wrapper {
  //   display: none;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background: rgba(112, 112, 112, 0.7);
  //   z-index: 3;
  //   .crop_img_modal {
  //     position: relative;
  //     margin: auto;
  //     background-color: #fff;
  //     padding: 20px 50px 15px 50px;
  //     overflow-y: auto;
  //     @include media_query(XS) {
  //       width: 100%;
  //       height: 100%;
  //     }
  //     @include media_query(M) {
  //       display: flex;
  //       flex-direction: column;
  //       width: 80%;
  //       height: 80%;
  //       padding: 0;
  //       padding-right: 8px;
  //     }
  //     @include media_query(L) {
  //       width: 1025px;
  //       height: 650px;
  //       padding: 20px 50px 15px 50px;

  //     }

  .header_wrapper {
    padding: 0 10px 15px 0;
    @include media_query(M) {
      padding: 20px 40px 0;
      margin-bottom: 24px;
    }
    @include media_query(L) {
      padding: 0 10px 15px 0;
      margin: 0;
    }

    .heading {
      font-family: 'SourceSansPro';
      font-size: 24px;
      font-weight: 700;
      color: #2c2c2c;
      margin-bottom: 10px;
    }
    .warning_msg_wrapper {
      .msg_txt {
        font-family: 'SourceSansPro';
        font-size: 16px;
        font-weight: 400;
        color: #2c2c2c;
        line-height: 24px;
      }
    }
  }

  .close_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    background-color: #2c2c2c;
    cursor: pointer;

    .close_icon {
      width: 10px;
      height: 10px;
      background-image: url(../../../styles/images/close-white.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .modal_middle_body {
    width: 100%;
    overflow-y: auto;

    .top_inner_wrapper {
      display: flex;
      flex-direction: column;
      @include media_query(M) {
        flex-direction: column;
        margin-bottom: 30px;
        padding: 0 32px 0 40px;
      }
      @include media_query(L) {
        flex-direction: row;
        margin-bottom: 0;
        padding: 0;
      }
      .img_prop {
        width: 100%;
        height: 250px;
        margin-bottom: 15px;
        border-radius: 12px;
        object-fit: cover;
        @include media_query(M) {
          height: 300px;
        }
      }
      .title_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        .title {
          font-family: 'SourceSansPro';
          font-size: 21px;
          font-weight: 500;
          color: #2c2c2c;
          line-height: 24px;
        }
        // @include media_query(M) {
        //   min-height: 50px;
        // }
      }
      .left_section_wrapper {
        @include media_query(M) {
          width: 100%;
          margin-bottom: 25px;
        }
        @include media_query(L) {
          padding-right: 50px;
          margin: 0;
        }
        .img {
          width: 100%;
          min-height: 195px;
          background-image: url(../../../styles/images/sample_img2.svg);
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 12px;
          @include media_query(L) {
            height: 260px;
          }
        }
      }
      .right_section_wrapper {
        margin-bottom: 15px;
        @include media_query(M) {
          width: 300px;
        }
        @include media_query(L) {
          width: 40%;
          padding-left: 50px;
          padding-left: 20px;
        }

        .img_wrapper {
          width: 100%;
          height: auto;
          .img {
            width: 100%;
            height: 195px;
            background-image: url(../../../styles/images/image_sample4.svg);
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 12px;
            @include media_query(L) {
              height: 260px;
            }
          }
        }
      }
      .btn_wrapper {
        width: 100%;
        height: 35px;
        display: none;
        // margin-top: 15px;
        margin-top: auto;
        .inner_btn_wrapper {
          width: 280px;
          height: 35px;
          border-radius: 20px;
          background-color: #f1f2ff;
          display: flex;
          cursor: pointer;
          .fill_btn {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              background-color: #38539a;
              border-radius: 24px;
            }
            .fill_image {
              width: 15px;
              height: 15px;
              background-image: url(../../../styles/images/inactive_fill_icon.svg);
              background-repeat: no-repeat;
              background-size: cover;
            }
            .active_fill_image {
              width: 15px;
              height: 15px;
              background-image: url(../../../styles/images/fill_icon.svg);
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
          .crop_btn {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              background-color: #38539a;
              border-radius: 24px;
            }
            .crop_image {
              width: 15px;
              height: 15px;
              background-image: url(../../../styles/images/crop_icon.svg);
              background-repeat: no-repeat;
              background-size: cover;
            }
            .active_crop_image {
              width: 15px;
              height: 15px;
              background-image: url(../../../styles/images/active_crop_icon.svg);
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
        @include media_query(M) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        // @include media_query(L) {
        //   display: none;
        // }
      }
      .img_details_opt_wrapper {
        display: none;

        .info_text {
          font-family: 'SourceSansPro';
          font-size: 16px;
          font-weight: 600;
          color: #2c2c2c;
          margin-right: 10px;
        }
        .left_arrow_icon {
          width: 12px;
          height: 18px;
          background-image: url(../../../styles/images/left_arrow_icon.svg);
          background-repeat: no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        @include media_query(L) {
          margin: 15px 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
    .checkbox_container {
      display: none;
      @include media_query(M) {
        display: flex;
        align-items: center;
      }
    }
    .warning_section {
      display: flex;
      background: #fff9f0;
      border: 1px solid #d1b278;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 30px;
      @include media_query(M) {
        display: none;
      }

      .warning_icon {
        width: 18px;
        height: 18px;
        background-image: url(../../../styles/images/warning-icon.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }

      .message_section {
        padding-left: 5px;

        .mb_5 {
          margin-bottom: 5px;
        }

        .heading {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 700;
          color: #a36a00;
        }

        .sub_heading {
          font-family: 'SourceSansPro';
          font-size: 14px;
          color: #a36a00;
        }
      }
    }
  }

  .btn_wrapper {
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin-top: auto;

    .btn {
      min-width: 120px;
      height: 35px;
      font-family: 'SourceSansPro';
      font-size: 16px;
      font-weight: 500;
      border: 2px solid #2c2c2c;
      background-color: #fff;
      border-radius: 24px;
      margin: 0 6px;
      padding: 0 30px;
      cursor: pointer;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 10px;
      }

      &.active {
        background-color: #2c2c2c;
        border: 2px solid transparent;
        color: #fff;
      }
      &.disabled {
        pointer-events: none;
        background-color: #d9d9d9;
        color: #5e5e5e;
        border: 2px solid transparent;
      }

      &.btn1 {
        @include media_query(M) {
          order: 2;
        }
      }
    }

    @include media_query(M) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .styles.btn1 {
        order: 2;
      }
    }
  }
  //   }
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e4e4e4;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    opacity: 0.5;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #707070;
    opacity: 0.5;
  }
  //   @include media_query(M) {
  //     display: flex;
  //     align-items: center;
  //   }
}

.disabled {
  pointer-events: none;
}
