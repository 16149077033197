@import '../../styles/common/media_queries';

.translation_wrapper {
  width: 100%;
  position: relative;
  @include media_query(XS) {
    height: calc(100vh);
    // padding-bottom: 85px;
    // overflow: auto;
    // padding-top: 85px;
  }
  @include media_query(M) {
    height: 100vh;
    padding-bottom: 0;
    // padding-top: 150px;
  }
  @include media_query(L) {
    height: calc(100vh - 150px);
    overflow: auto;
  }
  &.height_auto {
    height: auto;
    margin-bottom: 25px;
  }

  // Notibar
  .notification_wrapper {
    position: fixed;
    top: 15px;
    height: auto;
    z-index: 5;
    @include media_query(XS) {
      width: auto;
      margin: 0 15px;
    }
    @include media_query(M) {
      width: 450px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }

    .noti_bar {
      width: 100%;
      min-height: 60px;

      border-radius: 12px;
      padding: 12px 35px;
      position: relative;

      &.success_bar {
        background-color: #f0fae5;
        border: 1px solid #7fbc96;
      }
      &.failure_bar {
        background-color: #fff9f0;
        border: 1px solid #d1b278;
      }
      &.insufficient_bar {
        background-color: #fdf4f4;
        border: 1px solid #eb8593;
      }
      .text_wrapper {
        font-family: 'SourceSansPro';
        &.success_txt {
          color: #107f47;
        }
        &.failure_txt {
          color: #a36a00;
        }
        &.insufficient_txt {
          color: #d91734;
        }
        .tagline {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 700;
        }
        .description {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 400;
        }
      }
      .left_icon {
        position: absolute;
        top: 12px;
        left: 10px;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: cover;
        &.success_tick_icon {
          background-image: url(../../styles/images/success_icon.svg);
        }
        &.failure_icon {
          width: 18px;
          background-image: url(../../styles/images/failure_icon.svg);
        }
        &.insufficient_icon {
          width: 16px;
          background-image: url(../../styles/images/insufficient_icon.svg);
        }
      }

      .right_icon {
        position: absolute;
        top: 12px;
        right: 10px;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        &.green_close_icon {
          background-image: url(../../styles/images/close_green_icon.svg);
        }
        &.red_close_icon {
          background-image: url(../../styles/images/close_red_icon.svg);
        }
        &.pink_close_icon {
          background-image: url(../../styles/images/close_pink_icon.svg);
        }
      }
    }
  }
  // Notibar

  .inner_wrapper {
    // height: calc(100vh - 185px);
    // overflow-y: auto;
    // overflow: hidden;
    &.translation_inner_wrapper {
      position: relative;
      display: flex;
      @include media_query(M) {
        // padding-top: 165px;
        // padding-top: 170px;
      }
      @include media_query(L) {
        // padding-top: 130px;
        // height: 100%;
        // overflow: auto;
      }
    }
    @include media_query(XS) {
      margin-top: 15px;
    }
    @include media_query(M) {
      // padding-left: 65px;
      // padding-top: 150px;
      margin-top: 0;
      // height: calc(100vh - 15px);
      height: 100%;
    }
    @include media_query(L) {
      // height: 100%;
      // padding-left: 0;
    }
    .main_wrapper {
      position: relative;
      width: 100%;
      @include media_query(XS) {
        padding: 15px 15px 0px 15px;
      }
      @include media_query(M) {
        padding: 60px 40px 0 40px;
      }
      @include media_query(L) {
        padding: 30px 40px 0 40px;
      }
    }

    // Top Bar
    .top_area {
      display: flex;
      flex-direction: column;
      padding-bottom: 40px;
      .section_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 8px;
        .title {
          font-size: 32px;
          font-weight: 700;
          color: #2C2C2C;
        }
        .sub_title {
          font-size: 16px;
          font-weight: 400;
          color: #2C2C2C;
         
        }
      }
      .btn_wrapper {
        min-width: 345px;
        height: 36px;
        border-radius: 100px;
        padding: 9px 32px 9px 32px;
        background-color: #2C2C2C;
        cursor: pointer;
        &.disabled {
          pointer-events: none;
          background-color: #d9d9d9;
          border: 2px solid transparent;
          .btn {
            color: #5e5e5e;
          }
          .add_icon {
            background-image: url(../../styles/images/add-gray.svg);
          }
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          font-size: 14px;
          font-weight: 700;
          color: #fff;
          line-height: 1.2;
        }
        .add_icon {
          width: 12px;
          height: 12px;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(../../styles/images/add-white.svg);
        }
        &.desktop_lang {
          @include media_query(XS) {
            display: none;
          }
          @include media_query(M) {
            display: block;
          }
        }
        &.mob_lang {
          @include media_query(XS) {
            display: block;
            margin-top: 15px;
          }
          @include media_query(M) {
            display: none;
            margin-top: initial;
          }
        }
      }
    }
    // Top Bar

    // Card
    .card_wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
      @include media_query(L) {
        &.active_drawer {
          width: calc(100% - 500px);
        }
      }
      .card_block {
        padding: 0 10px;
        margin-bottom: 16px;
      }
      .card {
        padding: 24px;
        box-shadow: 0px 2px 16px 0px rgba(44, 44, 44, 0.1);
        min-height: 275px;
        &.selected {
          background-color: #F1F2FF;
        }
        .language_info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
          min-height: 24px;
          .language {
            display: flex;
            align-items: center;
            width: fit-content;
            min-width: 65px;
            height: 24px;
            border-radius: 4px;
            color: #F1F2FF;
            font-size: 14px;
            font-weight: 700;
            background-color: #2C2C2C;
            text-align: center;
            padding: 3px 8px 3px 8px;
          }
          .primary_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2px;
            width: 85px;
            height: 24px;
            background-color: #F1F2FF;
            padding: 3px, 8px, 3px, 8px;
            border-radius: 4px;
            text-align: center;
            .star {
              width: 12px;
              height: 12px;
              background-size: contain;
              background-repeat: no-repeat;
              background-image: url(../../styles/images/star.svg);
            }
            .type {
              font-size: 14px;
              font-weight: 500;
              color: #38539A;
            }
          }
          .action_wrapper {
            display: flex;
            align-items: center;
            gap: 16px;
            .edit_btn { 
              width: 19px;
              height: 18px;
              background-size: contain;
              background-repeat: no-repeat;
              background-image: url(../../styles/images/edit-black.svg);
              cursor: pointer;
              &.disabled {
                pointer-events: none;
                background-image: url(../../styles/images/edit-black.svg);
                opacity: 0.6;
              }
            }
            .seperator {
              border-right: 1px solid #2C2C2C;
              height: 23px;
            }
            .delete_btn {
              width: 16px;
              height: 18px;
              background-size: contain;
              background-repeat: no-repeat;
              background-image: url(../../styles/images/delete-black.svg);
              cursor: pointer;
              &.disabled {
                pointer-events: none;
                background-image: url(../../styles/images/delete-black.svg);
                opacity: 0.6;
              }
            }
          }
        }
        .title {
          font-size: 16px;
          font-weight: 700;
          color: #2C2C2C;
          line-height: 24px;
          margin-bottom: 12px;
          height: 48px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .author {
          font-size: 16px;
          font-weight: 400;
          font-style: italic;
          color: #2C2C2C;
          line-height: 24px;
          height: 24px;
          margin-bottom: 12px;
        }
        .description {
          font-size: 16px;
          font-weight: 400;
          color: #2C2C2C;
          line-height: 24px;
          height: 96;
          // margin-bottom: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          @supports (-webkit-line-clamp: 4) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    // Card
  }
}