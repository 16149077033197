.container {
  width: 60%;
  // height: 100%;
  .svg_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    align-items: center;
    font-family: 'SourceSansPro';
    .title{
        font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 28px;
    }
    .desc{
        text-align: center;
        font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
    }
  }
}
