@import '../../../styles/common/media_queries';

.aboListing_wrapper {
    width: 100%;

    .header_main_wrapper {
        @include media_query(M) {
            padding-left: 65px;
        }

        @include media_query(L) {
            position: fixed;
            width: calc(100% - 65px);
            top: 0;
            z-index: 3;
            margin-bottom: 40px;
            padding-left: 0;
        }

        .header_wrapper {
            display: flex;
            align-items: center;
            background: #ffffff;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
            // height: 110px;
            padding: 0 40px;

            @include media_query(XS) {
                min-height: 35px;
                padding-bottom: 15px;
                justify-content: center;
            }

            @include media_query(M) {
                min-height: auto;
                height: 110px;
                padding-bottom: 0;
                justify-content: left;
            }

            .heading {
                font-family: 'SourceSansPro';
                font-weight: 700;
                color: #2c2c2c;

                @include media_query(XS) {
                    font-size: 24px;
                }

                @include media_query(M) {
                    font-size: 32px;
                }
            }
        }
    }

    .main_content_wrapper {
        overflow: hidden;

        @include media_query(XS) {
            margin-top: 25px;
        }

        @include media_query(M) {
            padding-left: 105px;
            padding-top: 150px;
            padding-right: 40px;
            padding: 150px 40px 0px 105px;
            margin-top: 0;
            height: calc(100vh - 15px);
        }

        @include media_query(L) {
            height: 100%;
        }

        &.inner_wrapper {
            @include media_query(M) {
                padding-top: 150px;
                height: 100%;
            }

            @include media_query(L) {
                height: 100%;
                overflow-y: visible;
                min-height: 600px;
            }
        }

        .top_area_wrapper {
            display: flex;
            align-items: center;
            column-gap: 9px;
            margin-bottom: 40px;
            // @include media_query(XS) {
            //   margin: 0 15px;
            //   margin-bottom: 24px;
            // }
            // @include media_query(M) {
            //   // display: flex;
            //   margin: 0;
            //   margin-bottom: 15px;
            //   padding: 0 40px;
            // }
      
            // @include media_query(L) {
            //   width: 100%;
            // }
      
            .txt {
              font-family: 'SourceSansPro';
              font-size: 16px;
              color: #949494;
            }
            .sort_by_wrapper {
              // order: 1;
              @include media_query(XS) {
                display: flex;
                align-items: center;
                margin-left: 15px;
              }
              @include media_query(M) {
                margin-left: 0;
                order: 0;
              }
              .txt {
                font-family: 'SourceSansPro';
                @include media_query(XS) {
                  // display: none;
                  font-size: 14px;
                  font-weight: 700;
                  color: #2c2c2c;
                  text-transform: capitalize;
                }
                @include media_query(M) {
                  display: none;
                }
              }
              .icon_wrapper {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 36px;
                height: 36px;
                background-color: #f4f4f4;
                border-radius: 50%;
      
                @include media_query(M) {
                  background-color: #f4f4f4;
                  border-radius: 50%;
                }
                .filter {
                  // position: absolute;
                  // top: 0;
                  // left: 10px;
                  // right: 0;
                  // bottom: 0;
                  // margin: auto 0;
                  width: 18px;
                  height: 19px;
                  background-image: url(../../../styles/images/filter-black.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                  cursor: pointer;
                }
                &.selected {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  width: 32px;
                  height: 32px;
                  background-color: #fafafa;
                  border-radius: 50%;
                  .filter {
                    background-image: url(../../../styles/images/filter-black.svg);
                  }
                }
              }
            }
            .toggle_section_wapper {
              position: relative;
              display: flex;
              align-items: center;
              width: 120px;
              height: 32px;
              margin-left: auto;
            }
            .toggle_wrapper {
              display: flex;
              width: 105px;
              height: 32px;
              padding: 1px;
              margin-left: auto;
              border: 1px solid #2c2c2c;
              border-radius: 20px;
              // background-color: #ffffff;
              @include media_query(XS) {
                display: none;
              }
              @include media_query(M) {
                display: flex;
              }
              .card_radius {
                border-radius: 24px;
              }
              .view_radius {
                border-radius: 24px;
              }
              .toggle_section {
                position: relative;
                top: -1px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: calc(100% + 2px);
                background-color: #ffffff;
                cursor: pointer;
      
                &.card_selected,
                &.view_selected {
                  background-color: #2c2c2c;
                  height: calc(100% + 2px);
                  .card_view {
                    background-image: url(../../../styles/images/card-view-selected.svg);
                  }
                  .list_view {
                    background-image: url(../../../styles/images/list-view-selected.svg);
                  }
                }
                &.card_selected {
                  left: -1px;
                  // border-radius: 20px 0 0 20px;
                }
                &.view_selected {
                  right: -1px;
                  // border-radius: 0 20px 20px 0;
                }
                .card_view {
                  width: 18px;
                  height: 18px;
                  background-image: url(../../../styles/images/card-view.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                }
                .list_view {
                  width: 18px;
                  height: 13px;
                  background-image: url(../../../styles/images/list-view.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }
            }
            .btn_wrapper {
              // display: flex;
              align-items: center;
              margin-left: 16px;
              @include media_query(M) {
                display: flex;
                position: relative;
                height: auto;
                padding: 0;
              }
              &.btn_wrapper_xs {
                position: relative;
                margin-left: 16px;
                height: auto;
                width: auto;
                padding: 0;
                @include media_query(XS) {
                  position: fixed;
                  display: flex;
                  align-items: center;
                  background-color: #fff;
                  bottom: 0;
                  left: 0;
                  margin-left: 0;
                  width: 100%;
                  padding: 0 16px;
                  height: 70px;
                  z-index: 2; //need to add 'z-index: 2' to avoid overlapping in mobile view
                }
                @include media_query(M) {
                  position: relative;
                  margin-left: 16px;
                  height: auto;
                  width: auto;
                  padding: 0;
                }
              }
              .sort_wrapper {
                // display: flex;
                align-items: center;
                width: 220px;
                height: 35px;
                border: 2px solid #2c2c2c;
                border-radius: 10px;
                padding: 0 15px;
                margin: 0 10px;
                cursor: pointer;
                .txt {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  color: #707070;
                }
                @include media_query(XS) {
                  display: none;
                }
                @include media_query(M) {
                  display: flex;
                }
              }
              .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 170px;
                background: #38539a;
                border-radius: 24px;
                // padding: 0 30px;
                cursor: pointer;
                @include media_query(XS) {
                  width: 100%;
                  height: 35px;
                }
                @include media_query(M) {
                  position: relative;
                  width: auto;
                  height: 35px;
                  margin: 0;
                }
                .txt {
                  font-family: 'SourceSansPro';
                  font-size: 16px;
                  font-weight: 700;
                  color: #fff;
                  padding-left: 10px;
                  text-transform: capitalize;
                }
                .add {
                  width: 12px;
                  height: 13px;
                  background-image: url(../../../styles/images/add-white.svg);
                  background-repeat: no-repeat;
                }
              }
            }

            .search_main_wrapper {
                position: relative;
                width: 100%;
                min-width: 120px;
               
                @media screen and (max-width: 992px) {
                  min-width: 80px;
                }
                ::-webkit-scrollbar {
                  width: 5px;
                }
            
                /* Track */
                ::-webkit-scrollbar-track {
                  background: #e4e4e4;
                  border-radius: 10px;
                }
            
                /* Handle */
                ::-webkit-scrollbar-thumb {
                  background: #707070;
                  border-radius: 10px;
                }
            
                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                  background: #707070;
                }
            
                &.active {
                  .dropdown_container,
                  .search_wrapper {
                    border: 2px solid #38539a;
                  }
                }
            
                .dropdown_container {
                  position: absolute;
                  width: 100%;
                  top: 45px;
                  background-color: #fff;
                  border-radius: 10px;
                  z-index: 1;
                  border: 2px solid #707070;
                  padding: 10px 10px 10px 0;
                  .list_wrapper {
                    height: 185px;
                    overflow: auto;
                    .list {
                      font-family: 'SourceSansPro';
                      display: flex;
                      align-items: center;
                      padding: 0 16px;
                      font-size: 16px;
                      font-weight: 500;
                      color: #2c2c2c;
                      cursor: pointer;
                      @include media_query(XS) {
                        height: auto;
                        margin-bottom: 20px;
                      }
                      @include media_query(M) {
                        min-height: 35px;
                        margin-bottom: 0;
                      }
                      &:hover {
                        font-weight: 700;
                        color: #38539a;
                      }
                    }
                  }
                }
            }

            .search_wrapper {
                display: flex;
                align-items: center;
                border: 2px solid #2c2c2c;
                border-radius: 10px;
                height: 35px;
                // width: 21rem;
                padding: 0 10px;
                &.outline {
                  border: 2px solid #38539a;
                }
                // @include media_query(XS) {
                //   // width: 15.5rem;
                //   // width: 18rem;
                //   width: 100%;
                // }
                // @include media_query(M) {
                //   width: 16.5rem;
                // }
                // @include media_query(L) {
                //   width: 20rem;
                // }
            
                .icon_wrapper {
                  margin-left: auto;
                  .search {
                    width: 18px;
                    height: 18px;
                    background-image: url(../../../styles/images/search-black.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    cursor: pointer;
                  }
                  .cross_icon {
                    width: 12px;
                    height: 12px;
                    background-image: url(../../../styles/images/cross_icon_slim.svg);
                    cursor: pointer;
                  }
                }
                .search_input {
                  font-family: 'SourceSansPro';
                  background-color: transparent;
                  font-size: 16px;
                  font-weight: 500;
                  color: #2c2c2c;
                  width: 100%;
                  border: none;
                  outline: none;
                  @include media_query(M) {
                    padding-right: 15px;
                  }
                  &::placeholder {
                    font-weight: 400;
                    color: #707070;
                  }
                }
              }
        }

        .richGridContainer {
          display: none;

          @media only screen and (min-width: 992px) {
            display: block;
          }
        }

        .learningRecordCardContainer {
          display: none;

          @media only screen and (max-width: 992px) {
            display: flex;
            flex-direction: column;
            row-gap: 20px; 
            margin-top: 20px;
            padding-bottom: 158px;
          }
        }
    }

    .modal_wrapper {
        .header_wrapper {
          padding: 0 0 35px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .heading {
            font-family: 'SourceSansPro';
            font-size: 24px;
            font-weight: 700;
            color: #2c2c2c;
          }
        }
    
        .close_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
    
          .close_icon {
            width: 15px;
            height: 15px;
            background-image: url(../../../styles/images/cross_icon_slim.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
    
        .warning_section {
          display: flex;
          background: #fff9f0;
          border: 1px solid #d1b278;
          border-radius: 10px;
          padding: 10px;
          margin-bottom: 30px;
    
          .warning_icon {
            margin-top: 4px;
            width: 28px;
            height: 25px;
            background-image: url(../../../styles/images/warning-icon.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
    
          .message_section {
            padding-left: 5px;
    
            .mb_5 {
              margin-bottom: 5px;
            }
    
            .heading {
              font-family: 'SourceSansPro';
              font-size: 14px;
              font-weight: 700;
              color: #a36a00;
              &.warning_message {
                font-weight: 400;
              }
              .text_bold {
                font-weight: 700;
              }
            }
    
            .sub_heading {
              font-family: 'SourceSansPro';
              font-size: 14px;
              color: #a36a00;
            }
          }
        }
    
        .confirm_wrapper {
          padding-bottom: 25px;
    
          .txt {
            font-family: 'SourceSansPro';
            font-size: 14px;
            color: #2c2c2c;
            margin-bottom: 10px;
          }
    
          .input_wrapper {
            position: relative;
            .input {
              width: 100%;
              height: 35px;
              font-family: 'SourceSansPro';
              font-size: 14px;
              font-weight: 500;
              color: #2c2c2c;
              padding: 0 10px;
              border: 2px solid #2c2c2c;
              border-radius: 10px;
              &.error_input {
                border: 2px solid #d91734;
              }
              &::placeholder {
                font-family: 'SourceSansPro';
                font-weight: 200;
                color: #707070;
              }
            }
            .error_icon {
              display: flex;
              align-items: center;
              transform: translate(-50%, 25%);
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              width: 23px;
              height: 23px;
              background-image: url(../../../styles/images/insufficient.svg);
              background-repeat: no-repeat;
              background-size: contain;
            }
            .error_msg {
              font-family: 'SourceSansPro';
              font-size: 14px;
              font-weight: 500;
              color: #d91734;
              margin-top: 8px;
            }
          }
        }
    
        .confirm_msg {
          text-align: center;
    
          .txt {
            display: inline-block;
            vertical-align: middle;
            font-family: 'SourceSansPro';
            font-size: 16px;
            color: #2c2c2c;
          }
    
          .bold {
            font-family: 'SourceSansPro';
            font-weight: 700;
          }
        }
    
        .btn_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: auto;
    
          .btn {
            min-width: 120px;
            height: 35px;
            font-family: 'SourceSansPro';
            font-size: 16px;
            font-weight: 700;
            border: 2px solid #2c2c2c;
            background-color: #fff;
            border-radius: 24px;
            margin: 0 6px;
            padding: 0 30px;
            cursor: pointer;
    
            &.active {
              background-color: #d91734;
              border: 2px solid transparent;
              color: #fff;
            }
    
            &.save_changes {
              background-color: #2c2c2c;
              border: 2px solid transparent;
              color: #fff;
            }
    
            &.disabled {
              pointer-events: none;
              background-color: #d9d9d9;
              color: #5e5e5e;
              border: 2px solid transparent;
            }
          }
        }
        // }
      }
}

.add_addition_icons {
    display: flex;
    justify-content: center;
    align-items: center;
    @include media_query(ML) {
      display: none;
    }
}

.actionBtnContainer {
  cursor: pointer;
  margin-right: 0;
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f1f2ff;
  @include media_query(S) {
    margin-right: 8px;
  }
}

.sortIcon {
  padding: 0px 0px 2px 8px;
  cursor: pointer;
}

.aboListingTableHeaders {
  margin: 0;
  display: flex;
  align-items: center;
}