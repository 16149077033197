.country_section {
  border-radius: 12px;
  background: var(--2-Pure-White, #fff);
  box-shadow: 0px 2px 16px 0px rgba(44, 44, 44, 0.1);
  padding: 24px;
  margin: 40px 0px 40px;
  max-width: 440px;
  max-height: 248px;
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.67;
  }

  .country_section_title {
    font-weight: bold;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .country_action_btns {
    display: flex;
    font-weight: bold;
    margin: 8px 0px 5px;
    .clear_all {
      color: #38539a;
      border-bottom: 2px solid #38539a;
      cursor: pointer;
    }
    .select_all {
      margin-left: 20px;
      color: #38539a;
      border-bottom: 2px solid #38539a;
      cursor: pointer;
    }
  }
  .check_list {
    max-height: 138px;
    margin-top: 15px;
    overflow-y: auto;
    .checkBox {
      margin-top: 20px;
    }
  }
}
