@import '../../../styles/common/media_queries';

// .pre_browse_wrapper {
//   font-family: "SourceSansPro";
//   position: relative;
//   width: 100%;
//   min-height: 200px;
//   border: 2px dashed #707070;
//   border-radius: 12px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 25px 0;
//   font-size: 14px;
//   font-weight: 500;
//   color: #707070;
//   cursor: pointer;

//   &:hover {
//     border: 2px dashed #38539a;
//     background-color: #f1f2ff;
//     color: #38539a;
//     .browse_img {
//       background-image: url(../../../styles/images/browse_icon_blue1.svg);
//     }
//     .browse_text {
//       border-bottom: 1px solid #38539a;

//       &.text {
//         border-bottom: none;
//       }
//     }
//   }

//   // &.uploading_state {
//   //   border: 2px dashed #38539a;
//   //   background-color: #f1f2ff;
//   //   .browse_img {
//   //     background-image: url(../../../styles/images/browse_icon_blue1.svg);
//   //   }
//   //   .browse_text {
//   //     color: #38539a;
//   //     border-bottom: 1px solid #38539a;
//   //   }
//   //   .text {
//   //     color: #38539a;
//   //   }
//   // }

//   .file_input {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     opacity: 0;
//     z-index: 1;
//     cursor: pointer;
//   }
//   .browse_img {
//     width: 35px;
//     height: 35px;
//     background-image: url(../../..//styles/images/browse_icon_black.svg);
//     background-repeat: no-repeat;
//     background-size: cover;
//     margin-bottom: 40px;
//     @include media_query(XS) {
//       margin-bottom: 0;
//     }
//     @include media_query(L) {
//       margin-bottom: 20px;
//     }
//   }
//   .browse_text {
//     // text-decoration: underline;
//     border-bottom: 1px solid #707070;
//     margin-bottom: 20px;
//     @include media_query(XS) {
//       margin-top: 20px;
//     }
//     @include media_query(L) {
//       // margin-top: 0;
//     }
//   }
//   .txt {
//     border: none;
//     margin-bottom: 20px;
//   }
//   @include media_query(M) {
//     font-family: "SourceSansPro";
//     font-size: 18px;
//     line-height: 21px;
//     height: 350px;
//   }
//   @include media_query(L) {
//     width: 600px;
//   }
// }
.pre_browse_wrapper {
  font-family: 'SourceSansPro';
  position: relative;
  width: 100%;
  height: 200px;
  border: 2px dashed #707070;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  font-size: 14px;
  font-weight: 500;
  color: #707070;
  // pointer-events: none;

  .div_wrapper_browse_img_browse_text {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: auto;
    cursor: pointer;
    &:hover {
      color: #38539a;
      .browse_img {
        background-image: url(../../../styles/images/browse_icon_blue1.svg);
      }
      .browse_text {
        font-family: 'SourceSansPro';
        border-bottom: 1px solid #38539a;

        &.text {
          font-family: 'SourceSansPro';
          border-bottom: none;
        }
      }
      .browse_text_secondary {
        font-family: 'SourceSansPro';
        border-bottom: none;
        border-bottom-width: 0;
      }
    }
  }

  &.uploading_state {
    border: 2px dashed #38539a;
    background-color: #f1f2ff;
    .browse_img {
      background-image: url(../../../styles/images/browse_icon_blue1.svg);
    }
    .browse_text {
      color: #38539a;
      border-bottom: 1px solid #38539a;
    }
    .text {
      font-family: 'SourceSansPro';
      color: #38539a;
    }
    .browse_text_secondary {
      color: #38539a;
      border-bottom: none;
    }
  }

  .file_input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    display: none;
  }
  .browse_img {
    width: 35px;
    height: 35px;
    background-image: url(../../..//styles/images/browse_icon_black.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .browse_text {
    font-family: 'SourceSansPro';
    display: block;
    border-bottom: 1px solid #707070;

    &:hover {
      color: #38539a;
      border-bottom: 1px solid #38539a;
    }
  }
  .browse_text_secondary {
    border-bottom: none;
    display: block;
  }
  .txt {
    display: block;
    border: none;
    margin-bottom: 20px;
  }
  @include media_query(XS) {
    .browse_img {
      margin-bottom: 50px;
    }
    .browse_text {
      margin-bottom: 0;
    }
    .browse_text_secondary {
      display: none;
    }
    .text {
      display: none;
    }
  }
  @include media_query(M) {
    font-family: 'SourceSansPro';
    font-size: 18px;
    line-height: 21px;
    height: 350px;
    .browse_img {
      margin-bottom: 40px;
    }
    .browse_text {
      margin-bottom: 20px;
    }
    .browse_text_secondary {
      display: block;
      border-bottom: none !important;
    }
    .text {
      display: block;
      margin-bottom: 20px;
    }
  }
  @include media_query(L) {
    width: 600px;
  }
}

.custom_styling {
  @include media_query(L) {
    width: 450px;
    height: 260px;
  }
}
// .div_wrapper_browse_img_browse_text {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   pointer-events: auto;
//   cursor: pointer;
//   &:hover {
//     color: #38539a;
//     .browse_img {
//       background-image: url(../../../styles/images/browse_icon_blue1.svg);
//     }
//     .browse_text {
//       font-family: "SourceSansPro";
//       border-bottom: 1px solid #38539a;

//       &.text {
//         font-family: "SourceSansPro";
//         border-bottom: none;
//       }
//     }
//     .browse_text_secondary {
//       font-family: "SourceSansPro";
//       border-bottom-width: 0;
//       border-bottom: none;
//     }
//   }
// }
