/** gtWalsheim **/

/* gtWalsheim-Regular */
@font-face {
  font-family: 'gtWalsheim';
  // src: url('./gtWalsheim/gt-walsheim-regular.eot');
  src: url('./gtWalsheim/gt-walsheim-regular.ttf') format('truetype');
  // url('./gtWalsheim/gt-walsheim-regular.woff') format('woff'),
  // url('./gtWalsheim/gt-walsheim-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* gtWalsheim-Medium */
@font-face {
  font-family: 'gtWalsheim';
  // src: url('./gtWalsheim/gt-walsheim-medium.eot');
  src: url('./gtWalsheim/gt-walsheim-medium.ttf') format('truetype');
  // url('./gtWalsheim/gt-walsheim-Medium.woff') format('woff'),
  // url('./gtWalsheim/gt-walsheim-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/** gtWalsheim Bold **/
@font-face {
  font-family: 'gtWalsheim';
  // src: url('./SourceSansPro/gt-walsheim-bold.eot');
  src: url('./gtWalsheim/gt-walsheim-bold.ttf') format('truetype');
  // url('./gtWalsheim/gt-walsheim-bold.woff') format('woff'),
  // url('./gtWalsheim/gt-walsheim-bold.svg') format('svg'),
  // url('./gtWalsheim/gt-walsheim-bold.eot') format('embedded-opentype'),
  // url('./gtWalsheim/gt-walsheim-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* -------------------------------------------------------------------------------- */
/** SourceSansPro Light **/
@font-face {
  font-family: 'SourceSansPro';
  // src: url('./SourceSansPro/gt-walsheim-medium.eot');
  src: url('./SourceSansPro/SourceSansPro-Light.ttf') format('truetype'),
  url('./SourceSansPro/SourceSansPro-Light.woff') format('woff'),
  url('./SourceSansPro/SourceSansPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

/** SourceSansPro Regular **/
@font-face {
  font-family: 'SourceSansPro';
  // src: url('./SourceSansPro/gt-walsheim-medium.eot');
  src: url('./SourceSansPro/SourceSansPro-Regular.ttf') format('truetype'),
  url('./SourceSansPro/SourceSansPro-Regular.woff') format('woff'),
  url('./SourceSansPro/SourceSansPro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

/** SourceSansPro Medium **/
@font-face {
  font-family: 'SourceSansPro';
  // src: url('./SourceSansPro/gt-walsheim-medium.eot');
  src: url('./SourceSansPro/SourceSans3-Medium.ttf') format('truetype'),
  url('./SourceSansPro/SourceSans3-Medium.woff') format('woff'),
  url('./SourceSansPro/SourceSans3-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

/** SourceSansPro Semi Bold **/
@font-face {
  font-family: 'SourceSansPro';
  // src: url('./SourceSansPro/gt-walsheim-medium.eot');
  src: url('./SourceSansPro/SourceSansPro-Semibold.ttf') format('truetype'),
  url('./SourceSansPro/SourceSansPro-Semibold.woff') format('woff'),
  url('./SourceSansPro/SourceSansPro-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

/** SourceSansPro Bold **/
@font-face {
  font-family: 'SourceSansPro';
  // src: url('./SourceSansPro/gt-walsheim-medium.eot');
  src: url('./SourceSansPro/SourceSansPro-Bold.ttf') format('truetype'),
  url('./SourceSansPro/SourceSansPro-Bold.woff') format('woff'),
  url('./SourceSansPro/SourceSansPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}