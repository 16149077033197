@import './bootstrap/bootstrap-grid.min.css';
@import './common/all';
// @import '/components/contentManagement/addImagePage/AddImage.module.scss';

/* Component */
// @import './component/sideNavigation';
// @import './component/portal';
// @import './component/audienceMgt';
// @import './component/filter';

/* Shared Components */
// @import './component/checkbox';

.MuiGrid-root .MuiCardContent-root {
  padding: 17px;
}
.page_title {
  margin-bottom: 20px;
  font-family: 'SourceSansPro';
  font-size: 20px;
  font-weight: 400;
}
.main_container {
  .card_title {
    background-color: #eee;
  }
}

.rah-input-wrapper {
  width: 100%;
}

//code for edit image extent crop scale
.ReactCrop {
  width: 100%;
  height: 100%;
}
.ReactCrop > div {
  width: 100%;
  height: 100%;
}
.ReactCrop > div > .ReactCrop__image {
  width: 100%;
  height: 100%;
}

.page_content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Portal_filter_main_wrapper__9zsfL {
  .Filter_filter_container__6bWRn {
    margin-left: auto;
  }
}
body {
  @include media_query(XS) {
    overflow: hidden;
  }
  @include media_query(M) {
    overflow: auto;
  }
  .dl-item {
    &.dragged {
      height: 56px;
      left: 0px;
      top: 72px;
      background: #f1f2ff;
      border-radius: 12px;
      padding: 0 24px;
      margin-bottom: 16px;
      z-index: 10000;
      .CourseSequencing_tbl_row__ZzLGr {
        display: none !important;
      }
    }
  }
}
