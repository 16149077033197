@import '../../../styles/common/media_queries';

// Notification bar code here
.notification_wrapper {
  position: fixed;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 450px;
  height: auto;
  z-index: 100;
  @include media_query(XS) {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @include media_query(M) {
    width: 450px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
  &.notify_width {
    @include media_query(XS) {
      width: 90%;
    }
    @include media_query(M) {
      width: 450px;
    }
  }

  .noti_bar {
    width: 90%;
    min-height: max-content;

    border-radius: 12px;
    padding: 12px 35px;
    position: relative;
    @include media_query(M) {
      width: 100%;
    }
    &.success_bar {
      background-color: #f0fae5;
      border: 1px solid #7fbc96;
    }
    &.failure_bar {
      background-color: #fff9f0;
      border: 1px solid #d1b278;
    }
    &.warning_bar {
      background-color: #fdf4f4;
      border: 1px solid #d91734;
    }
    // New class added for in-progress notification
    &.inprogress_bar {
      background-color: #f1f2ff;
      border: 1px solid #94a3cd;
    }
    .text_wrapper {
      &.success_txt {
        color: #107f47;
      }
      &.failure_txt {
        color: #a36a00;
      }
      &.warning_txt {
        color: #d91734;
      }
      &.inprogress_txt {
        color: #38539a;
      }
      .tagline {
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }
      .description {
        font-family: 'SourceSansPro';
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
      }
    }
    .left_icon {
      position: absolute;
      top: 12px;
      left: 10px;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: cover;
      &.success_tick_icon {
        background-image: url(../../../styles/images/success_icon.svg);
      }
      &.failure_tick_icon {
        width: 18px;
        background-image: url(../../../styles/images/warning.svg);
      }
      &.warning_tick_icon {
        width: 18px;
        background-image: url(../../../styles/images/insufficient.svg);
        background-size: contain;
      }
      &.inprogress_tick_icon {
        width: 16px;
        height: 18px;
        background-image: url(../../../styles/images/inprogress_up_icon.svg);
        background-size: contain;
      }
    }

    .right_icon {
      position: absolute;
      top: 12px;
      right: 10px;
      width: 10px;
      height: 10px;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      &.green_close_icon {
        background-image: url(../../../styles/images/close_green_icon.svg);
      }
      &.red_close_icon {
        background-image: url(../../../styles/images/close_red_icon.svg);
        // background-image: url(../../../styles/images/close_red_icon.svg);
      }
      &.yellow_close_icon {
        width: 12px;
        height: 18px;
        background-image: url(../../../styles/images/yellow-cross.svg);
      }
      &.blue_close_icon {
        background-image: url(../../../styles/images/cross_blue_icon.svg);
      }
    }
  }
}
// Notification bar End
