@import '../../../styles/common/media_queries';

.add_image_container {
  width: 100%;
  // height: 100vh;

  .borderRadius12 {
    border-radius: 12px !important;
  }

  .add_inner_wrapper {
    width: 100%;
    height: calc(100vh - 125px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 70px;

    .top_section {
      width: 100%;
      height: auto;
      padding: 0px 0 25px;
      display: flex;
      flex-direction: column;
      order: 2;

      .left_container {
        flex: 1;
        padding: 0 15px;
        margin-bottom: 25px;

        @include media_query(M) {
          padding: 0 40px;
        }
        @include media_query(L) {
          border-right: 1px solid #e4e4e4;
        }
      }
      .right_container {
        flex: 1;
        padding: 0 15px;
        .info_container {
          margin-bottom: 15px;
        }

        .pills_container {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;
          padding: 0;
          @include media_query(M) {
            margin-bottom: 0;
          }
        }
        .suggestion_text {
          font-family: 'SourceSansPro';
          font-size: 14px;
          min-height: 50px;
          color: #a36a00;
          font-weight: 400;
          background: #fff9f0;
          border: 1px solid #d1b278;
          border-radius: 12px;
          padding: 10px;
          float: left;
          @include media_query(M) {
            display: none;
          }
        }
        @include media_query(M) {
          padding: 0 40px;
        }
      }
      .info_container {
        width: 100%;
        margin-bottom: 25px;
        &:last-child {
          margin: 0;
        }
        .label_wrapper {
          font-family: 'SourceSansPro';
          font-size: 16px;
          color: #2c2c2c;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
          .tag_text {
            font-family: 'SourceSansPro';
            font-size: 14px;
            color: #707070;

            @include media_query(M) {
              font-size: 16px;
            }
          }
          @include media_query(M) {
            font-size: 21px;
          }
        }
        .dropdown_container {
          position: absolute;
          width: 100%;
          top: 45px;
          background-color: #fff;
          border-radius: 10px;
          z-index: 1;
          border: 2px solid #707070;
          padding: 10px 0;
          .no_results {
            padding: 0 15px;
          }
          .list_wrapper {
            max-height: 185px;
            overflow: auto;
            .list {
              font-family: 'SourceSansPro';
              display: flex;
              align-items: center;
              padding: 0 16px;
              font-size: 14px;
              font-weight: 500;
              color: #2c2c2c;
              cursor: pointer;
              @include media_query(XS) {
                height: auto;
                margin-bottom: 20px;
              }
              @include media_query(M) {
                min-height: 35px;
                margin-bottom: 0;
              }
              &:hover {
                font-weight: 700;
                color: #38539a;
                background-color: #f4f4f4;
              }
            }
          }
        }
        &.error_wrapper {
          .form_field {
            .input_wrapper {
              font-family: 'SourceSansPro';
              .input {
                border: 2px solid #d91734;
              }
              .error_icon_red {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 12px;
                width: 20px;
                height: 20px;
                background-image: url(../../../styles/images/error_icon_red.svg);
                background-size: cover;
                background-repeat: no-repeat;
              }
            }
          }

          .error_message {
            font-family: 'SourceSansPro';
            color: #d91734;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            padding: 10px 0;
          }
        }

        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #e4e4e4;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #707070;
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }
        .form_field {
          position: relative;
          .input_wrapper {
            font-family: 'SourceSansPro';
            display: flex;
            align-items: center;
            position: relative;
            .input_area {
              position: relative;
              width: calc(100% - 120px);
            }
            .input {
              font-family: 'SourceSansPro';
              width: 100%;
              height: 35px;
              border: 2px solid #2c2c2c;
              border-radius: 10px;
              font-size: 14px;
              line-height: 35px;
              font-weight: 500;
              color: #2c2c2c;
              padding: 0 12px;
              &.active {
                border: 2px solid #38539a;
              }
              @include media_query(M) {
                font-size: 16px;
              }
              &.ip2 {
                // width: calc(100% - 120px);
              }
              &::placeholder {
                font-weight: 200;
              }
            }
            .textarea {
              width: 100%;
              min-height: 90px;
              line-height: initial;
              border: 2px solid #2c2c2c;
              border-radius: 10px;
              font-size: 14px;
              font-family: 'SourceSansPro';
              font-weight: 500;
              color: #2c2c2c;
              padding: 10px 12px;
              resize: vertical;
              overflow: auto;
              &:focus {
                outline: none;
                border: 2px solid #2c2c2c;
              }
              @include media_query(M) {
                font-size: 16px;
              }
              &::placeholder {
                font-weight: 200;
              }
            }
            .add_btn {
              font-family: 'SourceSansPro';
              width: 120px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              font-weight: 700;
              color: #5e5e5e;
              background: #d9d9d9;
              border-radius: 24px;
              margin-left: 10px;
              cursor: pointer;
              .plus_sign {
                width: 12px;
                height: 12px;
                background-image: url(../../../styles/images/plus_icon.svg);
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 8px;
              }
              &.selected {
                background-color: #2c2c2c;
                color: #fff;
                .plus_sign {
                  background-image: url(../../../styles/images/add-white.svg);
                }
              }
            }
          }
        }
      }
      @include media_query(M) {
        padding: 45px 0;
      }
      @include media_query(L) {
        flex-direction: row;
        padding: 40px 0;
      }
    }
    .middle_container {
      width: 100%;
      padding: 15px;
      padding-top: 0;
      order: 1;

      .middle_wrapper {
        padding-top: 25px;
        &.full_width_class {
          @include media_query(L) {
            width: 100%;
          }
        }
        @include media_query(L) {
          width: 50%;
          padding-right: 40px;
        }

        .tag_text {
          font-family: 'SourceSansPro';
          font-size: 14px;
          color: #707070;
          display: flex;
          align-items: center;
          justify-content: end;
          padding-top: 12px;

          @include media_query(M) {
            font-size: 16px;
          }
          @include media_query(L) {
            width: auto;
          }
        }
        .title {
          font-family: 'SourceSansPro';
          font-size: 24px;
          font-weight: 700;
          color: #2c2c2c;
          margin-bottom: 30px;
          @include media_query(S) {
            display: none;
          }
          @include media_query(L) {
            font-size: 26px;
          }
        }
        .pre_browse_wrapper {
          font-family: 'SourceSansPro';
          position: relative;
          width: 100%;
          height: 200px;
          border: 2px dashed #707070;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 25px 0;
          font-size: 14px;
          font-weight: 500;
          color: #707070;
          //pointer-events: none;

          .div_wrapper_browse_img_browse_text {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            pointer-events: auto;
            cursor: pointer;
            &:hover {
              color: #38539a;
              .browse_img {
                background-image: url(../../../styles/images/browse_icon_blue1.svg);
              }
              .browse_text {
                font-family: 'SourceSansPro';
                border-bottom: 1px solid #38539a;

                &.text {
                  font-family: 'SourceSansPro';
                  border-bottom: none;
                }
              }
              .browse_text_secondary {
                font-family: 'SourceSansPro';
                border-bottom: none;
                border-bottom-width: 0;
              }
            }
          }

          &.uploading_state {
            border: 2px dashed #38539a;
            background-color: #f1f2ff;
            .browse_img {
              background-image: url(../../../styles/images/browse_icon_blue1.svg);
            }
            .browse_text {
              color: #38539a;
              border-bottom: 1px solid #38539a;
            }
            .text {
              font-family: 'SourceSansPro';
              color: #38539a;
            }
            .browse_text_secondary {
              color: #38539a;
              border-bottom: none;
            }
          }

          .file_input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
            display: none;
          }
          .browse_img {
            width: 35px;
            height: 35px;
            background-image: url(../../..//styles/images/browse_icon_black.svg);
            background-repeat: no-repeat;
            background-size: cover;
          }
          .browse_text {
            font-family: 'SourceSansPro';
            display: block;
            border-bottom: 1px solid #707070;

            &:hover {
              color: #38539a;
              border-bottom: 1px solid #38539a;
            }
          }
          .browse_text_secondary {
            border-bottom: none;
            display: block;
          }
          .txt {
            display: block;
            border: none;
            margin-bottom: 20px;
          }
          @include media_query(XS) {
            .browse_img {
              margin-bottom: 50px;
            }
            .browse_text {
              margin-bottom: 0;
            }
            .browse_text_secondary {
              display: none;
            }
            .text {
              display: none;
            }
          }
          @include media_query(M) {
            font-family: 'SourceSansPro';
            font-size: 18px;
            line-height: 21px;
            height: 350px;
            .browse_img {
              margin-bottom: 40px;
            }
            .browse_text {
              margin-bottom: 20px;
            }
            .browse_text_secondary {
              display: block;
              border-bottom: none !important;
            }
            .text {
              display: block;
            }
          }
          @include media_query(L) {
            // width: 100%;
          }
        }
        .browse_wrapper {
          width: 100%;
          height: auto;
          .scale_wrapper {
            padding: 0;

            // min-height: 400px;
            .label {
              font-family: 'SourceSansPro';
              display: none;
              font-size: 21px;
              font-weight: 500;
              color: #2c2c2c;
              margin-bottom: 10px;
              @include media_query(M) {
                display: block;
              }
            }
            .image_wrapper {
              margin-bottom: 15px;
              object-fit: cover;
              .loading_image {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 12px;
                .img_prop {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
                .cropped_img_prop {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .btn_wrapper {
              width: 100%;
              height: 35px;
              display: none;

              .inner_btn_wrapper {
                width: 280px;
                height: 100%;
                border-radius: 20px;
                background-color: #f1f2ff;
                display: flex;
                cursor: pointer;
                .fill_btn {
                  width: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  &.active {
                    background-color: #38539a;
                    border-radius: 24px;
                  }
                  .fill_image {
                    width: 15px;
                    height: 15px;
                    background-image: url(../../../styles/images/inactive_fill_icon.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                  }
                  .active_fill_image {
                    width: 15px;
                    height: 15px;
                    background-image: url(../../../styles/images/fill_icon.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                  }
                }
                .crop_btn {
                  width: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  &.active {
                    background-color: #38539a;
                    border-radius: 24px;
                  }
                  .crop_image {
                    width: 15px;
                    height: 15px;
                    background-image: url(../../../styles/images/crop_icon.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                  }
                  .active_crop_image {
                    width: 15px;
                    height: 15px;
                    background-image: url(../../../styles/images/active_crop_icon.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                  }
                }
              }
              @include media_query(M) {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            &.left_wrapper {
              font-family: 'SourceSansPro';
              margin-bottom: 25px;
              .image_wrapper {
                width: 100%;
                height: 200px;

                .loading_image {
                  // background-image: url(../../../styles/images/mask1.svg);
                }
                @include media_query(M) {
                  height: 350px;
                }
              }
              @include media_query(M) {
                margin-bottom: 40px;
              }
              @include media_query(L) {
                padding-right: 40px;
                margin-bottom: 0;
              }
            }
            &.right_wrapper {
              font-family: 'SourceSansPro';
              display: none;
              .image_wrapper {
                width: 200px;
                height: 200px;

                .loading_image {
                  // background-image: url(../../../styles/images/mask2.svg);
                }
                @include media_query(M) {
                  width: 350px;
                  height: 350px;
                }
              }
              .btn_wrapper {
                width: 350px;
              }
              @include media_query(M) {
                display: block;
              }
              @include media_query(L) {
                padding: 0 40px;
              }
            }
            @include media_query(L) {
              width: 50%;
            }
          }
          &.loading_mask_wrapper {
            .image_wrapper {
              opacity: 0.2;
            }
            .loading_btn_wrapper {
              background: linear-gradient(90deg, #c5c5c5 17.83%, rgba(196, 196, 196, 0) 100%);
              .inner_btn_wrapper {
                display: none;
              }
            }
          }
          @include media_query(L) {
            display: flex;
          }
        }
        @include media_query(M) {
          border-top: 1px solid #e4e4e4;
        }
      }

      @include media_query(M) {
        padding: 0 40px 40px;
      }
    }
    .btn_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 45px 0px;
      order: 3;
      @include media_query(XS) {
        display: none;
      }
      @include media_query(M) {
        display: flex;
      }
      &.btn_xs {
        padding-bottom: 25px;
        @include media_query(M) {
          display: none;
        }
      }
      &.justify_left {
        justify-content: left;
      }
      .btn {
        font-family: 'SourceSansPro';
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 120px;
        height: 35px;
        font-size: 16px;
        font-weight: 700;
        border: 2px solid #2c2c2c;
        background-color: #fff;
        border-radius: 24px;
        margin: 0 6px 10px;
        padding: 0 30px;
        white-space: nowrap;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
        }
        &.active {
          background-color: #2c2c2c;
          border: 2px solid transparent;
          color: #fff;
        }
        &.disabled {
          pointer-events: none;
          background-color: #d9d9d9;
          color: #5e5e5e;
          border: 2px solid transparent;
        }
        &.m_0 {
          margin: 0;
        }
      }
    }

    @include media_query(M) {
      padding-left: 65px;
      padding-top: 110px;
      display: block;
      height: 100%;
      padding-bottom: 0;
    }
    @include media_query(L) {
      // padding-left: 0;
      padding-left: 65px;
    }
    // &.content_mgt_wrapper_disable_side_nav {
    //   width: calc(100%) !important;
    //   z-index: 6 !important;
    //   position: relative !important;
    //   @include media_query(XS) {
    //     left: auto !important;
    //   }
    //   @include media_query(M) {
    //     left: -65px !important;
    //   }
    //   .header_main_wrapper {
    //     @include media_query(M) {
    //       padding-left: 65px;
    //     }
    //     @include media_query(L) {
    //       position: fixed;
    //       width: calc(100%);
    //       top: 0;
    //       z-index: 3;
    //       margin-bottom: 40px;
    //       padding-left: 0;
    //     }
    //     .header_wrapper {
    //       display: flex;
    //       align-items: center;
    //       background: #ffffff;
    //       box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    //       // height: 110px;
    //       padding: 0 40px;
    //       @include media_query(XS) {
    //         min-height: 35px;
    //         padding-bottom: 15px;
    //         justify-content: center;
    //       }
    //       @include media_query(M) {
    //         min-height: auto;
    //         height: 110px;
    //         padding-bottom: 0;
    //         justify-content: left;
    //       }
    //       .heading {
    //         font-family: 'SourceSansPro';
    //         font-weight: 700;
    //         color: #2c2c2c;
    //         @include media_query(XS) {
    //           font-family: 'SourceSansPro';
    //           font-size: 24px;
    //         }
    //         @include media_query(M) {
    //           font-family: 'SourceSansPro';
    //           font-size: 32px;
    //         }
    //       }
    //     }
    //   }
    // }
    &.audience_wrapper {
      width: 100%;
      height: 100vh !important;
      overflow-y: auto;
      display: block;
      padding-bottom: 70px;
      z-index: 3 !important;
      position: relative;
      background: white !important;
      @include media_query(XS) {
        width: 100%;
        padding-left: 0;
        padding-top: 0;
      }
      @include media_query(M) {
        padding-top: 150px;
        // padding-left: 65px;
        height: 100vh !important;
        width: 100%;
      }
      @include media_query(M) {
        padding-top: 150px;
        // padding-left: 65px;
        height: 100vh !important;
        width: 100%;
      }
    }
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e4e4e4;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }

  .edit_image_container {
    display: none;
    width: 100%;
    height: 100%;
    padding-top: 110px;

    @include media_query(M) {
      padding-left: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include media_query(L) {
      // padding-left: 0px;
      padding-left: 65px;
    }

    .edit_image_wrapper {
      width: 100%;
      height: 75%;
      padding: 40px;
      object-fit: cover;

      @include media_query(L) {
        height: 100%;
      }

      .edit_image {
        width: 100%; //Should be Image size
        height: 100%; //Should be Image size
        background-image: url(../../../styles/images/unsplash_image.svg);
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
        background-position: center;
      }
    }
  }
  .header_container {
    z-index: 4; 
    position: fixed;
  }
}
