@import '../../../../styles/common/media_queries';

.modal-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  background-color: #fff;
  overflow: auto;

  @include media_query(XS) {
    width: 100%;
    height: 100%;
    padding: 0;
    padding-right: 4px;
  }
  @include media_query(M) {
    width: 75%;
    height: 75%;
    padding-right: 8px;
  }
  @include media_query(L) {
    width: 1025px;
    height: 585px;
    padding: 20px 30px 15px 30px;
  }
}

.cropping-modal-container {
  position: relative;
  margin: auto;
  background-color: #fff;
  padding: 20px 50px 15px 50px;
  overflow-y: auto;
  @include media_query(XS) {
    width: 100%;
    height: 100%;
  }
  @include media_query(M) {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 80%;
    padding: 0;
    padding-right: 8px;
  }
  @include media_query(L) {
    width: 1025px;
    height: 650px;
    padding: 20px 50px 15px 50px;
  }
}

.modal-container-small {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  background-color: #fff;
  padding: 20px 30px 15px 30px;
  @include media_query(XS) {
    min-width: 315px;
    min-height: 230px;
    max-width: 100%;
    max-height: 400px;
  }
  @include media_query(M) {
    min-width: 390px;
    min-height: 230px;
    max-width: 400px;
    max-height: 400px;
  }
}

.modal-container-mini {
  position: relative;
  margin: auto;
  background-color: #fff;
  padding: 20px 30px;
  @include media_query(XS) {
    width: 335px;
    height: 260px;
  }
  @include media_query(M) {
    width: 400px;
    height: 300px;
  }
  @include media_query(L) {
  }
}
