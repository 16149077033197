@import '../../styles/common/media_queries';

// Notification drawer
.notification_wrapper {
  // z-index: 999 !important;
  position: fixed;
  top: 70px;
  bottom: 0px;
  left: 0px;
  max-height: 992px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  z-index: 5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  @include media_query(XS) {
    width: 100%;
  }
  @include media_query(M) {
    top: 15px;
    bottom: 15px;
    left: 80px;
    border-radius: 12px;
    width: 390px;
  }
  &.no_network_wrapper {
    @include media_query(XS) {
      max-height: 100vh;
    }
    @include media_query(M) {
      max-height: 350px;
    }
    .middle_body_wrapper {
      @include media_query(XS) {
        padding: 0 16px;
      }
      @include media_query(M) {
        padding: 0 0;
      }
      .inner_wrapper {
        @include media_query(XS) {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fafafa;
          height: calc(100% - 66px);
        }
        @include media_query(M) {
          display: block;
          background-color: #ffffff;
          height: calc(100% - 48px);
        }
      }
    }
  }
  &.server_down_wrapper {
    @include media_query(XS) {
      max-height: 100vh;
    }
    @include media_query(M) {
      max-height: 350px;
    }
    .middle_body_wrapper {
      @include media_query(XS) {
        padding: 0 16px;
      }
      @include media_query(M) {
        padding: 0 0;
      }
      .inner_wrapper {
        @include media_query(XS) {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fafafa;
          height: calc(100% - 66px);
        }
        @include media_query(M) {
          display: block;
          background-color: #ffffff;
          height: calc(100% - 48px);
        }
      }
    }
  }
  &.empty_screen_visible {
    .middle_body_wrapper {
      .empty_screen_wrapper {
        display: block;
      }
      .inner_wrapper {
        display: none;
      }
    }
    @include media_query(M) {
      height: auto;
      bottom: auto;
      .middle_body_wrapper {
        height: auto;
        .empty_screen_wrapper {
          display: block;
        }
        .inner_wrapper {
          display: none;
        }
      }
    }
  }
  .header {
    width: 100%;
    height: 65px;
    background-color: #fafafa;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2c2c2c;
    font-family: 'SourceSansPro';
    font-weight: 700;
    @include media_query(M) {
      border-radius: 12px 12px 0px 0px;
    }
    .title {
      font-family: 'SourceSansPro';
      font-size: 21px;
      line-height: 24px;
    }
    .btn_wrapper {
      font-family: 'SourceSansPro';
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      cursor: pointer;
      @include media_query(M) {
        font-size: 16px;
      }
      .close_icon {
        width: 14px;
        height: 14px;
        background-image: url(../../styles/images/x_close_black.svg);
        background-repeat: no-repeat;
        background-size: cover;
      }
      .btn_txt {
        color: #2c2c2c;
        border-bottom: 2px solid #2c2c2c;
      }
      .confirmation_btn {
        color: #ffffff;
        background-color: #2c2c2c;
        height: 36px;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
      }
    }
  }
  .tab_menu_bar {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding: 0 12px;
    .nav_item {
      width: auto;
      height: 24px;
      white-space: nowrap;
      background: #ffffff;
      border: 1px solid #38539a;
      box-sizing: border-box;
      border-radius: 24px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      font-family: 'SourceSansPro';
      font-size: 14px;
      font-weight: 500;
      color: #38539a;
      margin-right: 10px;
      cursor: pointer;

      &:first-child {
        min-width: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.selected {
        color: #f1f2ff;
        background: #38539a;
        .icon {
          &.course_icon {
            background-image: url(../../styles/images/course_icon_white.svg);
          }
          &.learn_path_icon {
            background-image: url(../../styles/images/books-white.svg);
          }
          &.img_library_icon {
            background-image: url(../../styles/images/img_library_white.svg);
          }
          &.aud_mgt_icon {
            background-image: url(../../styles/images/people-white.svg);
          }
          &.cst_icon {
            background-image: url(../../styles/images/category-skills-tags-selected-side-nav.svg);
          }
        }
      }
      &.disable {
        color: #707070;
        background: #ffffff;
        border: 1px solid #707070;
        .icon {
          &.course_icon {
            background-image: url(../../styles/images/course_icon_gray.svg);
          }
          &.learn_path_icon {
            background-image: url(../../styles/images/learning_path_icon_gray.svg);
          }
          &.img_library_icon {
            background-image: url(../../styles/images/img_lib_gray.svg);
          }
          &.aud_mgt_icon {
            background-image: url(../../styles/images/people-white.svg);
            // gray icon not available
          }
        }
      }
      .icon {
        height: 14px;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 5px;
        &.course_icon {
          width: 12px;
          background-image: url(../../styles/images/course_icon.svg);
        }
        &.learn_path_icon {
          width: 16px;
          background-image: url(../../styles/images/learning_icon.svg);
          &.selected {
            // background-image: url(../../styles/images/learning_icon_white.svg);
          }
        }
        &.img_library_icon {
          width: 18px;
          background-image: url(../../styles/images/img_library.svg);
          &.selected {
            background-image: url(../../styles/images/img_library_white.svg);
          }
        }
        &.aud_mgt_icon {
          width: 20px;
          background-image: url(../../styles/images/Users_blue.svg);
          &.selected {
            background-image: url(../../styles/images/people-white.svg);
          }
        }
        &.cst_icon {
          width: 14.5px;
          background-image: url(../../styles/images/CST-Hover-image.svg);
          &.selected {
            //background-image: url(../../styles/images/img_library_white.svg);
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
    height: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e4e4e4;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }
  .middle_body_wrapper {
    height: calc(100% - 65px);
    padding-right: 4px;
    .empty_screen_wrapper {
      display: none;
      margin-bottom: 25px;
      .img_wrapper {
        width: 100%;
        height: 105px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 25px 0 15px;
        .empty_img {
          width: 110px;
          height: 100%;
          background-image: url(../../styles/images/Illustration_sceen.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .txt {
        font-family: 'SourceSansPro';
        font-size: 16px;
        font-style: 500;
        color: #2c2c2c;
        line-height: 24px;
        text-align: center;
      }
    }
    .no_network_screen_wrapper {
      // display: none;
      margin-bottom: 25px;
      .img_wrapper {
        width: 100%;
        height: 105px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 15px;
        .no_network_img {
          width: 110px;
          height: 100%;
          background-image: url(../../styles/images/illustration_network.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .content_wrapper {
        text-align: center;
        color: #2c2c2c;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        .label {
          font-family: 'SourceSansPro';
          font-size: 18px;
          line-height: 21px;
          font-weight: 700;
          margin-bottom: 4px;
          @include media_query(M) {
            font-size: 16px;
            line-height: 28px;
          }
        }
        .content {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          @include media_query(M) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .server_down_screen_wrapper {
      // display: none;
      margin-bottom: 25px;
      .img_wrapper {
        width: 100%;
        height: 105px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 15px;
        .server_down_img {
          width: 110px;
          height: 100%;
          background-image: url(../../styles/images/illustration_server.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .content_wrapper {
        text-align: center;
        color: #2c2c2c;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        .label {
          font-family: 'SourceSansPro';
          font-size: 18px;
          line-height: 21px;
          font-weight: 700;
          margin-bottom: 4px;
          @include media_query(M) {
            font-size: 16px;
            line-height: 28px;
          }
        }
        .content {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          @include media_query(M) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .inner_wrapper {
      // padding-top: 15px;
      height: calc(100% - 48px);
      overflow-y: auto;
      .section_wrapper {
        &.unread_state_parent {
          .unread_state_title {
            background-color: #f1f2ff;
            .title {
              color: #38539a;
            }
            .calender_icon {
              width: 16px;
              height: 16px;
              background-image: url(../../styles/images/calendar_icon_purple.svg);
            }
          }

          .info_container {
            &.unread_state {
              background-color: #f1f2ff;
              .info_wrapper {
                background-color: #fff;
                .title_wrapper {
                  .title {
                    color: #38539a;
                  }
                  .description_txt {
                    color: #38539a;
                    font-family: 'SourceSansPro';
                    font-style: italic;
                    word-break: break-all;
                  }
                }
                .icon {
                  background-size: contain;
                  cursor: pointer;
                  &.close_icon {
                    background-image: url(../../styles/images/close_icon_purple.svg);
                  }
                  &.edit_icon {
                    width: 22px;
                    height: 19px;
                    background-image: url(../../styles/images/edit_icon_purple.svg);
                  }
                  &.online_icon {
                    width: 20px;
                    height: 16px;
                    background-image: url(../../styles/images/bulb-on-purple.svg);
                  }
                  &.offline_icon {
                    width: 20px;
                    height: 16px;
                    background-image: url(../../styles/images/bulb-off-purple.svg);
                  }
                  &.delete_icon {
                    width: 20px;
                    height: 16px;
                    background-image: url(../../styles/images/delete_noti_purple.svg);
                  }
                  &.archive_icon {
                    width: 20px;
                    height: 16px;
                    background-image: url(../../styles/images/box-purple.svg);
                  }
                  &.create_icon {
                    width: 20px;
                    height: 16px;
                    background-image: url(../../styles/images/book-purple.svg);
                  }
                  &.cst_icon {
                    width: 20px;
                    height: 16px;
                    background-image: url(../../styles/images/tag-purple.svg);
                  }
                }
              }
            }
          }
        }

        .info_container {
          &.unread_state {
            background-color: #f1f2ff;
            .info_wrapper {
              background-color: #fff;
              .title_wrapper {
                .title {
                  color: #38539a;
                }
                .description_txt {
                  color: #38539a;
                  font-family: 'SourceSansPro';
                  font-style: italic;
                  word-break: break-all;
                }
              }
              .icon {
                background-size: contain;
                cursor: pointer;
                &.close_icon {
                  background-image: url(../../styles/images/close_icon_purple.svg);
                }
                &.edit_icon {
                  width: 22px;
                  height: 19px;
                  background-image: url(../../styles/images/edit_icon_purple.svg);
                }
                &.online_icon {
                  width: 20px;
                  height: 16px;
                  background-image: url(../../styles/images/bulb-on-purple.svg);
                }
                &.offline_icon {
                  width: 20px;
                  height: 16px;
                  background-image: url(../../styles/images/bulb-off-purple.svg);
                }
                &.delete_icon {
                  width: 20px;
                  height: 16px;
                  background-image: url(../../styles/images/delete_noti_purple.svg);
                }
                &.archive_icon {
                  width: 20px;
                  height: 16px;
                  background-image: url(../../styles/images/box-purple.svg);
                }
                &.create_icon {
                  width: 20px;
                  height: 16px;
                  background-image: url(../../styles/images/book-purple.svg);
                }
                &.cst_icon {
                  width: 20px;
                  height: 16px;
                  background-image: url(../../styles/images/tag-purple.svg);
                }
              }
            }
          }
        }
        .title_wrapper {
          display: flex;
          align-items: center;
          padding-left: 15px;
          padding-top: 10px;
          padding-bottom: 8px;
          .calender_icon {
            width: 16px;
            height: 16px;
            background-image: url(../../styles/images/calendar_icon.svg);
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 10px;
          }
          .title {
            font-family: 'SourceSansPro';
            font-size: 16px;
            font-weight: 700;
            color: #2c2c2c;
            line-height: 18px;
          }
        }
        .info_container {
          padding: 8px 15px;
          .info_wrapper {
            min-height: 88px;
            padding: 0 35px;
            box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
            border-radius: 12px;
            position: relative;
            .title_wrapper {
              display: block;
              line-height: 24px;
              padding: 10px 0;
              .description_txt {
                font-family: 'SourceSansPro';
                font-size: 14px;
                font-weight: 400;
                color: #2c2c2c;
                font-style: italic;
                word-break: break-all;
                @include media_query(M) {
                  font-size: 16px;
                }
              }
            }
            .icon {
              background-repeat: no-repeat;
              background-size: contain;

              &.close_icon {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 12px;
                height: 12px;
                background-image: url(../../styles/images/close_icon_black.svg);
                cursor: pointer;
              }
              &.left_icons {
                position: absolute;
                top: 10px;
                left: 10px;
              }
              &.edit_icon {
                width: 20px;
                height: 16px;
                background-image: url(../../styles/images/edit_icon_black.svg);
              }
              &.online_icon {
                width: 20px;
                height: 16px;
                // background-image: url(../../styles/images/bulb-on-purple.svg);
                background-image: url(../../styles/images/online_iocn_black.svg);
              }
              &.offline_icon {
                width: 20px;
                height: 16px;
                // background-image: url(../../styles/images/bulb-off-purple.svg);
                background-image: url(../../styles/images/offline_icon_black.svg);
              }
              &.delete_icon {
                width: 20px;
                height: 16px;
                // background-image: url(../../styles/images/bulb-off-purple.svg);
                background-image: url(../../styles/images/delete_noti_black.svg);
              }
              &.archive_icon {
                width: 20px;
                height: 16px;
                background-image: url(../../styles/images/box-black.svg);
              }
              &.create_icon {
                width: 20px;
                height: 16px;
                background-image: url(../../styles/images/course_black_icon.svg);
              }

              &.course_black_icon {
                width: 16px;
                height: 18px;
                background-image: url(../../styles/images/course_black_icon.svg);
              }
              &.gift_black_icon {
                width: 16px;
                height: 14px;
                background-image: url(../../styles/images/gift_black_icon.svg);
              }
              &.cst_icon {
                width: 16px;
                height: 18px;
                background-image: url(../../styles/images/tag-black.svg);
              }
            }
          }
        }
      }
    }
    // Scrollbar code
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e4e4e4;
      mix-blend-mode: normal;
      opacity: 0.5;
      border-radius: 100px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #707070;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #707070;
    }
  }
}
