@import '../../../styles/common/media_queries';

.api_fail_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  .api_fail_img {
    width: 75px;
    height: 65px;
    margin-bottom: 16px;
    background-image: url(../../../styles/images/api_fails.svg);
    background-repeat: no-repeat;
  }
  .sub_text {
    font-family: 'SourceSansPro';
    font-weight: 500;
    margin-bottom: 16px;
    @include media_query(XS) {
      font-size: 18px;
    }
    @include media_query(M) {
      font-size: 21px;
    }
  }
  .try_again_btn {
    display: flex;
    align-items: center;
    font-family: 'SourceSansPro';
    font-weight: 500;
    color: #38539a;
    cursor: pointer;
    @include media_query(XS) {
      font-size: 16px;
    }
    @include media_query(M) {
      font-size: 18px;
    }
    .refresh_img {
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../../../styles//images/undo-blue.svg);
      margin-left: 4px;
    }
  }
}
