@import '../../../../../styles/common/media_queries';

.card_container {
  font-family: 'SourceSansPro';
  cursor: pointer;
  .courses_icon {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 17px;
    background-image: url(../../../../../styles/images/files.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 4px;
  }
  .learning_path_icon {
    display: inline-block;
    vertical-align: middle;
    width: 19px;
    height: 18px;
    background-image: url(../../../../../styles/images/folder.svg);
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 4px;
  }
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // justify-content: space-between;
  min-height: 150px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 2px 16px 0px rgba(44, 44, 44, 0.1);
  @include media_query(XS) {
    width: 100%;
  }
  @include media_query(M) {
    width: calc(50% - 18px);
  }
  @include media_query(L) {
    width: calc(25% - 18px);
  }

  &.drawer_open {
    @include media_query(XS) {
      width: 100%;
    }
    @include media_query(M) {
      width: calc(50% - 18px);
    }
    @include media_query(L) {
      width: calc(33.3% - 18px);
    }
  }

  .heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .course_count {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    .type_container {
      display: flex;
      gap: 8px;
      .type_name {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
  .cstdetails {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #2c2c2c;
    line-height: 24px;
    text-overflow: ellipsis;
    .cstTitle {
      line-break: anywhere;
    }
    span {
      font-weight: 700;
      margin-right: 4px;
    }
  }
}
