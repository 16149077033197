@import '../../../styles/common/media_queries';

.modal_wrapper {
  //   position: fixed;
  //   display: flex;
  //   align-items: center;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background: rgba(112, 112, 112, 0.7);
  //   z-index: 4;
  //   .replace_img_modal {
  //     display: flex;
  //     flex-direction: column;
  //     position: relative;
  //     margin: auto;
  //     background-color: #fff;
  //     overflow: auto;

  //     @include media_query(XS) {
  //       width: 100%;
  //       height: 100%;
  //       padding: 0;
  //       padding-right: 4px;
  //     }
  //     @include media_query(M) {
  //       width: 75%;
  //       height: 75%;
  //       padding-right: 8px;
  //     }
  //     @include media_query(L) {
  //       width: 1025px;
  //       height: 585px;
  //       padding: 20px 30px 15px 30px;
  //     }

  .header_wrapper {
    padding: 20px 15px 0px 15px;
    margin-bottom: 15px;
    @include media_query(M) {
      padding: 20px 10px 0px 30px;
    }
    @include media_query(L) {
      padding: 0px 10px 35px 0px;
      margin: 0;
    }
    .heading {
      font-family: 'SourceSansPro';
      font-size: 24px;
      font-weight: 700;
      color: #2c2c2c;
    }
  }

  .close_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    background-color: #2c2c2c;
    cursor: pointer;

    .close_icon {
      width: 10px;
      height: 10px;
      background-image: url(../../../styles/images/close-white.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .modal_middle_body {
    width: 100%;
    overflow: auto;
    @include media_query(XS) {
      padding: 0 12px 70px 15px;
    }
    @include media_query(M) {
      padding: 20px 30px 15px 30px;
    }
    @include media_query(L) {
      padding: 0;
    }
    // overflow-y: auto;
    .top_inner_wrapper {
      display: flex;
      flex-direction: column;
      @include media_query(M) {
        flex-direction: column;
      }
      @include media_query(L) {
        flex-direction: row;
      }
      .title_wrapper {
        font-family: 'SourceSansPro';
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .title {
          font-size: 16px;
          font-weight: 500;
          color: #2c2c2c;
        }
        @include media_query(M) {
          // min-height: 50px;
          min-height: auto;
        }
        @include media_query(L) {
          margin-bottom: 20px;
        }
      }
      .left_section_wrapper {
        width: 100%;
        margin-bottom: 25px;
        @include media_query(M) {
          width: 100%;
          padding-right: 0px;
        }
        @include media_query(L) {
          width: 50%;
          padding-right: 20px;
        }
        .current_img {
          width: 100%;
          min-height: 195px;
          //background-image: url(../../../styles/images/sample_img2.svg);
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 12px;
          margin-bottom: 12px;
          @include media_query(L) {
            height: 260px;
          }
        }
        .checkbox_container {
          font-family: 'SourceSansPro';
          // padding: 15px 0;
          display: flex;
          // align-items: center;
          .checkbox_note {
            font-size: 16px;
            font-weight: 500;
            color: #2c2c2c;
          }
        }
      }
      .right_section_wrapper {
        margin-bottom: 15px;
        @include media_query(XS) {
          width: 100%;
          padding-right: 0px;
        }
        @include media_query(L) {
          width: 50%;
          padding-left: 20px;
        }
        .tag_text {
          font-family: 'SourceSansPro';
          font-size: 14px;
          color: #707070;
          display: flex;
          align-items: center;
          justify-content: end;
          padding-top: 12px;

          @include media_query(M) {
            font-size: 16px;
          }
          @include media_query(L) {
            padding-right: 15px;
          }
        }
        .pre_browse_wrapper {
          width: 100%;
          height: 195px;
          border: 2px dashed #707070;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 25px 0;
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 600;
          color: #707070;
          text-align: center;
          cursor: pointer;

          &.hover_state {
            border: 2px dashed #38539a;
            background-color: #f1f2ff;
            .browse_img {
              background-image: url(../../../styles/images/browse_icon_blue1.svg);
            }
            .browse_text {
              color: #38539a;
              border-bottom: 1px solid #38539a;
            }
            .text {
              color: #38539a;
            }
          }
          .browse_img {
            width: 35px;
            height: 35px;
            background-image: url(../../..//styles/images/browse_icon_black.svg);
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 40px;
          }
          .browse_text {
            // text-decoration: underline;
            border-bottom: 1px solid #707070;
            margin-bottom: 25px;
            &:last-child {
              margin: 0;
            }
          }
          .text {
            margin-bottom: 25px;
          }
          @include media_query(M) {
            font-family: 'SourceSansPro';
            font-size: 18px;
          }
          @include media_query(L) {
            height: 260px;
          }
        }

        .img_wrapper {
          width: 100%;
          height: auto;
          .replaced_img {
            width: 100%;
            height: 195px;
            // background-image: url(../../../styles/images/image_sample4.svg);
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 12px;
            @include media_query(L) {
              height: 260px;
            }
          }
        }

        .image_upload_wrapper {
          @include media_query(XS) {
            width: 100%;
          }
          @include media_query(L) {
            width: 450px;
          }
        }
      }
      .btn_wrapper {
        width: 100%;
        height: 35px;
        display: none;
        margin-top: 15px;

        &.ratio_btn {
          width: 120px;
          margin-top: 0;
          padding: 0;
          font-family: 'SourceSansPro';
          font-size: 16px;
          font-weight: 500;
          color: #38539a;
          cursor: pointer;
          .inner_btn_wrapper {
            border-radius: 24px;
            .ratio_scale {
              width: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              &.ratio1 {
              }
              &.ratio2 {
              }
              &.active {
                color: #fff;
                background-color: #38539a;
                border-radius: 24px;
              }
            }
          }
          // @include media_query(L) {
          //   display: flex;
          // }
        }
        .inner_btn_wrapper {
          width: 280px;
          height: 100%;
          border-radius: 20px;
          background-color: #f1f2ff;
          display: flex;
          cursor: pointer;
          .fill_btn {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              background-color: #38539a;
              border-radius: 24px;
            }
            .fill_image {
              width: 15px;
              height: 15px;
              background-image: url(../../../styles/images/fill_icon.svg);
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
          .crop_btn {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              background-color: #38539a;
              border-radius: 24px;
            }
            .crop_image {
              width: 15px;
              height: 15px;
              background-image: url(../../../styles/images/crop_icon.svg);
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
        @include media_query(M) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        // @include media_query(L) {
        //   display: none;
        // }
      }
      .img_details_opt_wrapper {
        display: none;

        .info_text {
          font-family: 'SourceSansPro';
          font-size: 16px;
          font-weight: 600;
          color: #2c2c2c;
          padding-right: 10px;
          cursor: pointer;
        }
        .left_arrow_icon {
          width: 12px;
          height: 18px;
          background-image: url(../../../styles/images/left_arrow_icon.svg);
          background-repeat: no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        @include media_query(M) {
          margin: 15px 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
    // top section added
    .bottom_section {
      width: 100%;
      height: auto;
      padding: 0px 0 15px;
      display: flex;
      flex-direction: column;
      .title_wrapper_img {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .title {
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
        }
      }
      .left_container {
        flex: 1;
        // padding: 0 15px;
        margin-bottom: 25px;

        @include media_query(M) {
          padding: 0 40px;
        }
        @include media_query(L) {
          border-right: 1px solid #e4e4e4;
        }
      }
      .right_container {
        flex: 1;
        // padding: 0 15px;
        .info_container {
          margin-bottom: 15px;
        }

        .pills_container {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;
          @include media_query(M) {
            margin-bottom: 0;
          }
        }
        .suggestion_text {
          font-family: 'SourceSansPro';
          font-size: 14px;
          min-height: 50px;
          color: #a36a00;
          font-weight: 400;
          background: #fff9f0;
          border: 1px solid #d1b278;
          border-radius: 12px;
          padding: 10px;
          float: left;
          @include media_query(M) {
            display: none;
          }
        }
        @include media_query(M) {
          padding: 0 40px;
        }
      }
      .info_container {
        width: 100%;
        margin-bottom: 25px;
        &:last-child {
          margin: 0;
        }
        .label_wrapper {
          font-family: 'SourceSansPro';
          font-size: 16px;
          color: #2c2c2c;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
          .tag_text {
            font-family: 'SourceSansPro';
            font-size: 14px;
            color: #707070;

            @include media_query(M) {
              font-size: 16px;
            }
          }
          @include media_query(M) {
            font-size: 21px;
          }
        }
        .dropdown_container {
          position: absolute;
          width: 100%;
          top: 45px;
          background-color: #fff;
          border-radius: 10px;
          z-index: 1;
          border: 2px solid #707070;
          padding: 10px 0;
          .no_results {
            padding: 0 15px;
          }
          .list_wrapper {
            max-height: 185px;
            overflow: auto;
            .list {
              font-family: 'SourceSansPro';
              display: flex;
              align-items: center;
              padding: 0 16px;
              font-size: 14px;
              font-weight: 500;
              color: #2c2c2c;
              cursor: pointer;
              @include media_query(XS) {
                height: auto;
                margin-bottom: 20px;
              }
              @include media_query(M) {
                min-height: 35px;
                margin-bottom: 0;
              }
              &:hover {
                font-weight: 700;
                color: #38539a;
                background-color: #f4f4f4;
              }
            }
          }
        }
        &.error_wrapper {
          .form_field {
            .input_wrapper {
              font-family: 'SourceSansPro';
              .input {
                border: 2px solid #d91734;
              }
              .error_icon_red {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 12px;
                width: 20px;
                height: 20px;
                background-image: url(../../../styles/images/error_icon_red.svg);
                background-size: cover;
                background-repeat: no-repeat;
              }
            }
          }

          .error_message {
            font-family: 'SourceSansPro';
            color: #d91734;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            padding: 10px 0;
          }
        }

        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #e4e4e4;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #707070;
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }
        .form_field {
          position: relative;
          .input_wrapper {
            font-family: 'SourceSansPro';
            display: flex;
            align-items: center;
            position: relative;
            .input_area {
              position: relative;
              width: calc(100% - 120px);
            }
            .input {
              font-family: 'SourceSansPro';
              width: 100%;
              height: 35px;
              border: 2px solid #2c2c2c;
              border-radius: 10px;
              font-size: 14px;
              line-height: 35px;
              font-weight: 500;
              color: #2c2c2c;
              padding: 0 12px;
              &.active {
                border: 2px solid #38539a;
              }
              @include media_query(M) {
                font-size: 16px;
              }
              &.ip2 {
                // width: calc(100% - 120px);
              }
              &::placeholder {
                font-weight: 200;
              }
            }
            .textarea {
              width: 100%;
              min-height: 90px;
              line-height: initial;
              border: 2px solid #2c2c2c;
              border-radius: 10px;
              font-size: 14px;
              font-family: 'SourceSansPro';
              font-weight: 500;
              color: #2c2c2c;
              padding: 10px 12px;
              resize: vertical;
              overflow: auto;
              &:focus {
                outline: none;
                border: 2px solid #2c2c2c;
              }
              @include media_query(M) {
                font-size: 16px;
              }
              &::placeholder {
                font-weight: 200;
              }
            }
            .add_btn {
              font-family: 'SourceSansPro';
              width: 120px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              font-weight: 700;
              color: #5e5e5e;
              background: #d9d9d9;
              border-radius: 24px;
              margin-left: 10px;
              cursor: pointer;
              .plus_sign {
                width: 12px;
                height: 12px;
                background-image: url(../../../styles/images/plus_icon.svg);
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 8px;
              }
              &.selected {
                background-color: #2c2c2c;
                color: #fff;
                .plus_sign {
                  background-image: url(../../../styles/images/add-white.svg);
                }
              }
            }
          }
        }
      }
      @include media_query(M) {
        display: none;
      }
    }
    // top section ended
    .checkbox_container {
      display: none;
      @include media_query(M) {
        display: flex;
        align-items: center;
      }
    }
    .warning_section {
      display: flex;
      background: #fff9f0;
      border: 1px solid #d1b278;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 30px;
      @include media_query(M) {
        display: none;
      }
      .message_section {
        padding-left: 5px;

        .mb_5 {
          margin-bottom: 5px;
        }

        .heading {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 400;
          color: #a36a00;
        }
      }
    }
  }

  .btn_wrapper {
    font-family: 'SourceSansPro';
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @include media_query(XS) {
      flex-direction: row;
      position: fixed;
      bottom: 0;
      width: 100%;
      align-items: center;
      left: -50%;
      right: 0;
      height: 68px;
      transform: translate(50%, 0%);
      box-shadow: 0px -2px 6px rgba(44, 44, 44, 0.1);
      background-color: #fff;
      justify-content: center;
    }
    @include media_query(M) {
      position: relative;
      flex-direction: row;
      align-items: center;
      justify-content: left;
      justify-content: center;
      transform: translate(0%, 0%);
      left: auto;
      right: auto;
      height: auto;
      box-shadow: none;
      background-color: transparent;
      padding-top: 25px;
      .styles.btn1 {
        order: 2;
      }
    }
    .btn {
      min-width: 120px;
      height: 35px;
      font-family: 'SourceSansPro';
      font-size: 16px;
      font-weight: 600;
      border: 2px solid #2c2c2c;
      background-color: #fff;
      border-radius: 24px;
      margin: 0 6px;
      padding: 0 30px;
      cursor: pointer;
      margin-bottom: 10px;
      @include media_query(XS) {
        margin-bottom: 0;
      }
      @include media_query(M) {
        margin-bottom: 10px;
      }
      &:last-child {
        margin-bottom: 10px;
        @include media_query(XS) {
          margin-bottom: 0;
        }
        @include media_query(M) {
          margin-bottom: 10px;
        }
      }

      &.active {
        background-color: #2c2c2c;
        border: 2px solid transparent;
        color: #fff;
      }
      &.disabled {
        pointer-events: none;
        background-color: #d9d9d9;
        color: #5e5e5e;
        border: 2px solid transparent;
      }

      &.btn1 {
        @include media_query(M) {
          order: 2;
        }
      }
    }
  }
  //   }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e4e4e4;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }
}
