@import '../../../styles/common/media_queries';


.search_main_wrapper {
  position: relative;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e4e4e4;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }

  &.active {
    .dropdown_container,
    .search_wrapper {
      border: 2px solid #38539a;
    }
  }
}

.dateSelectorDropDownContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    border-radius: 12px;
    border: 1px solid var(--gray-30-light-gray-rule-lines, #E4E4E4);
    background: var(--primary-2-pure-white, #FFF);
    height: 100%;
    padding: 0px 16px;
    &.disabled{
      pointer-events: none;
      cursor: not-allowed;
      border: 1px solid var(--gray-30-light-gray-rule-lines, #e4e4e4);
    background: var(--gray-28-light-gray-small-bgs, #f4f4f4);
      
    }
    &.active {
      border: 2px solid var(--primary-1-amway-black, #2C2C2C);
    }

    @media screen and (max-width: 992px) {
      padding: 2px 16px;
      margin-bottom: 24px;
    }

    .selectedDate{
        color: var(--gray-33-dark-gray-text-on-white-bg, #707070);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        &.active {
          color: #2C2C2C;
        }
    }
    .icon_wrapper {
        margin-left: auto;
        .upArrow {
          width: 18px;
          height: 18px;
          background-image: url(../../../styles/images/up_arrow_black.svg);
          background-repeat: no-repeat;
          cursor: pointer;
        }
        .downArrow {
            width: 18px;
            height: 18px;
            background-image: url(../../../styles/images/down_arrow_2.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            &.isDisabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
      }
}

.calendarContainer {
    margin-top: 8px;
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  border-radius: 12px;
  max-width: 390px;
  border: 2px solid var(--primary-3-dark-purple, #38539a);
  background: var(--primary-2-pure-white, #fff);
  .calendarHeader {
    padding: 0px 24px;
    margin: 20px 0px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .calendarHeaderText {
      color: var(--primary-1-amway-black, #2c2c2c);
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 133.333% */
    }
    .closeIcon {
      background: url('../../../styles/images/x_close_black.svg');
      height: 13px;
      width: 15px;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
  .calendarDescription {
    padding: 16px 24px;
    border-top: 2px solid #e4e4e4;
  }

  .filter_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    box-shadow: 0px -2px 6px 0px rgba(44, 44, 44, 0.1);
    @include media_query(XS) {
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px;
      box-shadow: 0px -2px 6px rgba(44, 44, 44, 0.1);
    }
    @include media_query(M) {
      margin-top: auto;
      padding: 25px 0;
    }
    .m_lr_6 {
      margin: 0 6px;
    }
    .btn {
      font-family: 'SourceSansPro';
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 2px solid #2c2c2c;
      color: #2c2c2c;
      border-radius: 24px;
      height: 36px;
      outline: none;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      &.dark {
        background: #2c2c2c;
        color: #fff;
      }
      &:first-child {
        width: 35%;
      }
      &:last-child {
        @include media_query(XS) {
          min-width: 65%;
        }
        @include media_query(M) {
          min-width: 33%;
        }
      }
      @include media_query(M) {
        min-width: 120px;
        max-width: 150px;
      }
    }
    .btn_disable {
      font-family: 'SourceSansPro';
      display: flex;
      align-items: center;
      justify-content: center;
    background: var(--gray-35-disabled-gray-button-bg, #D9D9D9);
      border: 2px solid #d9d9d9;
      color: var(--gray-37-disabled-gray-on-35-gray, #5E5E5E);
      border-radius: 24px;
      height: 36px;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      pointer-events: none;
      outline: none;
      &.dark {
        background: #d9d9d9;
        color: #5e5e5e;
      }
      &:first-child {
        width: 35%;
      }
      &:last-child {
        @include media_query(XS) {
          min-width: 65%;
        }
        @include media_query(M) {
          min-width: 33%;
        }
      }
      @include media_query(M) {
        min-width: 120px;
        max-width: 150px;
      }
    }
  }
}
.dropdowns {
  font-weight: 500;
  color: #38539a;
  font-family: 'gtWalsheim';
}
button.button_previous {
  border-radius: 24px 0px 0px 24px ;
  background: var(--primary-4-light-purple, #f1f2ff) ;
  width: 40px ;
  height: 24px ;
}
button.button_next {
    border-radius: 0px 24px 24px 0px;
    background: var(--primary-4-light-purple, #f1f2ff);
    width: 40px;
    height: 24px;
    margin-left: 4px;
  }

button.day_button{
    background-color: #38539A;

}
.weekday {
  font-weight: bolder;
}
.root {
  padding: 16px 0px;
  font-family: 'gtWalsheim';
}
.months {
  margin: 0 auto;
}
// .selected {
// //   border: none;
//   background: #38539a;
//   color:white
// }
.day_button {
  background: #38539a;
}
table thead tr > th:last-child,
table thead tr > td:last-child,
table tbody tr > th:last-child,
table tbody tr > td:last-child,
table tbody tr > td:first-child,
table thead tr > th:first-child
{
  border: none;
  border-radius: 0; /* Removes border radius */
}

/* styles.module.css */
.range_start :global(.rdp-day_button) {
  background-color: #38539a !important;
  color: #fff !important;
  border: none !important;
}
.range_end :global(.rdp-day_button) {
  background-color: #38539a !important;
  color: #fff !important;
  border: none !important;
}

.selected :global(.rdp-day_button) {
  background-color: #38539a !important;
  color: #fff !important;
  border-radius: 50% !important; 
  border: 1px solid #38539a !important;
  font-weight: 100;
}

.timeContainer{
    min-width: 100%;
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .timeTextContainer{
        min-width: 50%;
        display: flex;
        gap:8px;
        align-items: center;
        .icon_wrapper{
            .timeWatch{
                width: 18px;
                height: 18px;
                background-image: url(../../../styles/images/time_watch.svg);
                background-repeat: no-repeat;
                cursor: pointer;
            }
        }
    }
    .timeInputContainer{
        display: flex;
        justify-content: space-between;
        gap:8px;
        .inputBoxes{
            display: flex;
            height: 36px;
            padding: 3px 6px;
            align-items: center;
            gap: 4px;
            align-self: stretch;
            border-radius: 12px;
            border: 2px solid var(--primary-1-amway-black, #2C2C2C);
            background: var(--primary-2-pure-white, #FFF);
            max-width: 45px;
            color: var(--primary-1-amway-black, #2C2C2C);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
        }
        .hourContainer{
            
        }
        .minContainer{

        }
        .periodContainer{

        }
    }
}