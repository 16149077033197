@import '../../../styles/common/media_queries';

.modal_wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(112, 112, 112, 0.7);
  z-index: 10;
  .courses_modal {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    background-color: #fff;
    @include media_query(XS) {
      min-width: 100%;
      max-width: 100%;
      min-height: 100vh;
      max-height: 100vh;
      padding: 20px 0px 0px 0px;
    }
    @include media_query(M) {
      min-width: 850px;
      min-height: 230px;
      max-width: 950px;
      max-height: 650px;
      padding: 20px 30px 15px 30px;
    }
    .header_wrapper {
      @include media_query(XS) {
        padding: 0 15px 25px 15px;
      }
      @include media_query(M) {
        padding: 0 10px 35px 0;
      }
      .heading {
        font-family: 'SourceSansPro';
        font-size: 24px;
        font-weight: 700;
        color: #2c2c2c;
      }
    }
    .close_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      // top: 0;
      right: 0;
      width: 32px;
      height: 32px;
      background-color: #2c2c2c;
      cursor: pointer;
      @include media_query(XS) {
        top: 10px;
      }
      @include media_query(M) {
        top: 0;
      }
      .close_icon {
        width: 10px;
        height: 10px;
        background-image: url(../../../styles/images/close-white.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .top_area_wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      @include media_query(XS) {
        padding: 0 15px;
      }
      @include media_query(M) {
        padding: 0 0;
      }
      .search_main_wrapper {
        position: relative;
        /* width */
        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #e4e4e4;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #707070;
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }
        &.active {
          .dropdown_container,
          .search_wrapper {
            border: 2px solid #38539a;
          }
        }
        .dropdown_container {
          position: absolute;
          width: 100%;
          top: 45px;
          background-color: #fff;
          border-radius: 10px;
          z-index: 1;
          border: 2px solid #707070;
          padding: 10px 10px 10px 0;
          .list_wrapper {
            height: 185px;
            overflow: auto;
            .list {
              display: flex;
              align-items: center;
              font-family: 'SourceSansPro';
              font-size: 14px;
              font-weight: 500;
              color: #2c2c2c;
              padding: 0 16px;
              // min-height: 35px;
              cursor: pointer;
              @include media_query(XS) {
                height: auto;
                margin-bottom: 20px;
              }
              @include media_query(M) {
                min-height: 35px;
                margin-bottom: 0;
              }
              &:hover {
                font-weight: 700;
              }
            }
          }
        }
      }
      .search_wrapper {
        display: flex;
        align-items: center;
        border: 2px solid #2c2c2c;
        border-radius: 10px;
        height: 34px;
        padding: 0 15px;
        @include media_query(XS) {
          width: 14rem;
        }
        @include media_query(M) {
          width: 22rem;
        }
        .icon_wrapper {
          margin-left: auto;
          .search {
            width: 18px;
            height: 18px;
            background-image: url(../../../styles/images/search-black.svg);
            background-repeat: no-repeat;
            cursor: pointer;
          }
        }
        .search_input {
          background-color: transparent;
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 500;
          color: #2c2c2c;
          width: 100%;
          border: none;
          outline: none;
          padding-right: 15px;
          &::placeholder {
            font-weight: 400;
            color: #707070;
          }
        }
      }
      .sort_by_wrapper {
        @include media_query(XS) {
          display: flex;
          align-items: center;
          margin-left: auto;
        }
        @include media_query(M) {
          display: none;
        }
        .txt {
          @include media_query(XS) {
            font-family: 'SourceSansPro';
            font-size: 14px;
            font-weight: 700;
            color: #2c2c2c;
          }
        }
        .icon_wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          .filter {
            position: absolute;
            top: 0;
            left: auto;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 15px;
            height: 15px;
            background-image: url(../../../styles/images/filter-black.svg);
            background-repeat: no-repeat;
            cursor: pointer;
          }
          &.selected {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            background-color: #fafafa;
            border-radius: 50%;
            .filter {
              background-image: url(../../../styles/images/filter-black.svg);
            }
          }
        }
      }
      .action_wrapper {
        display: flex;
        align-items: center;
        margin-left: auto;
        @include media_query(XS) {
          display: flex;
          justify-content: center;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          margin: 0 16px;
          height: 95px;
          background-color: #ffffff;
          z-index: 1;
        }
        @include media_query(M) {
          display: flex;
          position: relative;
          height: auto;
          margin: initial;
          margin-left: auto;
        }
        .audience_grp {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 205px;
          height: 35px;
          border: 2px solid #2c2c2c;
          border-radius: 24px;
          padding: 0 30px;
          cursor: pointer;
          &:last-child {
            @include media_query(XS) {
              z-index: initial;
              border-radius: 32px;
              height: 48px;
              min-width: 100%;
              margin: 0 15px;
            }
            @include media_query(M) {
              position: relative;
              height: 35px;
              margin: 0 0;
              border-radius: 24px;
              z-index: 1;
              min-width: 205px;
            }
          }
          .txt {
            font-family: 'SourceSansPro';
            font-size: 16px;
            font-weight: 700;
            color: #2c2c2c;
            padding-left: 10px;
          }
          .add {
            width: 14px;
            height: 14px;
            background-image: url(../../../styles/images/add-white.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
          &.active {
            border: 2px solid transparent;
            background: #38539a;
            .txt {
              color: #fff;
            }
          }
          .remove {
            width: 16px;
            height: 16px;
            background-image: url(../../../styles/images/rounded-delete.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
          &.disabled {
            pointer-events: none;
            background-color: #d9d9d9;
            color: #fff;
            border: 2px solid transparent;
            .txt {
              color: #5e5e5e;
            }
            .add {
              background-image: url(../../../styles/images/add-gray.svg);
            }
          }
        }
      }
    }
    .tbl_wrapper {
      margin-bottom: 30px;
      /* width */
      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #e4e4e4;
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #707070;
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #707070;
      }
      .tbl_header {
        display: flex;
        align-items: center;
        height: 55px;
        @include media_query(XS) {
          background-color: #2c2c2c;
          border-radius: 0px 0px 0px 0px;
          padding: 0 16px 0 16px;
        }
        @include media_query(M) {
          background-color: #2c2c2c;
          border-radius: 12px 12px 0px 0px;
          padding: 0 40px 0 25px;
        }
        .question_icon {
          @include media_query(XS) {
            display: flex;
            width: 16px;
            height: 16px;
            background-image: url(../../../styles/images/question.svg);
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 10px;
          }
          @include media_query(M) {
            display: none;
          }
        }
        .txt {
          color: #fff;
          @include media_query(XS) {
            font-family: 'SourceSansPro';
            font-size: 14px;
            font-weight: 400;
          }
          @include media_query(M) {
            font-family: 'SourceSansPro';
            font-size: 16px;
            font-weight: 400;
          }
        }
        .filter_main_wrapper {
          position: relative;
          margin-right: 15px;
          @include media_query(XS) {
            display: none;
          }
          @include media_query(M) {
            position: relative;
            display: block;
          }
          .icon_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            .filter {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              width: 15px;
              height: 15px;
              background-image: url(../../../styles/images/filter-white.svg);
              background-repeat: no-repeat;
              cursor: pointer;
            }
            &.selected {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              background-color: #fafafa;
              border-radius: 50%;
              .filter {
                background-image: url(../../../styles/images/filter-black.svg);
              }
            }
          }
        }
        .tbl_head {
          display: flex;
          align-items: center;
          &:nth-child(1) {
            @include media_query(XS) {
              width: 70%;
            }
            @include media_query(M) {
              width: 65%;
            }
            .txt {
              @include media_query(XS) {
                padding-left: 0;
              }
              @include media_query(M) {
                padding-left: 50px;
              }
            }
          }
          &:nth-child(2) {
            @include media_query(XS) {
              width: 20%;
              justify-content: center;
            }
            @include media_query(M) {
              width: 25%;
              justify-content: flex-start;
            }
          }
          &:nth-child(3) {
            width: 10%;
            @include media_query(XS) {
              display: none;
            }
            @include media_query(M) {
              display: flex;
            }
          }
        }
      }
      .tbl_body {
        overflow: auto;
        @include media_query(XS) {
          max-height: calc(100vh - 275px);
        }
        @include media_query(M) {
          max-height: 250px;
        }
        .tbl_row {
          display: flex;
          align-items: center;
          border: 1px solid #e4e4e4;
          border-top: 0;
          @include media_query(XS) {
            min-height: 72px;
            padding: 0 16px 0 16px;
          }
          @include media_query(M) {
            min-height: 72px;
            padding: 0 40px 0 25px;
          }
          &.selected {
            background-color: #f4f4f4;
          }
          .tbl_data {
            display: flex;
            align-items: center;
            &.justify_center {
              justify-content: center;
            }
            .status {
              display: flex;
              align-items: center;
              justify-content: center;
              @include media_query(XS) {
                width: 15px;
                height: 15px;
                border-radius: 50%;
              }
              @include media_query(M) {
                min-width: 95px;
                height: 25px;
                padding: 0 10px;
                border-radius: 5px;
              }
              &.live {
                @include media_query(XS) {
                  background-color: #107f47;
                }
                @include media_query(M) {
                  background-color: #f0fae5;
                  color: #107f47;
                }
              }

              &.draft {
                @include media_query(XS) {
                  background-color: #a65523;
                }
                @include media_query(M) {
                  background-color: #fff1e8;
                  color: #a65523;
                }
              }

              &.go_offline {
                @include media_query(XS) {
                  background-color: #7f3e3e;
                }
                @include media_query(M) {
                  background-color: #ffe2e2;
                  color: #7f3e3e;
                }
              }

              &.go_live {
                @include media_query(XS) {
                  background-color: #38539a;
                }
                @include media_query(M) {
                  background-color: #f1f2ff;
                  color: #38539a;
                }
              }
            }
            .status_txt {
              @include media_query(XS) {
                display: none;
              }
              @include media_query(M) {
                display: block;
                font-family: 'SourceSansPro';
                font-size: 14px;
                font-weight: 500;
                color: #2c2c2c;
              }
            }
            .txt {
              font-family: 'SourceSansPro';
              font-size: 14px;
              font-weight: 500;
              color: #2c2c2c;
            }
            .remove {
              width: 16px;
              height: 16px;
              background-image: url(../../../styles/images/rounded-delete.svg);
              background-repeat: no-repeat;
              background-size: contain;
              cursor: pointer;
              &:hover {
                background-image: url(../../../styles/images/rounded-delete-red.svg);
              }
            }
            &:nth-child(1) {
              @include media_query(XS) {
                width: 70%;
                padding-left: 0;
              }
              @include media_query(M) {
                width: 65%;
                padding-left: 47px;
              }
              .txt {
                @include media_query(XS) {
                  padding-left: 0;
                  padding-right: 16px;
                }
                @include media_query(M) {
                  padding-left: 50px;
                  padding-right: 64px;
                }
              }
            }
            &:nth-child(2) {
              @include media_query(XS) {
                width: 20%;
                justify-content: center;
              }
              @include media_query(M) {
                width: 25%;
                justify-content: flex-start;
              }
            }
            &:nth-child(3) {
              width: 10%;
              @include media_query(XS) {
                justify-content: flex-end;
              }
              @include media_query(M) {
                justify-content: center;
              }
            }
          }
        }
      }
    }
    .btn_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      .btn {
        min-width: 120px;
        height: 35px;
        font-family: 'SourceSansPro';
        font-size: 16px;
        font-weight: 700;
        border: 2px solid #2c2c2c;
        background-color: #fff;
        border-radius: 24px;
        margin: 0 6px;
        padding: 0 30px;
        cursor: pointer;
        &.active {
          background-color: #2c2c2c;
          border: 2px solid transparent;
          color: #fff;
        }
        &.disabled {
          pointer-events: none;
          background-color: #d9d9d9;
          color: #5e5e5e;
          border: 2px solid transparent;
        }
      }
    }
  }
}
