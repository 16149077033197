@import '../../styles/common/media_queries';

.portal_main_wrapper {
  width: 100%;
  // padding-left: 65px;
  @include media_query(M) {
    padding-left: 65px;
  }
  @include media_query(L) {
    // padding-left: 0;
  }
  .portal_wrapper {
    padding: 25px 25px 15px 25px;
    @include media_query(S) {
      padding: 0;
    }
    .p_lr_12 {
      padding-left: 12px;
      padding-right: 12px;
    }
    // common class for error screen
    .empty_screen_path_detail {
      text-align: center;
      color: #2c2c2c;
      .error_label {
        font-family: 'SourceSansPro';
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 4px;
      }
      .error_content {
        font-family: 'SourceSansPro';
        font-size: 14px;
        line-height: 18px;
      }
    }
    // Notification drawer
    .notification_wrapper {
      position: fixed;
      top: 70px;
      bottom: 15px;
      left: 0px;
      max-height: 992px;
      width: 390px;
      background-color: #ffffff;
      border-radius: 12px;
      z-index: 5;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      @include media_query(M) {
        top: 15px;
        bottom: 15px;
        left: 80px;
        border-radius: 12px;
      }

      .header {
        width: 100%;
        height: 65px;
        background-color: #fafafa;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #2c2c2c;
        font-family: 'SourceSansPro';
        font-weight: 700;
        @include media_query(M) {
          border-radius: 12px 12px 0px 0px;
        }
        .title {
          font-family: 'SourceSansPro';
          font-size: 21px;
          line-height: 24px;
        }
        .btn_wrapper {
          font-family: 'SourceSansPro';
          font-size: 14px;
          line-height: 24px;
          font-weight: 700;
          @include media_query(M) {
            font-size: 16px;
          }
          .btn_txt {
            color: #2c2c2c;
            border-bottom: 2px solid #2c2c2c;
          }
          .confirmation_btn {
            color: #ffffff;
            background-color: #2c2c2c;
            height: 36px;
            padding: 0 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 24px;
          }
        }
      }
      .tab_menu_bar {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        overflow-x: auto;
        padding: 0 12px;
        .nav_item {
          width: auto;
          height: 24px;
          white-space: nowrap;
          background: #ffffff;
          border: 1px solid #38539a;
          box-sizing: border-box;
          border-radius: 24px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 500;
          color: #38539a;
          margin-right: 10px;

          &:first-child {
            min-width: 85px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &.selected {
            color: #f1f2ff;
            background: #38539a;
          }
          .icon {
            height: 14px;
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 5px;
            &.course_icon {
              width: 12px;
              background-image: url(../../styles/images/course_icon.svg);
            }
            &.learn_path_icon {
              width: 16px;
              background-image: url(../../styles/images/learning_icon.svg);
            }
            &.img_library_icon {
              width: 18px;
              background-image: url(../../styles/images/img_library.svg);
            }
            &.aud_mgt_iocn {
              width: 20px;
              background-image: url(../../styles/images/user_icon.svg);
            }
          }
        }
      }
      ::-webkit-scrollbar {
        height: 0px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #e4e4e4;
        border-radius: 5px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #707070;
        border-radius: 5px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #707070;
      }
      .middle_body_wrapper {
        height: calc(100% - 65px);

        .inner_wrapper {
          padding: 15px;
          height: calc(100% - 60px);
          overflow-y: auto;
          .section_wrapper {
            padding: 15px;
            margin: 0 - 15px;
            margin-bottom: 15px;
            &.unread_notification {
              background-color: #f1f2ff;
              color: #38539a;
              .title_wrapper {
                .title {
                  color: #38539a;
                  font-family: 'SourceSansPro';
                  font-style: italic;
                }
                .calender_icon {
                  background-image: url(../../styles/images/calendar_icon_purple.svg);
                }
              }
              .info_wrapper {
                .title_wrapper {
                  .description_txt {
                    color: #38539a;
                    font-family: 'SourceSansPro';
                    font-style: italic;
                  }
                }
                .icon {
                  &.close_icon {
                    background-image: url(../../styles/images/close_icon_purple.svg);
                  }
                  &.edit_icon {
                    width: 16px;
                    height: 19px;
                    background-image: url(../../styles/images/edit_icon_purple.svg);
                  }
                  &.online_icon {
                    width: 16px;
                    height: 19px;
                    background-image: url(../../styles/images/bulb-on-purple.svg);
                  }
                  &.offline_icon {
                    width: 16px;
                    height: 19px;
                    background-image: url(../../styles/images/bulb-off-purple.svg);
                  }
                  &.archive_icon {
                    width: 16px;
                    height: 19px;
                    background-image: url(../../styles/images/box-purple.svg);
                  }
                  &.create_icon {
                    width: 16px;
                    height: 19px;
                    background-image: url(../../styles/images/book-purple.svg);
                  }
                }
              }
            }
            .title_wrapper {
              display: flex;
              align-items: center;
              margin-bottom: 15px;
              .calender_icon {
                width: 14px;
                height: 16px;
                background-image: url(../../styles/images/calendar_icon.svg);
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 10px;
              }
              .archive_cal_icon {
                width: 18px;
                height: 15px;
                background-image: url(../../styles/images/archive_box_icon.svg);
                background-repeat: no-repeat;
                cursor: pointer;
                margin-right: 8px;
              }
              .offline_cal_icon {
                width: 16px;
                height: 16px;
                background-image: url(../../styles/images/offline_cal_icon.svg);
                background-repeat: no-repeat;
                cursor: pointer;
                margin-right: 8px;
              }
              .title {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 700;
                color: #2c2c2c;
                line-height: 18px;
              }
            }
            .info_wrapper {
              height: 85px;
              padding: 0 35px;
              box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
              border-radius: 12px;
              position: relative;
              margin-bottom: 15px;
              .title_wrapper {
                display: block;
                line-height: 24px;
                padding: 10px 0;
                .description_txt {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  font-weight: 400;
                  color: #2c2c2c;
                  font-style: italic;
                  @include media_query(M) {
                    font-size: 16px;
                  }
                }
              }
              .icon {
                background-repeat: no-repeat;
                background-size: cover;
                &.close_icon {
                  position: absolute;
                  top: 10px;
                  right: 10px;
                  width: 12px;
                  height: 12px;
                  background-image: url(../../styles/images/close_icon_black.svg);
                }
                &.left_icons {
                  position: absolute;
                  top: 10px;
                  left: 10px;
                }
                &.edit_icon {
                  width: 16px;
                  height: 19px;
                  background-image: url(../../styles/images/edit_icon_black.svg);
                }
                &.online_icon {
                  width: 16px;
                  height: 19px;
                  background-image: url(../../styles/images/bulb-on-purple.svg);
                }
                &.offline_icon {
                  width: 16px;
                  height: 19px;
                  background-image: url(../../styles/images/bulb-off-purple.svg);
                }
                &.archive_icon {
                  width: 16px;
                  height: 19px;
                  background-image: url(../../styles/images/box-black.svg);
                }
                &.create_icon {
                  width: 16px;
                  height: 19px;
                  background-image: url(../../styles/images/course_black_icon.svg);
                }

                &.course_black_icon {
                  width: 16px;
                  height: 18px;
                  background-image: url(../../styles/images/course_black_icon.svg);
                }
                &.gift_black_icon {
                  width: 16px;
                  height: 14px;
                  background-image: url(../../styles/images/gift_black_icon.svg);
                }
              }
            }
          }
        }
        // Scrollbar code
        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #e4e4e4;
          mix-blend-mode: normal;
          opacity: 0.5;
          border-radius: 100px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #707070;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }
      }
    }

    .row {
      margin-right: -12px;
      margin-left: -12px;
    }
    .card_wrapper {
      border-radius: 10px;
      border: 1px solid #e4e4e4;
      margin-bottom: 25px;
      @include media_query(S) {
        border: none;
        margin-bottom: 0;
      }
      .header_wrapper {
        display: flex;
        align-items: center;
        min-height: 50px;
        background-color: #2c2c2c;
        border-radius: 10px 10px 0px 0px;
        padding: 0 24px;
        @include media_query(S) {
          flex-direction: column;
          background-color: #fff;
          align-items: initial;
          padding: 0;
          padding-bottom: 25px;
        }
        .header_list {
          @include media_query(S) {
            // display: flex;
            // align-items: center;
            white-space: nowrap;
            padding: 0 15px;
            height: 28px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            overflow: auto;
            margin-bottom: 25px;
          }
        }
        .txt {
          font-family: 'SourceSansPro';
          font-size: 14px;
          color: #fff;

          @include media_query(S) {
            display: inline-block;
            vertical-align: middle;
            font-weight: 500;
            color: #2c2c2c;
            text-align: left;
            height: 100%;
            margin: 0 20px;
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
          }
          @include media_query(M) {
            font-family: 'SourceSansPro';
            font-size: 21px;
          }
          &.selected {
            position: relative;
            @include media_query(S) {
              font-family: 'SourceSansPro';
              font-weight: 700;
              color: #38539a;
              // border-bottom: 2px solid #38539a;
            }
            &:after {
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              border-radius: 10px;
              background-color: #38539a;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
        .search_wrapper {
          display: flex;
          align-items: center;
          border: 2px solid #fafafa;
          border-radius: 10px;
          height: 34px;
          width: 20rem;
          padding-left: 10px;
          // margin-right: 15px;
          background-color: #ffffff;
          &.outline {
            border: 2px solid #38539a;
          }
          @include media_query(S) {
            width: 100%;
            padding: 0 10px;
            border: 2px solid #2c2c2c;
            margin-right: initial;
          }
          .icon_wrapper {
            margin-left: auto;
          }
          .search_input {
            background-color: transparent;
            font-family: 'SourceSansPro';
            font-size: 16px;
            color: #2c2c2c;
            font-weight: 500;
            width: 100%;
            border: none;
            outline: none;
            @include media_query(S) {
              color: #2c2c2c;
              padding-right: 15px;
            }
            &::placeholder {
              color: #707070;
              font-family: 'SourceSansPro';
              font-weight: 400;
              @include media_query(S) {
                font-family: 'SourceSansPro';
                font-size: 14px;
                color: #707070;
              }
            }
          }
        }
        .action_wrapper {
          display: flex;
          align-items: center;
          margin-left: auto;
          @include media_query(S) {
            margin: initial;
            padding: 0 16px;
          }
          .icon_wrapper {
            margin: 0 15px;
            @include media_query(S) {
              margin: 0;
            }
            &.selected {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              background-color: #fafafa;
              border-radius: 50%;
              .filter {
                background-image: url(../../styles/images/filter-black.svg);
              }
            }
            .search {
              width: 18px;
              height: 18px;
              background-image: url(../../styles/images/search-black.svg);
              background-repeat: no-repeat;
              background-size: contain;
              cursor: pointer;
              @include media_query(S) {
                background-image: url(../../styles/images/search-black.svg);
              }
              &.cross_icon {
                width: 15px;
                height: 15px;
                background-image: url(../../styles/images/cross_icon_slim.svg);
              }
            }
            .search_white {
              width: 18px;
              height: 18px;
              background-image: url(../../styles/images/search.svg);
              background-repeat: no-repeat;
              cursor: pointer;
            }
            .filter {
              width: 15px;
              height: 15px;
              background-image: url(../../styles/images/filter-white.svg);
              background-repeat: no-repeat;
              background-size: contain;
              cursor: pointer;
              @include media_query(S) {
                background-image: url(../../styles/images/filter-black.svg);
              }
            }
          }
          .filter_main_wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 35px;
            height: 35px;
            flex-shrink: 0;
            @include media_query(S) {
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #f4f4f4;
              border-radius: 50%;
              margin-left: 15px;
            }
            @include media_query(L) {
              margin-left: 15px;
            }
          }
          .toggle_wrapper {
            display: flex;
            width: 105px;
            height: 32px;
            background-color: #fff;
            border-radius: 24px;
            padding: 1px;
            margin-left: 24px;
            .toggle_section {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50%;
              height: 100%;
              cursor: pointer;
              &.selected {
                background-color: #2c2c2c;
                border-radius: 24px;
                height: 100%;
                .card_view {
                  background-size: contain;
                  background-image: url(../../styles/images/card-view-selected.svg);
                }
                .list_view {
                  background-size: contain;
                  background-image: url(../../styles/images/list-view-selected.svg);
                }
              }
              .card_view {
                width: 18px;
                height: 18px;
                background-image: url(../../styles/images/card-view.svg);
                background-repeat: no-repeat;
              }
              .list_view {
                width: 20px;
                height: 18px;
                background-image: url(../../styles/images/list-view.svg);
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }
        }
      }
      .p_lr_10 {
        padding: 0 10px;
      }
      .card_view_wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 10px 0 10px;
        overflow: auto;
        @include media_query(S) {
          height: calc(100vh - 190px);
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      .card {
        // border: 1px solid #e4e4e4;
        min-height: 215px;
        margin-bottom: 20px;
        box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
        cursor: pointer;
        @include media_query(S) {
          display: flex;
          // align-items: center;
          min-height: auto;
          box-shadow: none;
          border: none;
          padding-bottom: 16px;
          border-bottom: 1px solid #e4e4e4;
        }
        @include media_query(M) {
          border-radius: 10px;
        }
        .image_wrapper {
          height: 83px;
          margin-bottom: 10px;
          @include media_query(M) {
            height: 130px;
          }
          @include media_query(S) {
            width: 92px;
            height: 92px;
            margin-bottom: 0;
          }
          img {
            border-radius: 10px 10px 0px 0px;
            height: 100%;
            width: 100%;
            @include media_query(S) {
              border-radius: 8px;
            }
          }
          &.empty_state {
            .empty_state_img {
              height: 100%;
              width: 100%;
              background-image: url(../../styles/images/empty_img_mob.svg);
              background-repeat: no-repeat;
              background-size: cover;
              // background-position: center center;
              @include media_query(M) {
                background-image: url(../../styles/images/Empty_State_img.svg);
              }
            }
          }
        }
        .details_wrapper {
          display: flex;
          flex-direction: column;
          padding-bottom: 10px;
          @include media_query(S) {
            width: calc(100% - 92px);
            padding-bottom: 0;
          }
        }
        .description {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          color: #2c2c2c;
          padding: 0 8px;
          margin-bottom: 0px;
          @include media_query(S) {
            padding: 0 12px;
          }
        }
        .bottom_wrapper {
          display: flex;
          align-items: center;
          padding: 0 8px 8px 8px;
          height: 32px;
          @include media_query(S) {
            padding: 0 12px 12px;
          }
          .archive_cal_icon {
            width: 18px;
            height: 15px;
            background-image: url(../../styles/images/archive_box_icon.svg);
            background-repeat: no-repeat;
            cursor: pointer;
            background-size: contain;
            margin-right: 4px;
          }
          .offline_cal_icon {
            width: 16px;
            height: 16px;
            background-image: url(../../styles/images/offline_cal_icon.svg);
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
            margin-right: 4px;
          }
          .calendar {
            width: 14px;
            height: 16px;
            background-image: url(../../styles/images/calender_icon.svg);
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 4px;
          }
          .date {
            font-family: 'SourceSansPro';
            font-weight: 400;
            font-size: 14px;
            color: #707070;
          }
          .block_wrapper {
            display: flex;
            align-items: center;
          }
        }
        .image_wrapper {
          position: relative;
        }
        .draft_icon {
          position: absolute;
          top: 10px;
          left: 10px;
          width: 24px;
          height: 24px;
          background-image: url(../../styles/images/draft.svg);
          background-repeat: no-repeat;
          @include media_query(S) {
            width: 20px;
            height: 20px;
            background-size: contain;
          }
        }
      }

      .highlight {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 47px;
        height: 24px;
        border-radius: 4px;
        font-family: 'SourceSansPro';
        font-size: 15px;
        font-weight: 500;
        margin-right: 12px;
        padding: 0 2px;
      }
      .live_card {
        .highlight {
          background: #f0fae5;
          color: #107f47 !important;
        }
      }
      .offline_card {
        .highlight {
          background: #f0f0f0;
          color: #707070 !important;
        }
      }
      .archive {
        .highlight {
          background: #fdf4f4;
          color: #d91734 !important;
        }
      }
      .draft_card {
        .highlight {
          background: #fff9f0;
          color: #a36a00 !important;
        }
      }

      // List View Wrapper Starts
      .list_view_wrapper {
        display: flex;
        flex-wrap: wrap;

        overflow: auto;
        .tbl_header {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 25px 20px 12px 20px;
          @include media_query(M) {
            position: sticky;
            top: 0;
            background: white;
          }

          .tbl_head {
            font-family: 'SourceSansPro';
            font-size: 16px;
            font-weight: 700;
            color: #2c2c2c;
            line-height: 24px;
            &:nth-child(1) {
              width: 20%;
            }
            &:nth-child(2) {
              width: 30%;
            }
            &:nth-child(3) {
              width: 35%;
            }
            &:nth-child(4) {
              width: 15%;
            }
          }
        }
        .user_icon {
          width: 18px;
          height: 14px;
          background-image: url(../../styles/images/people.svg);
          background-repeat: no-repeat;
          background-size: contain;
        }
        .issue_wapper {
          display: flex;
          align-items: center;
          margin-top: 5px;
          &:first-child {
            margin-top: 0;
          }
          .issue {
            width: 15px;
            height: 15px;
            background-image: url(../../styles/images/issue.svg);
            background-repeat: no-repeat;
          }
        }
        .edit {
          width: 17px;
          height: 16px;
          background-image: url(../../styles/images/edit.svg);
          background-repeat: no-repeat;
        }
        .active {
          width: 16px;
          height: 16px;
          background-image: url(../../styles/images/active.svg);
          background-repeat: no-repeat;
        }
        .warning {
          width: 16px;
          height: 14px;
          background-image: url(../../styles/images/warning.svg);
          background-repeat: no-repeat;
        }
        .books_gray {
          width: 14px;
          height: 16px;
          background-image: url(../../styles/images/books-gray.svg);
          background-repeat: no-repeat;
        }
        .files {
          width: 16px;
          height: 14px;
          background-image: url(../../styles/images/folder.svg);
          background-repeat: no-repeat;
          background-size: contain;
        }

        .tbl_body {
          padding: 0px 20px 15px 20px;
          // height: calc(100% - 35px);
          width: 100%;
          // overflow: auto;
          .tbl_row {
            display: flex;
            align-items: center;
            padding: 12px 0;
            border-bottom: 1px solid #e4e4e4;
            cursor: pointer;
            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }
            .tbl_data {
              display: flex;
              align-items: center;
              .txt {
                font-family: 'SourceSansPro';
                font-size: 14px;
                color: #2c2c2c;
                font-weight: 400;
                line-height: 18px;
              }
              .pr_5 {
                padding-right: 5px;
              }
              .pl_5 {
                padding-left: 5px;
              }
              .image_wrapper {
                width: 115px;
                height: 65px;
                img {
                  border-radius: 5px;
                  width: 115px;
                  height: 65px;
                }
                &.empty_state {
                  .empty_state_img {
                    height: 100%;
                    width: 100%;
                    background-image: url(../../styles/images/Empty_State_img.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center center;
                  }
                }
              }

              &:nth-child(1) {
                width: 20%;
              }
              &:nth-child(2) {
                width: 30%;

                .txt {
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  padding-right: 50px;
                  line-break: anywhere;
                }
              }
              &:nth-child(3) {
                width: 35%;
                .txt {
                  color: #949494;
                }
              }

              &:nth-child(4) {
                width: 15%;
                .txt {
                  font-family: 'SourceSansPro';
                  font-size: 16px;
                  min-width: 60px;
                  line-height: 24px;
                  padding: 0 15px;
                  max-width: 75px;
                }
              }
            }
          }
        }
      }
      // List View Wrapper Ends

      &.learning_section {
        .action_wrapper {
          @include media_query(XS) {
            padding: 0 0 !important;
          }
          @include media_query(M) {
            padding: 0 16px;
          }
        }
        .search_wrapper {
          width: 12rem;
          padding-right: 10px;
          @include media_query(XS) {
            width: calc(100% - 50px);
          }
          @include media_query(M) {
            width: 15rem;
          }
        }
        .learning_card_wrapper {
          // display: flex;
          flex-wrap: wrap;
          padding: 20px 6px 0 6px;
          overflow: auto;
          @include media_query(XS) {
            margin-top: -25px;
          }
          @include media_query(M) {
            margin-top: initial;
          }
          @include media_query(S) {
            flex-wrap: initial;
            flex-direction: column;
            height: calc(100vh - 190px);
            padding-top: 0;
            padding-bottom: 0;
          }
          .learning_card {
            @include media_query(M) {
              width: 100%;
              display: contents;
              align-items: center;
              // max-width: 70%;
            }
          }
          .card {
            display: flex;
            align-items: center;
            min-height: 95px;
            @include media_query(XS) {
              margin-bottom: 16px;
            }
            @include media_query(S) {
              min-height: auto;
            }
            @include media_query(M) {
              margin-bottom: 20px;
            }
            .image_wrapper {
              width: 170px;
              height: 120px;
              margin-bottom: 0;
              @include media_query(XS) {
                width: 96px;
                height: 106px;
              }
              @include media_query(M) {
                width: 120px;
                height: 115px;
              }
              img {
                border-radius: 10px 0px 0px 10px;
                @include media_query(S) {
                  border-radius: 10px;
                }
              }
              &.empty_state {
                .empty_state_img {
                  height: 100%;
                  width: 100%;
                  background-image: url(../../styles/images/Empty_LP_Image.svg);
                  background-repeat: no-repeat;
                  // background-size: contain;
                  // background-position: center center;
                }
              }
            }

            .details_wrapper {
              width: calc(100% - 170px);
              @include media_query(XS) {
                padding: 0 4px;
                width: calc(100% - 96px);
              }
              @include media_query(M) {
                padding: 0 8px;
                width: calc(100% - 120px);
              }
              .top_wrapper {
                display: flex;
                align-items: center;
                padding: 0 8px 8px 8px;
                @include media_query(XS) {
                  padding: 0 4px 8px 4px;
                }
                @include media_query(M) {
                  padding: 0 0 8px 0;
                }
              }
              .bottom_wrapper {
                height: auto;
                @include media_query(XS) {
                  padding: 0 4px 0 4px;
                }
                @include media_query(M) {
                  padding: 0 0 0 0;
                }
              }
              .description {
                max-height: 34px;
                margin-bottom: 12px;
                @include media_query(XS) {
                  padding: 0 4px 0px 4px;
                }
                @include media_query(M) {
                  padding: 0;
                }
              }
              .block_wrapper {
                display: flex;
                align-items: center;
                margin-left: 12px;
                &:first-child {
                  padding-right: 25px;
                }
                .calendar {
                  width: 14px;
                  height: 16px;
                  background-image: url(../../styles/images/calender_icon.svg);
                  background-repeat: no-repeat;
                }
                .archive_cal_icon {
                  width: 18px;
                  height: 15px;
                  background-image: url(../../styles/images/archive_box_icon.svg);
                  background-repeat: no-repeat;
                  cursor: pointer;
                  background-size: contain;
                }
                .offline_cal_icon {
                  width: 16px;
                  height: 16px;
                  background-image: url(../../styles/images/offline_cal_icon.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                  cursor: pointer;
                }
                .book {
                  width: 14px;
                  height: 16px;
                  background-repeat: no-repeat;
                  @include media_query(XS) {
                    background-image: url(../../styles/images/files.svg);
                    background-size: contain;
                    opacity: 0.6;
                  }
                  @include media_query(M) {
                    background-image: url(../../styles/images/book.svg);
                    opacity: 1;
                  }
                }
                .course_bold {
                  width: 14px;
                  height: 16px;
                  background-image: url(../../styles/images/files_gray_icon.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                }
                .date {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  color: #949494;
                  padding-left: 8px;
                  line-height: initial;
                }
              }
            }
          }
        }
        .learning_image_container {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          @include media_query(S) {
            height: calc(100vh - 239px);
            width: 100%;
          }
          .learning_middle_section {
            width: 243px;
            .learning_image_area {
              .empty_screen_icon {
                background-size: cover;
                background-repeat: no-repeat;
                width: 100px;
                height: 168px;

                margin: 0 auto;
                margin-bottom: 19px;

                &.learning_path_icon {
                  background-image: url(../../styles/images/learningpaths_Illustration.png);
                  @include media_query(M) {
                    background-image: url(../../styles/images/learning_path_img_md.png);
                  }
                }
                @include media_query(M) {
                  width: 160px;
                  height: 270px;
                }
              }
            }
          }
        }
      }
      &.audience_section {
        .search_wrapper {
          padding-right: 10px;
          width: 20rem;
        }
        .audience_details_wrapper {
          padding: 35px 20px 0 20px;
          overflow-y: scroll;
          height: calc(100% - 50px);
          z-index: 1;
          @include media_query(XS) {
            margin: 0 -16px;
            overflow: auto;
            overflow-x: hidden;
            padding-right: 0;
          }
          @include media_query(S) {
            flex-wrap: initial;
            flex-direction: column;
            height: calc(100vh - 190px);
            padding: 0 32px;
          }
          @include media_query(M) {
            margin: auto;
            padding: 0 16px;
          }
          &.user_grp_wrapper {
            position: relative;
            padding: 0 !important;
            overflow: auto !important;
            height: 100% !important;
            margin: initial;
            @include media_query(XS) {
              padding: 0 0px !important;
            }
            @include media_query(M) {
              padding: 0 0px !important;
            }
          }
          .tbl_header {
            display: flex;
            align-items: center;
            padding: 0 15px 15px 15px;
            height: 35px;
            @include media_query(S) {
              display: none;
            }
            .tbl_head {
              font-family: 'SourceSansPro';
              font-size: 14px;
              font-weight: 700;
              color: #2c2c2c;
              &:nth-child(1) {
                width: 45%;
              }
              &:nth-child(2) {
                width: 18.33%;
              }
              &:nth-child(3) {
                width: 18.33%;
              }
              &:nth-child(4) {
                width: 18.33%;
              }
            }
          }
          .tbl_body {
            height: calc(100% - 35px);
            overflow: auto;
            @include media_query(S) {
              height: 100% !important;
            }
            .tbl_row {
              display: flex;
              align-items: center;
              padding: 0 15px 15px 15px;
              margin-bottom: 15px;
              border-bottom: 1px solid #e4e4e4;
              @include media_query(S) {
                display: block;
                position: relative;
                padding-left: 0;
                padding-right: 0;
              }
              .right_arrow {
                @include media_query(S) {
                  display: block;
                  position: absolute;
                  top: -15px;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  margin: auto;
                  margin-right: 15px;
                  width: 10px;
                  height: 20px;
                  background-image: url(../../styles/images/chevron-left.svg);
                  background-repeat: no-repeat;
                  transform: rotate(180deg);
                  cursor: pointer;
                }
              }
              &:last-child {
                margin-bottom: 0;
                border-bottom: none;
              }
              .tbl_data {
                display: flex;
                align-items: center;
                .txt {
                  font-family: 'SourceSansPro';
                  font-size: 16px;
                  color: #2c2c2c;
                  @include media_query(S) {
                    font-weight: 700;
                  }
                }
                .pl_12 {
                  padding-left: 12px;
                }
                .user_icon {
                  width: 20px;
                  height: 16px;
                  background-image: url(../../styles/images/users.svg);
                  background-repeat: no-repeat;
                }
                .books_gray {
                  width: 14px;
                  height: 16px;
                  background-image: url(../../styles/images/books-gray.svg);
                  background-repeat: no-repeat;
                }
                .files_gray {
                  width: 18px;
                  height: 16px;
                  background-image: url(../../styles/images/files-gray.svg);
                  background-repeat: no-repeat;
                }
                &:nth-child(1) {
                  width: 45%;
                  @include media_query(S) {
                    width: 100%;
                    padding-bottom: 16px;
                  }
                }
                &:nth-child(2) {
                  width: 18.33%;
                  @include media_query(S) {
                    display: inline-flex;
                    width: auto;
                    margin-right: 20px;
                  }
                }
                &:nth-child(3) {
                  width: 18.33%;
                  @include media_query(S) {
                    display: inline-flex;
                    width: auto;
                    margin: 0 20px;
                  }
                }
                &:nth-child(4) {
                  width: 18.33%;
                  @include media_query(S) {
                    display: inline-flex;
                    width: auto;
                    margin: 0 20px;
                  }
                }
              }
            }
          }
        }
      }
      &.learning_section,
      &.audience_section {
        .filter_container {
          right: -15px;
        }
        .action_wrapper {
          .icon_wrapper {
            margin-right: 0;
            &.selected {
              margin-left: 15px;
            }
          }
        }
      }
      &.courses_section,
      &.learning_section {
        display: flex;
        flex-direction: column;
      }
      &.learning_section {
        display: block;
      }
      &.audience_section,
      &.analytics_section {
        display: flex;
        flex-direction: column;
        // height: 375px;
      }

      // Responsive
      &.courses_section {
        height: 630px;
        @include media_query(M) {
          height: 565px;
        }
        @include media_query(L) {
          height: 630px;
        }
        .card_view_wrapper {
          @include media_query(M) {
            height: 515px;
            // flex-direction: column;
            align-content: baseline;
            overflow-y: auto;
          }
          @include media_query(L) {
            height: 100%; //as empty screen and card wrapper not aligned center
            flex-direction: row;
          }
        }
        .course_bottom_link {
          text-align: center;
          font-family: 'SourceSansPro';
          font-size: 16px;
          font-weight: 700;
          text-decoration: underline;
          padding-bottom: 24px;
          margin-top: 10px;
          cursor: pointer;
        }
      }
      &.learning_section {
        height: 976px;
        @include media_query(M) {
          height: 520px;
        }
        @include media_query(L) {
          height: 976px;
        }
        @include media_query(S) {
          height: auto;
          flex-direction: row;
        }
        @include media_query(M) {
          .learning_card_wrapper {
            height: 460px;
            // flex-direction: column;
            // align-content: baseline;
            // overflow-x: scroll;
            // display: grid;
            // align-self: baseline;
            align-items: baseline;
            align-content: baseline;
            // grid-template-rows: auto auto auto;
            // grid-template-columns: repeat(auto-fit, minmax(33.3%, 1fr));
            // grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
            // grid-auto-rows: 150px;
            // grid-auto-flow: column;
            // grid-gap: 20px;
            padding: 20px 20px 0 20px;
            .learning_card {
              // width: 100%;
              //display: flex;
              // align-items: center;
              // max-width: 100%;
              // flex: auto;
              width: 25rem;
              max-width: 25rem;
              padding: 0;
            }
            .card_wrapper {
              display: grid;
              width: 100%;
            }
            .card {
              margin-bottom: 0;
              margin-bottom: 24px;
              .image_wrapper {
                img {
                  border-radius: 12px 0 0 12px;
                }
              }
            }
          }
        }
        @include media_query(L) {
          .learning_card_wrapper {
            // display: flex;
            // height: auto;
            height: 925px;
            flex-direction: row;
            grid-gap: initial;
            overflow-y: auto;
            .learning_card {
              // width: 100%;
              display: block;
              align-items: center;
              // max-width: 100%;
              flex: initial;
              height: 100%;
              width: 100%;
              max-width: 100%;
              padding: 0 0px;
            }
            .card {
              @include media_query(XS) {
                margin-bottom: 16px;
              }
              @include media_query(M) {
                margin-bottom: 24px;
              }
            }
          }
        }
      }
      &.audience_section {
        height: 325px;
        @include media_query(XS) {
          height: auto;
          overflow-x: hidden;
          overflow-y: auto;
          margin-top: -10px;
        }
        @include media_query(M) {
          height: 360px;
          overflow-x: hidden;
          overflow-y: auto;
          margin-top: initial;
        }
        @include media_query(L) {
          height: 325px;
        }
        .audience_image_container {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          @include media_query(S) {
            height: calc(100vh - 239px);
            width: 100%;
          }

          .audience_middle_section {
            width: 243px;
            .audience_image_area {
              .audience_empty_screen_icon {
                background-repeat: no-repeat;
                width: 130px;
                height: 140px;
                margin: 0 auto;
                margin-bottom: 19px;
                &.audience_path_icon {
                  background-image: url(../../styles/images/audience_mgt_illustrtion.png);
                }
              }
            }
            // .audience_path_detail {
            //   text-align: center;
            //   color: #2C2C2C;
            //   .audience_label {
            //     font-size: 16px;
            //     line-height: 24px;
            //     font-weight: 700;
            //     margin-bottom: 4px;
            //   }
            //   .audience_content {
            //     font-size: 14px;
            //     line-height: 18px;
            //   }
            // }
          }
        }
      }
      &.analytics_section {
        height: 325px;
        @include media_query(M) {
          height: 375px;
        }
        @include media_query(L) {
          height: 325px;
        }
      }
      // Ends Responsive
    }
  }
  // View all courses
  .course_bottom_link {
    text-align: center;
    font-family: 'SourceSansPro';
    font-weight: 700;
    text-decoration: underline;
    padding-bottom: 24px;
    cursor: pointer;
    @include media_query(XS) {
      font-size: 14px;
      margin-top: 24px;
    }
    @include media_query(M) {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}
.mobile_view_course_shimmer_card {
  height: 110px;
}
