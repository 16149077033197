@import '../../../styles/common/media_queries';

.round_toggle_wapper {
  display: flex;
  align-items: center;
  position: relative;
  @include media_query(M) {
    margin: 0 12px;
  }
  &.disabled {
    cursor: pointer;
    pointer-events: none;
    .slider {
      background-color: #707070;
      @include media_query(M) {
        background-color: #d9d9d9;
      }
    }
    .icon {
      &.black_course_icon {
        display: block;
        left: 7px;
        width: 13px;
        height: 12px;
        background-image: url(../../../styles/images/gray_toggle_course_icon.svg);
        @include media_query(M) {
          display: none;
        }
      }
      &.white_cross_icon {
        @include media_query(M) {
          display: none;
        }
      }
    }
  }
  &.active {
    input:checked + .slider {
      background-color: #38539a;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #38539a;
    }
    .whitecheck_icon {
      display: block;
      position: absolute;
      top: 7px;
      left: 7px;
      width: 13px;
      height: 12px;
      background-image: url(../../../styles/images/white_tick.svg);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
    }
    .icon {
      @include media_query(M) {
        display: none;
      }
      &.blue_course_icon {
        right: 7px;
        width: 9px;
        height: 12px;
        background-image: url(../../../styles/images/blue_toggle_icon.svg);
        //display: block;

        display: none;
      }
      &.white_cross_icon {
        display: none;
      }
      &.black_course_icon {
        display: none;
      }
    }
  }
  .whitecheck_icon {
    display: none;
  }
  .icon {
    position: absolute;
    top: 7px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    @include media_query(M) {
      display: none;
    }
    &.blue_course_icon {
      display: none;
    }

    &.white_cross_icon {
      display: block;
      right: 10px;
      width: 10px;
      height: 10px;
      background-image: url(../../../styles/images/white_cross_toggle.svg);
    }
    &.black_course_icon {
      display: block;
      left: 7px;
      width: 13px;
      height: 12px;
      background-image: url(../../../styles/images/black_toggle_icon.svg);
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
    margin: 0;
    cursor: pointer;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2c2c2c;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    cursor: pointer;
  }

  input:checked + .slider {
    background-color: #2c2c2c;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2c2c2c;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
