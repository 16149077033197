.modal_wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(112, 112, 112, 0.7);
    justify-content: center;
    z-index: 10;
    .custom_overlay {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      background-color: white;
      width: 530px;
      padding: 20px 16px 24px 16px;
      border-radius: 20px;
      background: var(--2-Pure-White, #fff);
      gap: 24px;

      .cross_icon {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 14px;
        height: 16px;
        background: url(../../../styles/images/cross_icon_slim.svg);
        background-repeat: no-repeat;
        cursor: pointer;
      }
      .header_wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        .headerTitle {
            color: var(--1-Amway-Black, #2c2c2c);
            font-feature-settings: 'liga' off, 'clig' off;
            font-size: 21px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px; /* 133.333% */
          }
          .sub_heading {
              color: #2C2C2C;
              font-family: "GT Walsheim Pro";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
          }
      }
  
      .form_main_wrapper {
        .error_wrapper {
          &.form_field {
            .input_wrapper {
              font-family: 'SourceSansPro';
              .input{
                border: 2px solid #d91734;
                padding-right: 35px;
              }
            }
          }
    
          .error_message {
            font-family: 'SourceSansPro';
            color: #d91734;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            padding: 4px 0;
          }
        }
      }
      .btn_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
          min-width: 120px;
          height: 48px;
          font-family: 'SourceSansPro';
          font-size: 16px;
          font-weight: 700;
          outline: none;
          border: 2px solid #2c2c2c;
          background-color: #fff;
          border-radius: 24px;
          margin: 0 6px;
          padding: 0 30px;
          cursor: pointer;
          &.active {
            background-color: #2c2c2c;
            border: 2px solid transparent;
            color: #fff;
          }
        &.secondary {
            background-color: #2C2C2C;
            color: white;
        }
        }
      }
    }
  }
  