.container {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    justify-content: space-between;
}

.tableContainer {
    // border: 1px solid red;
    padding: 12px 0;
}

.paginationContainer {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    margin: 12px 0px 20px;

    .paginatedRecordsTxt {
        margin: 0px;
        color: #707070;
    }

    .paginationNextBtn {
        // background: #38539a;
        cursor: pointer;
        // color: white;
        color: #2c2c2c;
        border: none;
        padding: 10px 20px;
        border-radius: 12px;
        background: transparent;
        padding-bottom: 20px;
    }

    .paginationPageNoContainer {
        display: flex;
        overflow: overlay; 
        max-width: 300px;
        padding-bottom: 4px;

        /* Thin the scrollbar */
        &::-webkit-scrollbar {
            width: 4px; /* Thinner vertical scrollbar */
            height: 4px; /* Thinner horizontal scrollbar */
        }
        
        /* Styling the scrollbar track (the background) */
        &::-webkit-scrollbar-track {
            background: #f1f1f1; /* Light grey track */
        }
        
        /* Styling the scrollbar thumb (the draggable part) */
        &::-webkit-scrollbar-thumb {
            background: #888; /* Darker color for the thumb */
            border-radius: 10px; /* Rounded corners */
        }
        
        /* On hover, make the thumb more visible */
        &::-webkit-scrollbar-thumb:hover {
            background: #555; /* Darker on hover */
        }
  
    }
}

// .table {
//     border-collapse: separate;
//     border-spacing: 0px 12px;
// }

.overflowClip {
    overflow-x: clip;
}


.checkboxColumnStyles {
    width: 20px;
    margin-right: 10px;
}

.paginationParentContainer {
    display: flex;
}

// .paginationContainer {
//     display: flex;
//     flex-direction: row;
//     flex: 1;
//     justify-content: end;
//     align-items: center;
//     column-gap: 20px;
// }

.pageNumbers {
    padding-top: 12px;
}

.headerCellContainer {
    display: flex;
    flex-direction: row;
    // justify-content: center;
}

.headerCellContainerSortable {
    display: flex;
    flex-direction: row;
    justify-content: start;
    cursor: pointer;
    align-items: center;
}

.headerRowContainer {
    display: flex;
    background-color: #2c2c2c;
    color: #ffffff;
    text-transform: capitalize;
    border-radius: 12px;
    padding: 14px 12px;
    padding-right: 5px;
    column-gap: 10px;
    align-items: center;
}

.tableBodyContainer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    height: calc(100vh - 460px);
    overflow-y: auto;
    padding-top: 12px;
}

.tableBodyRowContainer {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #E4E4E4;
    border-radius: 12px;
    background-color: #fff;
    padding: 14px 12px;
    padding-right: 5px;
    column-gap: 10px;

    &.selected{
        background-color: #F4F4F4;
    }
}

.sortIcon {
    padding: 0px 0px 2px 8px;
}

.rowsSelectionContainer {
    display: flex;
}

.rowSelectionDropdownContainer {
    margin-left: 8px;
}

.rowSelectionDropdown {
    font-size: 14px;
    caret-color: transparent;
    cursor: pointer;
    max-width: 73px;
    padding: 0;
}

.rowSelectionDropdownIcon {
    padding-left: 0;
}

.groupHeaderRow {
    border: none;
}

.groupHeaderContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 20px;
    background: #ffffff;
}

.flagCircle {
    display: flex;
    width: 15px;
    height: 15px;
    border-radius: 15px;
}

.green {
    background: #A9CE41;
}

.red {
    background: #C53A3A;
}

.yellow {
    background: #F9C947;
}

.dataNotFound {
    text-align: center;
}

.totalRecords {
    margin: 0 10px;
}
