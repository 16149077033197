.container {
  position: relative;
  min-height: 100vh;
  width: 100%;
}
.main_wrapper {
  position: relative;
  /* padding-left: 65px; */
  line-height: 1.2;
  width: 100%;
}

.main {
  display: flex;
}
@media screen and (max-width: 600px) {
  .main {
    flex-direction: column;
  }
}
