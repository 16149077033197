// Declaring Media Queries...

@mixin media_query($point) {
  @if $point == XXL {
    @media only screen and (min-width: 2000px) {
      @content;
      /* Anything you write inside the curly braces of the @include will be generated in place of the @content directive in our mixin. */
    }
  }

  /* Large Devices, Wide Screens 1200px */
  @else if $point == XL {
    @media only screen and (min-width: 1920px) {
      @content;
    }
  }

  /* Medium Devices, Desktops 992*/
  @else if $point == L {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }

  /* Small Devices, Tablets 768*/
  // @else if $point == M {
  //   @media only screen and (min-width: 960px) {
  //     @content;
  //   }
  // }
  // addded for landscape mode of Tablets
  @else if $point == ML {
    @media only screen and (min-width: 992px) {
      @content;
    }
  } @else if $point == M {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }

  /* Small Devices */
  @else if $point == S {
    @media only screen and (max-width: 600px) {
      @content;
    }
  }

  /* Extra Small Devices, Phones */
  @else if $point == XS {
    @media only screen and (min-width: 315px) {
      @content;
    }
  }
}
