@import '../../../styles/common/media_queries';
.drawer_container {
  @include media_query(M) {
    background: none;
  }
  .custom_overlay {
    width: 100%;
    height: 175px;
    position: fixed;
    top: 0px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    // position: ;
    background-color: rgba(0, 0, 0, 0.47);
    @include media_query(M) {
      display: none;
    }
  }
  .drawer_wrapper {
    // display: none;
    background-color: #fff;
    position: fixed;
    top: 140px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    padding: 35px 0px 0px;
    padding-right: 4px;
    flex-shrink: 0;
    border-left: 1px solid #e4e4e4;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 2px 8px 0px rgba(44, 44, 44, 0.12);
    // overflow-y: auto;
    @include media_query(M) {
      width: 500px;
      padding: 45px 0px 0;
      padding-right: 8px;
      right: 0;
      left: auto;
      top: 200px;
      // z-index: initial;
      border-radius: 0;
    }
    @include media_query(L) {
      padding-top: 0;
      height: calc(100vh - 125px);
      // position: relative;
      top: 125px;
    }

    .mob_bar {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 35px;
      background-color: #fafafa;
      border-radius: 20px 20px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      .line_bar {
        width: 50px;
        height: 3px;
        background-color: #2c2c2c;
      }
      @include media_query(M) {
        display: none;
      }
    }
    .header {
      display: flex;
      position: relative;
      padding-bottom: 16px;
      margin-bottom: 16px;
      // border-bottom: 1px solid #e4e4e4;
      @include media_query(M) {
        padding-bottom: 0px;
        margin-bottom: 24px;
      }
      .title {
        font-family: 'SourceSansPro';
        color: #2c2c2c;
        font-size: 21px;
        line-height: 24px;
        font-weight: 700;
        @include media_query(M) {
          font-size: 24px;
          line-height: 28px;
        }
      }
      .close_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        cursor: pointer;
        @include media_query(XS) {
          // top: 0px;
          display: none;
        }
        @include media_query(M) {
          display: flex;
          top: 0;
        }
        .close_icon {
          width: 12px;
          height: 12px;
          background-image: url(../../../styles/images/close.svg);
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
    .middle_container {
      overflow-y: auto;
      height: 100%;
      padding: 24px 15px 70px;
      margin-bottom: 50px;
      @include media_query(M) {
        padding: 40px 32px 40px 32px;
      }
      .edit_btn_wrapper {
        background-color: #38539a;
        border-radius: 24px;
        width: 100%;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        cursor: pointer;
        .txt {
          font-family: 'SourceSansPro';
          font-size: 16px;
          font-weight: 700;
          color: #ffffff;
          margin-right: 10px;
        }
        .edit_icon {
          width: 16px;
          height: 16px;
          background-image: url(../../../styles/images/edit_aud_group.svg);
          background-repeat: no-repeat;
          background-size: cover;
          cursor: pointer;
          margin-right: 8px;
        }
      }
      .edit_btn_wrapper_disabled {
        background-color: #d9d9d9;
        border-radius: 24px;
        width: 100%;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        cursor: pointer;
        .txt {
          font-family: 'SourceSansPro';
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;
          color: #5e5e5e;
          margin-right: 10px;
          @include media_query(M) {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .edit_icon {
          width: 16px;
          height: 14px;
          background-image: url(../../../styles/images/disabled_edit_agroup.svg);
          background-repeat: no-repeat;
          background-size: contain;
          cursor: pointer;
          margin-right: 8px;
        }
      }
      .course_title {
        font-family: 'SourceSansPro';
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;
        color: #2c2c2c;
        margin-bottom: 15px;
        @include media_query(M) {
          font-family: 'SourceSansPro';
          font-size: 24px;
          line-height: 28px;
        }
      }
      .description_wrapper {
        margin-bottom: 12px;
        @include media_query(M) {
          line-height: 1.2;
          margin-bottom: 16px;
        }
        .label {
          font-family: 'SourceSansPro';
          // font-size: 14px;
          color: #2c2c2c;
          font-weight: 700;
          margin-bottom: 4px;
          @include media_query(M) {
            font-family: 'SourceSansPro';
            font-size: 16px;
            line-height: 24px;
          }
        }
        .value {
          font-family: 'SourceSansPro';
          font-size: 14px;
          line-height: 18px;
          color: #2c2c2c;
          font-weight: 400;
          @include media_query(M) {
            font-family: 'SourceSansPro';
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .targ_wrapper {
        margin-bottom: 32px;
        .description_wrapper {
          margin: 0;
          .label {
            margin: 0;
          }
        }
        .pills_container {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;
          padding: 0;
          @include media_query(M) {
            margin-bottom: 0;
          }
        }
      }

      .upload_details_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 12px;
        @include media_query(M) {
          margin-bottom: 16px;
        }

        .upload_info {
          flex: 33.33%;
          max-width: 33.33%;
          padding: 0 15px;
          border-left: 1px solid #e4e4e4;

          &:first-child {
            padding-left: 0;
            border: none;
          }
          &:last-child {
            padding-right: 0;
          }
          .title {
            font-family: 'SourceSansPro';
            font-size: 14px;
            font-weight: 700;
            color: #2c2c2c;
            margin-bottom: 8px;
            @include media_query(M) {
              font-family: 'SourceSansPro';
              font-size: 16px;
            }
          }
          .info_text {
            font-family: 'SourceSansPro';
            font-size: 14px;
            font-weight: 500;
            color: #707070;
            @include media_query(M) {
              font-family: 'SourceSansPro';
              font-size: 16px;
            }
          }
        }
      }
      .note_wrapper {
        width: 100%;
        background-color: #fff9f0;
        border-radius: 12px;
        padding: 12px;
        margin-bottom: 24px;
        font-family: 'SourceSansPro';
        @include media_query(M) {
          margin-bottom: 32px;
        }
        .note_txt {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          font-style: italic;
          color: #2c2c2c;
          .semi_bold_txt {
            font-weight: 500;
          }
          @include media_query(M) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .img_instances_container {
        &.lp_user_grp_mgt {
          .dropdown_container {
            margin-bottom: 25px;
          }
          .add_course_btn_wrapper {
            height: auto;
            margin-bottom: 24px;
            margin-top: 24px;
            position: relative;
            box-shadow: none;
            padding: 0;
            .txt {
              @include media_query(XS) {
                font-size: 14px;
              }
              @include media_query(M) {
                font-size: 16px;
              }
            }
          }
        }
        .insturction_text_wrapper {
          display: flex;
          align-items: center;
          justify-content: end;
          margin-bottom: 12px;
          .info_text {
            font-family: 'SourceSansPro';
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            color: #2c2c2c;
            padding-right: 10px;
            cursor: pointer;
            @include media_query(M) {
              font-size: 16px;
              line-height: 24px;
            }
          }
          .left_arrow_icon {
            width: 12px;
            height: 18px;
            background-image: url(../../../styles/images/left_arrow_black.svg);
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
          }
        }
        .dropdown_container {
          // padding: 20px 0px;
          margin-bottom: 50px;
          border: 1px solid #e4e4e4;
          border-radius: 10px;
          position: relative;
          .top_bar {
            width: 100%;
            height: 50px;
            background-color: #f0f0f0;
            border-radius: 10px 10px 0 0;
            display: flex;
            align-items: center;
            position: relative;
            padding: 0 20px;
            cursor: pointer;
            .title {
              font-family: 'SourceSansPro';
              font-size: 14px;
              font-weight: 500;
              color: #2c2c2c;
              padding-right: 12px;

              @include media_query(M) {
                font-size: 18px;
                line-height: 21px;
              }
            }
            .info_btn_wrapper {
              position: relative;

              &.isClicked {
                .course_type_read_banner {
                  display: block;
                }
              }
              .info_btn {
                width: 20px;
                height: 20px;
                background-image: url(../../../styles/images/info_button.svg);
                background-repeat: no-repeat;
                background-size: contain;
                cursor: pointer;
              }

              .course_type_read_banner {
                display: none;
                position: absolute;
                top: 28px;
                right: -15px;
                width: 180px;
                min-height: 145px;
                background-color: #ffffff;
                border-radius: 12px;
                padding: 16px 5px 16px 16px;
                box-shadow: 0px 2px 16px rgb(44 44 44 / 10%);
                z-index: 1;
                .tooltip {
                  position: absolute;
                  top: -8px;
                  right: 18px;
                  width: 15px;
                  height: 14px;
                  background-image: url(../../../styles/images/tooltip_white_icon.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                }
                .title {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  line-height: 18px;
                  font-weight: 700;
                  color: #2c2c2c;
                  margin-bottom: 10px;
                  @include media_query(M) {
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
                .course_types {
                  .banner_details {
                    max-width: 100%;
                    margin-bottom: 12px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                    .item {
                      font-family: 'SourceSansPro';
                      font-size: 14px;
                      @include media_query(M) {
                        font-size: 16px;
                        line-height: 21px;
                      }
                    }
                  }
                }
              }
            }

            .toggle_arrow {
              position: absolute;
              right: 20px;
              width: 12px;
              height: 18px;
              background-repeat: no-repeat;
              background-size: contain;
              &.big_icon {
                &.up_arrow {
                  width: 12px;
                  height: 18px;
                }
              }
              &.up_arrow {
                transform: rotate(90deg);
                background-image: url(../../../styles/images/left_arrow_black.svg);
              }
              &.down_arrow {
                transform: rotate(270deg);
                background-image: url(../../../styles/images/left_arrow_black.svg);
              }
            }
          }
          .list_wrapper {
            // position: absolute;
            top: 100%;
            width: 100%;
            height: auto;
            .list {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 20px;
              width: 100%;
              min-height: 65px;
              border-bottom: 1px solid #e4e4e4;
              &:last-child {
                border: none;
              }

              .instance_status {
                min-width: 95px;
                height: 25px;
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 500;
                font-family: 'SourceSansPro';
                @include media_query(M) {
                  font-size: 16px;
                }
                &.live_sts {
                  color: #107f47;
                  background-color: #f0fae5;
                }
                &.draft_sts {
                  color: #a36a00;
                  background-color: #fff9f0;
                }
                &.offline_sts {
                  color: #62625a;
                  background-color: #f0f0f0;
                }
                &.go_live_sts {
                  color: #38539a;
                  background-color: #f1f2ff;
                }
                &.archive_sts {
                  color: #7f3e3e;
                  background-color: #ffe2e2;
                }
              }
            }
          }
        }
      }

      .form_main_wrapper {
        .disabled {
          opacity: 0.7;
          cursor: pointer;
          pointer-events: none;
        }
        .form_wrapper {
          margin-bottom: 24px;
          &.error_wrapper {
            input, textarea {
              border: 2px solid #d91734;
            }
          }
          .error_message {
            font-family: 'SourceSansPro';
            color: #d91734;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            padding: 10px 0;
          }
        } 
        .inner_label {
          display: flex;
          align-items: center;
        }
        .adapt_file {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        .label {
          font-size: 18px;
          font-weight: 700;
          color: #2C2C2C;
          margin-bottom: 12px;
        }
        .label_wrapper {
          display: flex;
          justify-content: space-between;
        }
        .tag_text {
          font-size: 16px;
          font-weight: 400;
          color: #707070;
        }
        .sub_text {
          font-size: 16px;
          font-weight: 400;
          color: #2C2C2C;
          list-style: 24px;
          margin-bottom: 12px;
        }
        .input_wrapper {
          border-radius: 12px;
          input, textarea {
            width: 100%;
            height: 36px;
            font-size: 16px;
            font-weight: 500;
            color: #2C2C2C;
            padding: 3px 12px 3px 12px;
            border-radius: 12px;
            border: 2px solid #2C2C2C;
            line-height: 24px;
          }
          textarea {
            min-height: 85px;
          }
        }
        .select_list_wrapper {
          position: relative;
          &.open {
            .select_list {
              color: #2C2C2C;
              border: 2px solid #38539A;
            }
            .dropdown_container {
              border: 2px solid #38539A;
            }
          }
        }
        .select_list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 36px;
          font-size: 16px;
          font-weight: 500;
          color: #707070;
          padding: 3px 12px 3px 12px;
          border-radius: 12px;
          border: 2px solid #2C2C2C;
          line-height: 24px;
          cursor: pointer;
          .down_arrow {
            width: 16px;
            height: 13px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(../../../styles/images/down-arrow-slim.svg);
          }
          &.selected {
            color: #2C2C2C;
          }
        }
        .dropdown_container {
          position: absolute;
          top: 45px;
          border: 2px solid #38539A;
          border-radius: 12px;
          width: 100%;
          min-height: 100px;
          max-height: 430px;
          overflow: auto;
          background-color: #fff;
          z-index: 1;
          .item {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            color: #2C2C2C;
            padding: 15px 15px;
            cursor: pointer;
            &:hover {
              background-color: #F4F4F4;
            }
            &:first-child {
              border-radius: 12px 12px 0 0;
            }
            &:last-child {
              border-radius: 0 0 12px 12px;
            }
            &.disabled{
              cursor: not-allowed; 
              pointer-events: none; 
              background-color: white;
              color:#D9D9D9;        
            }
          }
        }
      }

      .btn_wrapper {
        display: flex;
        font-family: 'SourceSansPro';
        flex-direction: column;
        justify-content: center;
        padding: 0;
        margin-bottom: 20px;
        &.disabled {
          pointer-events: none;
          .btn {
            background-color: #d9d9d9;
            color: #5e5e5e;
            border: transparent;
          }
          .delete_icon {
            background-image: url(../../../styles/images/delete-black.svg);
          }
        }
        &.justify_left {
          justify-content: left;
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 120px;
          height: 35px;
          font-family: 'SourceSansPro';
          font-size: 16px;
          font-weight: 700;
          // background-color: #d9d9d9;
          border: 2px solid #2c2c2c;
          color: #2c2c2c;
          border-radius: 24px;
          margin: 0 6px 0;
          padding: 0 30px;
          white-space: nowrap;
          cursor: pointer;
          &.save {
            gap: 8px;
            background-color: #2c2c2c;
            color: #fff;
            &:hover {
              background-color: #2c2c2c;
              color: #fff;
              border: 2px solid #2C2C2C;
            }
            .save_icon {
              width: 16px;
              height: 18px;
              background-size: contain;
              background-repeat: no-repeat;
              background-image: url(../../../styles/images/save_icon.svg);
            }
          }
          &.disabled {
            gap: 8px;
            background-color: #d9d9d9;
            color: #5e5e5e;
            border: transparent;
            pointer-events: none;
            &:hover {
              background-color: #d9d9d9;
              color: #5e5e5e;
              border: transparent;
              pointer-events: none;
            }
            .save_icon {
              width: 16px;
              height: 18px;
              background-size: contain;
              background-repeat: no-repeat;
              background-image: url(../../../styles/images/save_disabled_icon.svg);
            }
          }
        }
        .delete_icon {
          width: 16px;
          height: 18px;
          background-image: url(../../../styles/images/delete-black-update.svg);
          background-repeat: no-repeat;
          background-size: cover;
          margin: 0 10px;
        }
       
        // &:hover {
        //   .btn {
        //     background-color: #d91734;
        //     color: #fff;
        //     border: 2px solid #d91734;
        //   }
        //   .delete_icon {
        //     background-image: url(../../../styles/images/delete-white.svg);
        //   }
        // }
      }
      .suggestion_text {
        font-family: 'SourceSansPro';
        font-size: 14px;
        color: #a36a00;
        min-height: 50px;
        font-weight: 400;
        background: #fff9f0;
        border: 1px solid #d1b278;
        border-radius: 12px;
        padding: 10px;
        margin-bottom: 20px;
      }
      .add_course_btn_wrapper {
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 15px;
        background-color: #ffffff;
        height: 70px;
        display: flex;
        align-items: center;
        box-shadow: 0px -1px 10px rgba(44, 44, 44, 0.25);
        @include media_query(M) {
          padding: 0;
          position: relative;
          margin-bottom: 25px;
          box-shadow: none;
        }
        .add_course_ag_btn {
          width: 100%;
          border: 2px solid #2c2c2c;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'SourceSansPro';
          height: 36px;
          border-radius: 100px;
          cursor: pointer;
          .plus_icon {
            width: 15px;
            height: 15px;
            background-image: url('../../../styles/images/plus_icon_aud.svg');
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 8px;
          }
          .txt {
            font-family: 'SourceSansPro';
            font-size: 16px;
            line-height: 18px;
            font-weight: 700;
            @include media_query(M) {
              // font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e4e4e4;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #707070;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #707070;
    }
  }
}

.btn_wrapper_uaf {
  width: 100%;
  height: 35px;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  margin-bottom: 15px;
  cursor: pointer;
  &.margin_zero {
    @include media_query(XS) {
      margin-bottom: 0;
    }
  }
  .txt_replace {
    font-family: 'SourceSansPro';
    color: #5e5e5e;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin-right: 10px;
  }

  @include media_query(M) {
    height: 48px;
  }

  &.btn_wrapper_dec {
    height: 35px;
    width: 100%;
    background-color: #ffffff;
    color: #d91734;
    border: 2px solid #d91734;
    @include media_query(M) {
      width: 50%;
    }

    .gift_icon {
      width: 20px;
      height: 16px;
      background-image: url(../../../styles/images/red_gift_icon.svg);
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 10px;
    }
    .txt {
      font-family: 'SourceSansPro';
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: #d91734;
      @include media_query(M) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .txt {
    font-family: 'SourceSansPro';
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #5e5e5e;
    margin-right: 10px;
  }
  .upload_icon {
    width: 18px;
    height: 16px;
    background-image: url(../../../styles/images/upload_gray_icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  .disable_state {
    width: 18px;
    height: 18px;
    background-image: url(../../../styles/images/upload_gray_icon.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.inprogress_state {
    background-color: #f1f2ff;

    .txt {
      color: #38539a;
    }
    .upload_icon {
      width: 16px;
      height: 16px;
      background-image: url(../../../styles/images/inprogress_icon.svg);
      background-repeat: no-repeat;
      background-size: cover;
      animation: rotation 2s infinite linear;
    }
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
  &.final_state {
    background-color: #2c2c2c;
    .txt {
      color: #ffffff;
    }
    .upload_icon {
      width: 18px;
      height: 16px;
      background-image: url(../../../styles/images/upload_icon.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
.darkBackground {
  background-color: #2c2c2c;
}

.attachement_wrapper {
  width: 100%;
  min-height: 50px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  margin: 15px 0;
  .txt {
    font-family: 'SourceSansPro';
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #2c2c2c;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .preview_adapt_text {
    width: 160px;
    justify-content: flex-end;
  }
  .bold {
    display: flex;
    align-items: center;
    font-family: 'SourceSansPro';
    font-size: 16px !important;
    line-height: 50px !important;
    font-weight: 700 !important;
    color: #2c2c2c !important;
  }
  .cursor_p {
    cursor: pointer;
  }
  .left_arrow_icon {
    width: 12px;
    height: 18px;
    background-image: url(../../../styles/images/left_arrowN.svg);
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 9px;
  }
  .file_wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    .upload_icon_blue {
      width: 18px;
      height: 17px;
      background-image: url(../../../styles/images/upload_icon_n.svg);
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 8px;
    }
    .txt {
      font-family: 'SourceSansPro';
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: #38539a;
      border-bottom: 2px solid #38539a;
    }
  }
}

.info_container {
  .inr_wrapper {
    margin-bottom: 25px;
    &.margin_zero {
      margin-bottom: 0;
      padding-top: 0;
    }
    &.margin_class {
      margin-bottom: 15px;
    }
    @include media_query(M) {
      margin-bottom: 25px;
    }
    @include media_query(L) {
      margin-bottom: 40px;
    }
    .astric {
      position: relative;
      top: -3px;
    }
    .title_wrapper {
      &.padding_class {
        padding-top: 10px;
        @include media_query(L) {
          padding-top: 25px;
        }
      }
      &.invisible_tt {
        visibility: hidden;
      }
      .title {
        display: flex;
        align-items: center;
        color: #38539a;
        position: relative;
        @include media_query(M) {
          margin-bottom: 16px;
        }
        .icon {
          width: 25px;
          height: 25px;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 10px;

          &.setting_icon {
            width: 25px;
            height: 20px;
            background-image: url(../../../styles/images/setting_icon.svg);
          }

          &.timer_icon {
            width: 26px;
            height: 24px;
            background-image: url(../../../styles/images/timer_icon.svg);
          }
          &.publish_icon {
            width: 23px;
            height: 24px;
            background-image: url(../../../styles/images/publish_icon.svg);
          }
          &.info_icon {
            width: 26px;
            height: 24px;
            background-image: url(../../../styles/images/info_icon.svg);
          }
          &.user_icon {
            width: 27px;
            height: 20px;
            background-image: url(../../../styles/images/user_icon.svg);
          }
          &.achievements_icon {
            width: 20px;
            height: 28px;
            background-image: url(../../../styles/images/achievements_icon.svg);
          }

          &.thumbnail_icon {
            width: 25px;
            height: 20px;
            background-image: url(../../../styles/images/thumbnail_icon.svg);
          }

          &.content_book_icon {
            width: 22px;
            height: 26px;
            background-image: url(../../../styles/images/content_book_iocn.svg);
          }
          &.tag_icon {
            width: 18px;
            height: 18px;
            background-image: url(../../../styles/images/tag_icon.svg);
          }
          &.skills_icon {
            width: 22px;
            height: 24px;
            background-image: url(../../../styles/images/skills_icon.svg);
          }
          &.warn_icon {
            width: 20px;
            height: 24px;
            background-image: url(../../../styles/images/warn_icon.svg);
          }
        }
      }
      .arw_blue_icon {
        position: absolute;
        top: 10px;
        right: 0px;
        width: 20px;
        height: 12px;
        background-image: url(../../../styles/images/blue_arw_icon.svg);
        background-repeat: no-repeat;
        background-size: cover;
        transition: 0.3s ease-in;
        cursor: pointer;
        @include media_query(M) {
          right: 35px;
        }
        @include media_query(L) {
          right: 0;
        }

        &.is_decommission_open {
          transform: rotate(180deg);
        }
      }
    }
    .suggesion_box {
      background-color: #f1f2ff;
      border-radius: 12px;
      width: 100%;
      min-height: 40px;
      padding: 0 10px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      .txt {
        font-family: 'SourceSansPro';
        font-size: 14px;
        color: #38539a;
        font-weight: 400;
        line-height: 18px;
      }
      .bold_text {
        font-family: 'SourceSansPro';
        font-weight: 700;
      }
    }
    .warning_section {
      display: flex;
      background: #fff9f0;
      border: 1px solid #d1b278;
      border-radius: 10px;
      padding: 10px;
      // margin-bottom: 15px;

      @include media_query(M) {
        // display: none;
      }
      .message_section {
        padding-left: 5px;

        .mb_5 {
          margin-bottom: 5px;
        }

        .heading {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 400;
          color: #a36a00;
        }
      }
    }

    .course_status {
      width: 100%;
      height: 65px;
      padding: 0 15px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;
      @include media_query(M) {
        margin-bottom: 40px;
        padding: 0 25px;
      }

      &.archive {
        background-color: #fdf4f4;
        .txt_wrapper {
          .status_txt {
            color: #d91734;
          }
        }
        .status_btn {
          display: none;
        }
      }

      &.draft {
        background-color: #fff9f0;
        .txt_wrapper {
          .status_txt {
            color: #a36a00;
          }
        }
      }

      &.offline {
        background-color: #f0f0f0;
        .txt_wrapper {
          .status_txt {
            color: #62625a;
          }
        }
      }
      &.live {
        background-color: #f0fae5;
        .txt_wrapper {
          .status_txt {
            color: #107f47;
          }
        }
        .status_btn {
          display: flex;
        }
      }

      @include media_query(M) {
        min-height: 72px;
      }

      .txt_wrapper {
        display: inline-block;
        margin-right: 15px;
        .txt {
          font-family: 'SourceSansPro';
          display: inline;
          font-size: 18px;
          line-height: 24px;
          font-weight: 500;
          color: #2c2c2c;
          margin-right: 8px;
          @include media_query(M) {
            font-size: 24px;
            line-height: 28px;
            font-weight: 400;
          }
        }
        .status_txt {
          font-family: 'SourceSansPro';
          display: inline;
          font-size: 18px;
          font-weight: 700;
          line-height: 18px;

          @include media_query(M) {
            font-size: 24px;
            line-height: 24px;
          }
        }
      }

      .status_btn {
        font-family: 'SourceSansPro';
        width: 120px;
        height: 35px;
        background: #ffffff;
        border: 2px solid #2c2c2c;
        box-sizing: border-box;
        border-radius: 24px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #2c2c2c;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 5px;
        @include media_query(M) {
          min-width: 200px;
          height: 50px;
          display: flex;
        }
        &:hover {
          color: #ffffff;
          background-color: #2c2c2c;
        }
      }
    }
    .course_action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 40px;

      .txt {
        font-family: 'SourceSansPro';
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #2c2c2c;
        margin-right: 10px;
      }
      .archive_black_icon {
        background-image: url(../../../styles/images/archive_black.svg);
        width: 20px;
        height: 18px;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .upload_details_wrapper {
      width: 100%;
      display: flex;
      justify-content: space-around;
      // margin-bottom: 25px;

      .upload_info {
        flex: 33.33%;
        max-width: 33.33%;
        display: flex;
        flex-direction: column;
        // align-items: center;
        border-left: 1px solid #e4e4e4;
        padding-left: 15px;
        padding-right: 15px;
        @include media_query(M) {
          padding-left: 50px;
          padding-right: 50px;
        }
        &:first-child {
          align-items: flex-start;
          border-left: none;
          padding-left: 0;
          @include media_query(M) {
            padding-right: 50px;
          }
        }
        &:last-child {
          padding-right: 0;
        }

        .title {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          color: #2c2c2c;
          margin-bottom: 10px;
          @include media_query(M) {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .info_text {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          color: #707070;
          @include media_query(M) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
  &.course_adapt_wrapper {
    .inr_wrapper {
      @include media_query(XS) {
        margin-bottom: 20px !important;
      }
      @include media_query(M) {
        margin-bottom: 25px !important;
        padding:  0 0;
        padding:  0 0;
      }
      .title_wrapper {
        .title {
         
          &.title_black {
            font-family: 'SourceSansPro';
            font-size: 18px;
            line-height: 18px;
            color: #2c2c2c;
            margin-bottom: 16px;
          }
        }
        .txt {
          @include media_query(M) {
            margin-bottom: 16px;
          }
          @include media_query(L) {
            margin-bottom: 0;
          }
          &.course_txt {
            @include media_query(XS) {
              margin-bottom: 12px;
            }
            @include media_query(L) {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}