@import '../../../styles/common/media_queries';
.dateRangeCalendarContainer {
    min-width: 390px;
    @media screen and (max-width: 992px) {
      min-width: auto;
      width: 100%;
    }
}

.search_main_wrapper {
  position: relative;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e4e4e4;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }

  &.active {
    .dropdown_container,
    .search_wrapper {
      border: 2px solid #38539a;
    }
  }

  .dropdown_container {
    position: absolute;
    width: 100%;
    top: 45px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 1;
    border: 2px solid #707070;
    padding: 10px 10px 10px 0;

    .list_wrapper {
      height: 185px;
      overflow: auto;

      .list {
        display: flex;
        align-items: center;
        padding: 0 16px;
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 500;
        color: #2c2c2c;
        cursor: pointer;
        @include media_query(XS) {
          height: auto;
          margin-bottom: 20px;
        }
        @include media_query(M) {
          min-height: 35px;
          margin-bottom: 0;
        }
        &:hover {
          font-weight: 700;
        }
      }
    }
  }
}

.search_wrapper {
  display: flex;
  position: relative;
  align-items: center;
  border: 2px solid #2c2c2c;
  border-radius: 10px;
  height: 35px;
  // width: 21rem;
  padding: 0 12px;

  @include media_query(XS) {
    //width: 15.5rem;
  }

  @include media_query(M) {
    //width: 21rem;
  }

  .icon_wrapper {
    margin-left: auto;

    .search {
      width: 18px;
      height: 18px;
      background-image: url(../../../styles/images/calendar_roaster_icon.svg);
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  .search_input {
    background-color: transparent;
    font-family: 'SourceSansPro';
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #2c2c2c;
    width: 100%;
    border: none;
    outline: none;
    // padding-right: 200px;
    display: flex;
    align-items: center;
    &.search_font_XS {
      @include media_query(XS) {
        font-size: 14px;
      }
      @include media_query(M) {
        font-size: 16px;
      }
    }
    &::placeholder {
      font-weight: 400;
      color: #707070;
    }
  }
}

.calendarContainer {
    margin-top: 8px;
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  border-radius: 12px;
  max-width: 390px;
  border: 2px solid var(--primary-3-dark-purple, #38539a);
  background: var(--primary-2-pure-white, #fff);
  .calendarHeader {
    padding: 0px 24px;
    margin: 20px 0px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .calendarHeaderText {
      color: var(--primary-1-amway-black, #2c2c2c);
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 133.333% */
    }
    .closeIcon {
      background: url('../../../styles/images/x_close_black.svg');
      height: 13px;
      width: 15px;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
  .calendarDescription {
    padding: 16px 24px;
    border-top: 2px solid #e4e4e4;
  }

  .filter_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    box-shadow: 0px -2px 6px 0px rgba(44, 44, 44, 0.1);
    @include media_query(XS) {
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px;
      box-shadow: 0px -2px 6px rgba(44, 44, 44, 0.1);
    }
    @include media_query(M) {
      margin-top: auto;
      padding: 25px 0;
    }
    .m_lr_6 {
      margin: 0 6px;
    }
    .btn {
      font-family: 'SourceSansPro';
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 2px solid #2c2c2c;
      color: #2c2c2c;
      border-radius: 24px;
      height: 36px;
      outline: none;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      &.dark {
        background: #2c2c2c;
        color: #fff;
      }
      &:first-child {
        width: 35%;
      }
      &:last-child {
        @include media_query(XS) {
          min-width: 65%;
        }
        @include media_query(M) {
          min-width: 33%;
        }
      }
      @include media_query(M) {
        min-width: 120px;
        max-width: 150px;
      }
    }
    .btn_disable {
      font-family: 'SourceSansPro';
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 2px solid #d9d9d9;
      color: #2c2c2c;
      border-radius: 24px;
      height: 36px;
      font-size: 16px;
      font-weight: 700;
      pointer-events: none;
      cursor: not-allowed;
      outline: none;
      &.dark {
        background: #d9d9d9;
        color: #5e5e5e;
      }
      &:first-child {
        width: 35%;
      }
      &:last-child {
        @include media_query(XS) {
          min-width: 65%;
        }
        @include media_query(M) {
          min-width: 33%;
        }
      }
      @include media_query(M) {
        min-width: 120px;
        max-width: 150px;
      }
    }
  }
}
.dropdowns {
  font-weight: 500;
  color: #38539a;
  font-family: 'gtWalsheim';
}
button.button_previous {
  border-radius: 24px 0px 0px 24px ;
  background: var(--primary-4-light-purple, #f1f2ff) ;
  width: 40px ;
  height: 24px ;
}
button.button_next {
    border-radius: 0px 24px 24px 0px;
    background: var(--primary-4-light-purple, #f1f2ff);
    width: 40px;
    height: 24px;
    margin-left: 4px;
  }

button.day_button{
    background-color: #38539A;

}
.weekday {
  font-weight: bolder;
}
.root {
  padding: 16px 0px;
  font-family: 'gtWalsheim';
}
.months {
  margin: 0 auto;
}
.selected {
  border: none;
  background: #38539a;
}
.day_button {
  background: #38539a;
}
table thead tr > th:last-child,
table thead tr > td:last-child,
table tbody tr > th:last-child,
table tbody tr > td:last-child,
table tbody tr > td:first-child,
table thead tr > th:first-child
{
  border: none;
  border-radius: 0; /* Removes border radius */
}

/* styles.module.css */
.range_start :global(.rdp-day_button) {
  background-color: #38539a !important;
  color: #fff !important;
  border: none !important;
}
.range_end :global(.rdp-day_button) {
  background-color: #38539a !important;
  color: #fff !important;
  border: none !important;
}