@import '../../../styles/common/media_queries';

.modal_wrapper {
  // display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 15;
}
.course_mgt_wrapper {
  width: 100%;
  // Notibar
  .notification_wrapper {
    position: fixed;
    top: 15px;
    height: auto;
    z-index: 5;
    @include media_query(XS) {
      width: auto;
      margin: 0 15px;
    }
    @include media_query(M) {
      width: 450px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }

    .noti_bar {
      width: 100%;
      min-height: 60px;

      border-radius: 12px;
      padding: 12px 35px;
      position: relative;

      &.success_bar {
        background-color: #f0fae5;
        border: 1px solid #7fbc96;
      }
      &.failure_bar {
        background-color: #fff9f0;
        border: 1px solid #d1b278;
      }
      &.insufficient_bar {
        background-color: #fdf4f4;
        border: 1px solid #eb8593;
      }
      .text_wrapper {
        font-family: 'SourceSansPro';
        &.success_txt {
          color: #107f47;
        }
        &.failure_txt {
          color: #a36a00;
        }
        &.insufficient_txt {
          color: #d91734;
        }
        .tagline {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 700;
        }
        .description {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 400;
        }
      }
      .left_icon {
        position: absolute;
        top: 12px;
        left: 10px;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: cover;
        &.success_tick_icon {
          background-image: url(../../../styles/images/success_icon.svg);
        }
        &.failure_icon {
          width: 18px;
          background-image: url(../../../styles/images/failure_icon.svg);
        }
        &.insufficient_icon {
          width: 16px;
          background-image: url(../../../styles/images/insufficient_icon.svg);
        }
      }

      .right_icon {
        position: absolute;
        top: 12px;
        right: 10px;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        &.green_close_icon {
          background-image: url(../../../styles/images/close_green_icon.svg);
        }
        &.red_close_icon {
          background-image: url(../../../styles/images/close_red_icon.svg);
        }
        &.pink_close_icon {
          background-image: url(../../../styles/images/close_pink_icon.svg);
        }
      }
    }
  }

  .audience_wrapper {
    // height: calc(100vh - 185px);
    // overflow-y: auto;
    overflow: hidden;
    &.course_inner_wrapper {
      @include media_query(M) {
        padding-top: 150px;
      }
      @include media_query(L) {
        height: 100%;
        overflow-y: visible;
      }
    }
    @include media_query(XS) {
      margin-top: 15px;
    }
    @include media_query(M) {
      // padding-left: 65px;
      padding-top: 150px;
      margin-top: 0;
      height: calc(100vh - 15px);
    }
    @include media_query(L) {
      height: 100%;
      // padding-left: 0;
    }

    .inner_wrapper {
      display: flex;
      // padding-right: 4px;
      // height: calc(100vh - 200px);
      // overflow-y: auto;
      @include media_query(M) {
        padding-right: 8px;
      }

      @include media_query(L) {
        // height: auto;
        overflow: visible;
      }

      // Card view
      .card_wrapper {
        display: none;
        width: 100%;
        height: 100%;
        @include media_query(M) {
          display: block;
        }

        .row_wrapper {
          display: flex;
          flex-wrap: wrap;
          margin: 0px;
          //height: 100%;
          @include media_query(XS) {
            padding-bottom: 75px;
          }
          @include media_query(M) {
            padding-bottom: 0;
            margin: 0 -12px;
            padding-top: 15px;
          }
          .card_holder {
            margin-bottom: 20px;
            z-index: 1;
            @include media_query(XS) {
              flex: 100%;
              max-width: 100%;
              padding: 0;
              margin-bottom: 24px;
            }
            @include media_query(M) {
              flex: 50%;
              max-width: 50%;
              padding: 0 12px;
            }
            @include media_query(L) {
              flex: 20%;
              max-width: 20%;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .card {
              width: 100%;
              background-color: #fff;
              cursor: pointer;
              position: relative;
              font-family: 'SourceSansPro';
              z-index: 1;
              @include media_query(XS) {
                height: 110px;
                display: flex;
                padding-bottom: 16px;
                border-bottom: 1px solid #e4e4e4;
                border-radius: 12px 12px 0 0;
              }
              @include media_query(M) {
                height: 215px;
                display: block;
                box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.12);
                border-radius: 12px !important;
                padding-bottom: 0;
              }
              @include media_query(L) {
                height: 225px;
              }
              &.selected {
                .checked_card_wrapper {
                  position: absolute;
                  top: 0px;
                  right: 0px;
                  width: 115px;
                  height: 40px;
                  z-index: 1;
                  background: #f1f2ff;
                  box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
                  border-radius: 0 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .checked_box {
                    width: 18px;
                    height: 18px;
                    background-image: url(../../../styles/images/checkbox_blue.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 12px;
                  }
                  .text {
                    font-size: 16px;
                    font-weight: 700;
                    color: #38539a;
                    line-height: 21px;
                  }
                }
                box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.25);
              }
              .popup_btn_wrapper {
                width: 32px;
                height: 32px;
                position: absolute;
                top: 8px;
                left: 8px;
                border-radius: 50%;
                background-color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 2;
                cursor: pointer;

                .course_type_icon {
                  background-repeat: no-repeat;
                  background-size: contain;
                  // margin-right: 8px;
                  // margin-top: 5px;
                  &.gen_visiblity_icon {
                    width: 14px;
                    height: 18px;
                    flex-shrink: 0;
                    background-image: url(../../../styles/images/gen_visibility_filled.svg);
                  }
                  &.qstart_icon {
                    width: 16px;
                    height: 16px;
                    flex-shrink: 0;
                    background-image: url(../../../styles/images/qstart_icon_filled.svg);
                  }
                  &.req_icon {
                    width: 18px;
                    height: 16px;
                    flex-shrink: 0;
                    background-image: url(../../../styles/images/req_icon_filled.svg);
                  }
                  &.recom_icon {
                    width: 18px;
                    height: 16px;
                    flex-shrink: 0;
                    background-image: url(../../../styles/images/recom_icon_filled.svg);
                  }
                }
                .course_type_popup {
                  width: 221px;
                  height: 240px;
                  background-color: #ffffff;
                  box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
                  border-radius: 12px;
                  position: absolute;
                  left: 0px;
                  top: 40px;
                  padding: 16px 14px 16px 14px;
                  z-index: 3;
                  display: none;
                  @include media_query(M) {
                    display: block;
                  }
                  .title {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #2c2c2c;
                    padding-bottom: 12px;
                    border-bottom: 1px solid #e4e4e4;
                    margin-bottom: 20px;
                  }
                  .course_types {
                    .item_wrapper {
                      display: flex;
                      align-items: center;
                      max-width: 100%;
                      margin-bottom: 16px;
                      overflow: hidden;
                      &:last-child {
                        margin-bottom: 0;
                      }
                      .radio_btn_wrapper {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                      }
                      .item {
                        font-family: 'SourceSansPro';
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 400;
                        color: #2c2c2c;
                        margin-right: 8px;
                        @include media_query(XS) {
                          font-size: 16px;
                          line-height: 24px;
                        }
                      }
                      .course_type_icon {
                        background-repeat: no-repeat;
                        background-size: contain;
                        // margin-right: 8px;
                        // margin-top: 5px;
                        &.gen_visiblity_icon {
                          width: 14px;
                          height: 18px;
                          flex-shrink: 0;
                          background-image: url(../../../styles/images/gen_visi_icon.svg);
                        }
                        &.qstart_icon {
                          width: 16px;
                          height: 16px;
                          flex-shrink: 0;
                          background-image: url(../../../styles/images/qstart_icon.svg);
                        }
                        &.req_icon {
                          width: 18px;
                          height: 16px;
                          flex-shrink: 0;
                          background-image: url(../../../styles/images/req_icon.svg);
                        }
                        &.recom_icon {
                          width: 18px;
                          height: 16px;
                          flex-shrink: 0;
                          background-image: url(../../../styles/images/recom_icon.svg);
                        }
                      }
                      .course_type_icon {
                        &.selected {
                          &.gen_visiblity_icon {
                            background-image: url(../../../styles/images/gen_visibility_filled.svg);
                          }
                          &.qstart_icon {
                            background-image: url(../../../styles/images/qstart_icon_filled.svg);
                          }
                          &.req_icon {
                            background-image: url(../../../styles/images/req_icon_filled.svg);
                          }
                          &.recom_icon {
                            background-image: url(../../../styles/images/recom_icon_filled.svg);
                          }
                        }
                      }
                    }
                  }
                }
              }
              .uncheck_card_wrapper {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 36px;
                height: 36px;
                z-index: 1;
                background-color: #ffffff;
                box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
                border-radius: 12px 0px;
                transform: rotate(-90deg);
                display: flex;
                align-items: center;
                justify-content: center;

                .unchecked_box {
                  width: 18px;
                  height: 18px;
                  background-image: url(../../../styles/images/unselectedCheck.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }
              .checked_card_wrapper {
                display: none;
              }

              .card_top {
                position: relative;
                border-radius: 12px !important;
                @include media_query(XS) {
                  width: 95px;
                  height: 100%;
                }
                @include media_query(M) {
                  width: 100%;
                  height: 130px;
                  padding: 0;
                }
                .view_details {
                  display: none;
                }
                .img_wrapper {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  .img {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    @include media_query(M) {
                      // border-radius: 0;
                      border-radius: 12px 12px 0px 0px !important;
                    }
                  }
                }
              }
              .card_bottom {
                @include media_query(XS) {
                  width: calc(100% - 95px);
                  padding: 10px;
                  padding-top: 0;
                  padding-right: 25px;
                }
                @include media_query(M) {
                  width: 100%;
                  padding: 8px 8px 12px 8px;
                }
                .status_wrapper {
                  margin-bottom: 8px;
                  display: flex;

                  @include media_query(M) {
                    display: flex;
                    align-items: center;
                  }

                  .text_wrapper {
                    display: flex;
                    align-items: center;
                    @include media_query(M) {
                    }
                    .draft_icon {
                      width: 18px;
                      height: 18px;
                      background-image: url(../../../styles/images/draft_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                      display: block;
                      @include media_query(M) {
                        display: none;
                      }
                    }
                    .archive_cal_icon {
                      width: 18px;
                      height: 15px;
                      background-image: url(../../../styles/images/archive_box_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                    }
                    .offline_cal_icon {
                      width: 16px;
                      height: 16px;
                      background-image: url(../../../styles/images/offline_cal_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                    }
                    .calendar_icon {
                      width: 15px;
                      height: 18px;
                      background-image: url(../../../styles/images/calender_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                    }
                    .date_text {
                      font-family: 'SourceSansPro';
                      font-size: 14px;
                      font-weight: 400;
                      color: #707070;
                      line-height: 1;
                    }
                  }
                  &.draft_cd {
                    .date_text {
                      // display: none;
                    }
                  }
                }

                .description {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  font-weight: 400;
                  color: #2c2c2c;
                  max-height: 50px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  max-width: 95%;
                }
              }
            }
          }
        }
        @include media_query(M) {
          padding: 0 27px 0 40px;
          height: calc(100vh - 200px);
          overflow: auto;
        }
        @include media_query(L) {
          // width: calc(100% - 555px);
          height: calc(100vh - 225px);
          overflow: hidden;
          overflow-y: auto;
        }
      }

      .card_wrapper_with_drawer {
        height: 100%;
        overflow-x: hidden;

        .row_wrapper {
          display: flex;
          flex-wrap: wrap;
          margin: 0px;
          @include media_query(M) {
            margin: 0 -12px;
          }
          .card_holder {
            margin-bottom: 20px;
            @include media_query(XS) {
              flex: 100%;
              max-width: 100%;
              padding: 0;
              margin-bottom: 16px;
            }
            @include media_query(M) {
              flex: 50%;
              max-width: 50%;
              padding: 0 12px;
            }
            @include media_query(L) {
              flex: 33.3%;
              max-width: 33.3%;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .card {
              width: 100%;
              background-color: #fff;
              cursor: pointer;
              position: relative;

              @include media_query(XS) {
                height: 110px;
                display: flex;
                padding-bottom: 16px;
                border-bottom: 1px solid #e4e4e4;
                border-radius: 12px 12px 0 0;
              }
              @include media_query(M) {
                height: 220px;
                display: block;
                filter: drop-shadow(0px 2px 16px rgba(44, 44, 44, 0.12));
                border-radius: 12px;
                padding-bottom: 0;
              }
              @include media_query(L) {
              }
              &.selected {
                filter: drop-shadow(0px 2px 16px rgba(44, 44, 44, 0.25));
              }

              .card_top {
                position: relative;
                border-radius: 12px;
                @include media_query(XS) {
                  width: 95px;
                  height: 100%;
                }
                @include media_query(M) {
                  width: 100%;
                  height: 130px;
                  padding: 0;
                }
                .view_details {
                  display: none;
                }
                .img_wrapper {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  .img {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 12px 12px 0px 0px;
                    @include media_query(M) {
                      // border-radius: 0;
                    }
                  }
                }
              }
              .card_bottom {
                @include media_query(XS) {
                  width: calc(100% - 95px);
                  padding: 10px;
                  padding-right: 25px;
                }
                @include media_query(M) {
                  width: 100%;
                  padding: 8px;
                }
                .status_wrapper {
                  margin-bottom: 10px;
                  display: flex;

                  @include media_query(M) {
                    display: flex;
                    align-items: center;
                  }

                  .text_wrapper {
                    display: flex;
                    align-items: center;
                    @include media_query(M) {
                    }
                    .draft_icon {
                      width: 18px;
                      height: 18px;
                      background-image: url(../../../styles/images/draft_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                      display: block;
                      @include media_query(M) {
                        display: none;
                      }
                    }
                    .archive_cal_icon {
                      width: 18px;
                      height: 15px;
                      background-image: url(../../../styles/images/archive_box_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                    }
                    .offline_cal_icon {
                      width: 16px;
                      height: 16px;
                      background-image: url(../../../styles/images/offline_cal_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                    }
                    .calendar_icon {
                      width: 15px;
                      height: 18px;
                      background-image: url(../../../styles/images/calender_icon.svg);
                      background-repeat: no-repeat;
                      cursor: pointer;
                      margin-right: 4px;
                    }
                    .date_text {
                      font-family: 'SourceSansPro';
                      font-size: 14px;
                      font-weight: 400;
                      color: #707070;
                      line-height: 1;
                    }
                  }
                  &.draft_cd {
                    .date_text {
                      // display: none;
                    }
                  }
                }

                .description {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  font-weight: 400;
                  color: #2c2c2c;
                  max-height: 50px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  max-width: 95%;
                }
              }
            }
          }
        }
        .img_wrapper {
          width: 100%;
          height: 275px;
          position: relative;
          margin-bottom: 25px;
          object-fit: cover;

          .img {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 12px;
          }
          &.empty_state {
            .empty_state_img {
              background-image: url(../../../styles/images/Empty_State_img.svg);
            }
          }
        }
        @include media_query(M) {
          padding: 0 40px;
          padding-right: 32px;
        }
        @include media_query(L) {
          height: calc(100vh - 225px);
          overflow-y: auto;
          width: 100%;
        }
      }
      // List View table
      .table_main_wrapper {
        display: block;
        width: 100%;

        // @include media_query(L) {
        //   height: calc(100vh - 225px);
        //   overflow: hidden;
        //   overflow-y: auto;
        // }
        .tbl_header {
          display: flex;
          align-items: center;
          background-color: #2c2c2c;
          height: 50px;
          padding: 0 24px;
          @include media_query(M) {
            padding: 0 16px;
            margin: 0 24px 0 40px;
            margin-bottom: 16px;
            border-radius: 12px;
          }
          @include media_query(L) {
            padding: 0 40px;
          }
          .tbl_head_wrapper {
            display: flex;
            align-items: center;
            width: 100%;

            @include media_query(M) {
              width: 75%;
            }
            @include media_query(L) {
              width: 85%;
            }

            .tbl_head {
              &:nth-child(1) {
                display: flex;
                width: 60%;
                @include media_query(M) {
                  width: 45%;
                }
                @include media_query(L) {
                  width: 50%;
                }
              }
              &:nth-child(2) {
                display: none;
                @include media_query(M) {
                  display: flex;
                  width: 25%;
                }
              }
              &:nth-child(3) {
                width: 28%;
                display: flex;
                @include media_query(M) {
                  width: 20%;
                }
                // justify-content: center;
              }
              &:nth-child(4) {
                // display: none;
                display: flex;
                width: 12%;
                @include media_query(M) {
                  width: 5%;
                }
                // justify-content: center;
              }
            }
          }
          .tbl_head {
            font-family: 'SourceSansPro';
            font-size: 16px;
            font-weight: 500;
            color: #fafafa;

            &:nth-child(1) {
              display: none;

              @include media_query(M) {
                width: 25%;
                padding-left: 55px;
                display: flex;
              }
              @include media_query(L) {
                width: 15%;
              }
            }

            .active_user_icon {
              width: 25px;
              height: 18px;
              background-image: url(../../../styles/images/users_icon.svg);
              background-size: cover;
              background-repeat: no-repeat;
              margin-right: 8px;
            }
            .user_txt {
              display: none;
              @include media_query(L) {
                display: inline;
              }
            }
          }
        }

        .tbl_body {
          overflow: auto;
          background-color: #fff;
          padding: 0;
          // height: calc(100vh - 245px);
          @include media_query(XS) {
            height: calc(100vh - 230px);
          }
          // @include media_query(M) {
          //   padding: 0 40px;
          //   height: 100%;
          // }
          @include media_query(M) {
            padding: 0 20px 0 40px;
            height: calc(100vh - 275px);
          }

          .tbl_row_wrapper {
            .row_outer_wrapper {
              background-color: #fff;

              @include media_query(S) {
                padding: 0px 20px 0 24px;
              }
              @include media_query(M) {
                border-radius: 12px;
              }
              &:hover {
                @include media_query(M) {
                  background-color: #f4f4f4;
                }
              }
              &.selected {
                background-color: #f1f2ff;
                @include media_query(M) {
                  background-color: #fff;
                }
              }
            }
            .tbl_row {
              display: flex;
              align-items: center;
              min-height: 100px;
              // background-color: #fff;
              box-shadow: 0px 2px 8px rgba(44, 44, 44, 0.1);
              padding: 0 24px;
              margin-bottom: 16px;
              cursor: pointer;
              position: relative;
              @include media_query(S) {
                display: flex;
                min-height: auto;
                padding: 0;
                margin: 0;
                box-shadow: none;
                border-bottom: 1px solid #e4e4e4;
                min-height: 72px;
                &:last-child {
                  // border-bottom: none;
                }
                @include media_query(M) {
                  margin: 0 15px;
                }
              }
              @include media_query(M) {
                padding: 0 16px;
                border-radius: 12px;
              }
              @include media_query(L) {
                padding: 0 40px;
              }

              .tbl_data_wrapper {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                @include media_query(M) {
                  width: 75%;
                }
                @include media_query(L) {
                  width: 85%;
                }
                .tbl_data {
                  &:nth-child(1) {
                    width: 6%;
                    @include media_query(M) {
                      display: none;
                    }
                  }
                  &:nth-child(2) {
                    width: 40%;

                    @include media_query(S) {
                      width: 54%;
                      display: inline-block;
                      padding-left: 8px;
                    }
                    @include media_query(M) {
                      width: 45%;
                    }
                    @include media_query(L) {
                      width: 50%;
                    }

                    .inner_data {
                      @include media_query(L) {
                        padding-right: 100px;
                      }
                      @include media_query(M) {
                        padding-right: 25px;
                      }
                      @include media_query(S) {
                        padding-right: 0px;
                      }
                    }
                    .txt {
                      font-family: 'SourceSansPro';
                      font-size: 14px;
                      font-weight: 500;
                      color: #2c2c2c;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      max-width: 90%;

                      @include media_query(M) {
                        font-family: 'SourceSansPro';
                        font-size: 16px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        max-width: 80%;
                      }
                      @include media_query(L) {
                        max-width: 80%;
                      }
                    }
                  }
                  &:nth-child(3) {
                    width: 40%;
                    padding-left: 0;
                    .txt {
                      font-family: 'SourceSansPro';
                      color: #707070;
                      font-weight: 400;
                    }

                    @include media_query(S) {
                      width: 100%;
                      display: none;
                    }
                    @include media_query(M) {
                      width: 25%;
                    }
                  }
                  &:nth-child(4) {
                    width: 20%;
                    padding-left: 0;
                    @include media_query(S) {
                      width: 28%;
                    }
                    @include media_query(M) {
                      width: 20%;
                    }

                    .inner_data {
                      display: flex;
                      align-items: center;
                    }
                    .txt {
                      font-family: 'SourceSansPro';
                      font-size: 14px;
                      color: #707070;
                    }
                    .courses_icon {
                      opacity: 0.5;
                    }
                  }
                  &:nth-child(5) {
                    width: 12%;
                    @include media_query(M) {
                      width: 5%;
                    }
                    .type_wrapper {
                      display: none;
                      width: 32px;
                      height: 32px;
                      border-radius: 50%;
                      background-color: #ffffff;

                      // z-index: 2;
                      cursor: pointer;
                      box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
                      position: relative;
                      &.type_selected {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                      .course_type_icon {
                        background-repeat: no-repeat;
                        background-size: contain;
                        // margin-right: 8px;
                        // margin-top: 5px;
                        &.gen_visiblity_icon {
                          width: 14px;
                          height: 18px;
                          flex-shrink: 0;
                          background-image: url(../../../styles/images/gen_visibility_filled.svg);
                        }
                        &.qstart_icon {
                          width: 16px;
                          height: 16px;
                          flex-shrink: 0;
                          background-image: url(../../../styles/images/qstart_icon_filled.svg);
                        }
                        &.req_icon {
                          width: 18px;
                          height: 16px;
                          flex-shrink: 0;
                          background-image: url(../../../styles/images/req_icon_filled.svg);
                        }
                        &.recom_icon {
                          width: 18px;
                          height: 16px;
                          flex-shrink: 0;
                          background-image: url(../../../styles/images/recom_icon_filled.svg);
                        }
                      }
                      .course_type_popup {
                        position: fixed;
                        top: 0px;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        z-index: 3;

                        @include media_query(M) {
                          position: relative;
                          display: block;
                          width: 221px;
                          height: 240px;
                          background-color: #ffffff;
                          box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
                          border-radius: 12px;
                          position: absolute;
                          right: 0px;
                          left: auto;
                          bottom: auto;
                          top: 40px;
                          padding: 16px 14px 16px 14px;
                          z-index: 2;
                        }
                        .custom_overlay {
                          width: 100%;
                          height: calc(100% - 290px);
                          position: fixed;
                          top: 0px;
                          right: 0;
                          left: 0;
                          bottom: 305px;
                          z-index: 4;
                          background-color: rgba(112, 112, 112, 0.7);
                          @include media_query(M) {
                            display: none;
                          }
                        }
                        .popup_wrapper {
                          position: absolute;
                          top: calc(100% - 305px);
                          right: 0;
                          left: 0;
                          bottom: 0;
                          background-color: #ffffff;
                          z-index: 5;
                          border-radius: 20px 20px 0px 0px;
                          @include media_query(M) {
                            position: static;
                            left: auto;
                            bottom: auto;
                          }
                          .mob_bar {
                            width: 100%;
                            height: 35px;
                            background-color: #fafafa;
                            border-radius: 20px 20px 0px 0px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .line_bar {
                              width: 50px;
                              height: 3px;
                              background-color: #2c2c2c;
                            }
                            @include media_query(M) {
                              display: none;
                            }
                          }
                          .title {
                            font-family: 'SourceSansPro';
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 24px;
                            color: #2c2c2c;
                            padding: 24px 0 12px;
                            border-bottom: 1px solid #e4e4e4;
                            margin: 0 32px 20px;
                            @include media_query(M) {
                              font-weight: 500;
                              padding-top: 0;
                              margin: 0;
                              margin-bottom: 20px;
                            }
                          }
                          .course_types {
                            padding: 0 32px;
                            @include media_query(M) {
                              padding: 0;
                            }
                            .item_wrapper {
                              display: flex;
                              align-items: center;
                              max-width: 100%;
                              margin-bottom: 16px;
                              overflow: hidden;
                              &:last-child {
                                margin-bottom: 0;
                              }
                              .radio_btn_wrapper {
                                width: 20px;
                                height: 20px;
                                margin-right: 8px;
                              }
                              .item {
                                font-family: 'SourceSansPro';
                                font-size: 14px;
                                line-height: 18px;
                                font-weight: 400;
                                color: #2c2c2c;
                                margin-right: 8px;
                                @include media_query(XS) {
                                  font-size: 16px;
                                  line-height: 24px;
                                }
                              }
                              .course_type_icon {
                                background-repeat: no-repeat;
                                background-size: contain;
                                &.gen_visiblity_icon {
                                  width: 14px;
                                  height: 18px;
                                  flex-shrink: 0;
                                  background-image: url(../../../styles/images/gen_visi_icon.svg);
                                }
                                &.qstart_icon {
                                  width: 16px;
                                  height: 16px;
                                  flex-shrink: 0;
                                  background-image: url(../../../styles/images/qstart_icon.svg);
                                }
                                &.req_icon {
                                  width: 18px;
                                  height: 16px;
                                  flex-shrink: 0;
                                  background-image: url(../../../styles/images/req_icon.svg);
                                }
                                &.recom_icon {
                                  width: 18px;
                                  height: 16px;
                                  flex-shrink: 0;
                                  background-image: url(../../../styles/images/recom_icon.svg);
                                }
                              }
                              .course_type_icon {
                                &.selected {
                                  &.gen_visiblity_icon {
                                    background-image: url(../../../styles/images/gen_visibility_filled.svg);
                                  }
                                  &.qstart_icon {
                                    background-image: url(../../../styles/images/qstart_icon_filled.svg);
                                  }
                                  &.req_icon {
                                    background-image: url(../../../styles/images/req_icon_filled.svg);
                                  }
                                  &.recom_icon {
                                    background-image: url(../../../styles/images/recom_icon_filled.svg);
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .tbl_data {
                &:nth-child(2) {
                  width: 25%;
                  padding-left: 55px;
                  display: none;
                  .inner_data {
                    min-width: 105px;
                    .img {
                      width: 100%;
                      max-height: 62px;
                      height: 62px;
                      border-radius: 5px;
                      object-fit: cover;
                      display: none;
                      @include media_query(M) {
                        width: 115px;
                        display: block;
                      }
                    }
                    .empty_state_img {
                      width: 100%;
                      max-height: 62px;
                      height: 62px;
                      border-radius: 5px;
                      background-size: cover;
                      background-repeat: no-repeat;
                      object-fit: cover;
                      display: none;
                      // @include media_query(S) {
                      //   height: 102px;
                      //   max-height: 102px;
                      //   max-width: 96px;
                      // }
                      background-image: url(../../../styles/images/Empty_State_img.svg);
                      @include media_query(M) {
                        width: 115px;
                        display: block;
                      }
                    }
                  }
                  @include media_query(M) {
                    display: block;
                  }
                  @include media_query(L) {
                    width: 15%;
                  }
                }
                @include media_query(M) {
                  width: 25%;
                  // padding-left: 55px;
                }
                @include media_query(L) {
                  width: 15%;
                }
              }

              .checkbox_wrapper {
                position: absolute;
                left: 0;
                width: 55px;
                height: 100%;
                border-radius: 12px 0px 0px 12px;
                display: none;
                background-color: #f5f5f5;
                @include media_query(M) {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .check_box {
                  width: 18px;
                  height: 18px;
                  background-image: url(../../../styles/images/uncheck_box_black.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                }
                &.active {
                  background-color: #38539a;
                  .check_box {
                    width: 18px;
                    height: 18px;
                    background-image: url(../../../styles/images/checkbox_light.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                  }
                }
              }
              .courses_icon {
                width: 14px;
                height: 16px;
                background-image: url(../../../styles/images/files.svg);
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: 12px;
              }
              .txt {
                font-family: 'SourceSansPro';
                font-size: 14px;
                color: #2c2c2c;
                @include media_query(M) {
                  font-size: 16px;
                }
              }
            }
          }
        }
        /* width */
        ::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #e4e4e4;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #707070;
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }
        .img_wrapper {
          width: 100%;
          height: 275px;
          position: relative;
          margin-bottom: 25px;
          object-fit: cover;
          .img {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 12px;
          }
          &.empty_state {
            .empty_state_img {
              background-image: url(../../../styles/images/Empty_State_img.svg);
            }
          }
        }
      }
      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #e4e4e4;
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #707070;
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #707070;
      }

      .table_reduced_width {
        // width: 57% !important;
      }

      // Course Drawer
      .course_drawer_container {
        // display: none;
        // width: 550px;
        // overflow: auto;
        // border-left: 1px solid #e4e4e4;
        // z-index: 3;
        // @include media_query(L) {
        //   padding: 8px 0;
        //   height: calc(100vh - 110px);
        // }
        // @include media_query(M) {
        //   //position: absolute; // can provide absolute but moving with screen not looking feasible
        //   position: fixed; // updated due to changes required
        //   top: 125px;
        //   right: 0;
        //   // width: 40%;
        //   height: calc(100% - 125px);
        //   background-color: white;
        //   flex-shrink: 0;
        // }
        @include media_query(M) {
          background: none;
          padding-left: 8px;
        }
        .custom_overlay {
          width: 100%;
          height: 175px;
          position: fixed;
          top: 0px;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3;
          background-color: rgba(0, 0, 0, 0.47);
          @include media_query(M) {
            display: none;
          }
        }
        .course_drawer_wrapper {
          // background-color: #fff;
          // position: fixed;
          // top: 140px;
          // right: 0;
          // left: 0;
          // bottom: 0;
          // z-index: 10;
          // padding: 70px 15px 25px;
          // flex-shrink: 0;
          // border-radius: 20px 20px 0px 0px;
          // overflow-y: auto;
          // overflow-x: hidden;

          // @include media_query(M) {
          //   // display: block;
          //   // height: 100%;
          //   padding: 45px 40px;
          //   position: relative;
          //   top: 0;
          //   z-index: initial;
          //   position: relative;
          //   // border-radius: 0;
          // }

          @include media_query(L) {
            height: calc(100vh - 110px);
          }
          //
          // display: none;
          background-color: #fff;
          position: fixed;
          top: 140px;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 3;
          padding: 35px 0px 0px;
          padding-right: 4px;
          flex-shrink: 0;
          border-left: 1px solid #e4e4e4;
          border-radius: 20px 20px 0px 0px;
          // overflow-y: auto;
          @include media_query(M) {
            width: 550px;
            // padding: 45px 40px 0;
            padding: 0;
            padding-right: 8px;
            right: 0;
            left: auto;
            top: 200px;
            // z-index: initial;
            border-radius: 0;
          }
          @include media_query(L) {
            top: -50px;
            // padding: 0 40px 45px 24px;
            position: relative;
          }

          .mob_bar {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 35px;
            background-color: #fafafa;
            border-radius: 20px 20px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: center;

            .line_bar {
              width: 50px;
              height: 3px;
              background-color: #2c2c2c;
            }
            @include media_query(M) {
              display: none;
            }
          }
          .header {
            // height: 40px;
            height: auto;
            margin-bottom: 16px;
            display: flex;
            position: relative;
            .title {
              font-family: 'SourceSansPro';
              color: #2c2c2c;
              font-size: 21px;
              font-weight: 700;
              @include media_query(M) {
                font-family: 'SourceSansPro';
                font-size: 24px;
              }
            }
            .close_wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              right: 10px;
              width: 32px;
              height: 32px;
              cursor: pointer;
              @include media_query(XS) {
                // top: 0px;
                display: none;
              }
              @include media_query(M) {
                display: flex;
                top: 0;
              }
              .close_icon {
                width: 12px;
                height: 12px;
                background-image: url(../../../styles/images/close.svg);
                background-repeat: no-repeat;
                background-size: contain;
                cursor: pointer;
              }
            }
          }

          .middle_container {
            overflow-y: auto;
            height: 100%;
            // padding: 0 15px;
            padding: 15px;

            @include media_query(M) {
              padding: 40px 32px 40px 32px;
            }
            .edit_course_btn_wrapper {
              background-color: #38539a;
              border-radius: 24px;
              width: 100%;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 25px;
              cursor: pointer;
              .txt {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 700;
                color: #ffffff;
                margin-right: 10px;
              }
              .edit_icon {
                width: 14px;
                height: 16px;
                background-image: url(../../../styles/images/edit_white.svg);
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
              }
            }
            .edit_course_btn_wrapper_disabled {
              background-color: #e5e5e5;
              border-radius: 24px;
              width: 100%;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 25px;
              cursor: pointer;
              .txt {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 700;
                color: #5e5e5e;
                margin-right: 10px;
              }
              .edit_icon {
                width: 14px;
                height: 16px;
                background-image: url(../../../styles/images/disable_edit_course_icon.svg);
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
              }
            }
            .img_wrapper {
              width: 100%;
              height: 275px;
              position: relative;
              margin-bottom: 25px;

              .img {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                border-radius: 12px;
              }
              &.empty_state {
                .empty_state_img {
                  background-image: url(../../../styles/images/Empty_State_img.svg);
                }
              }
            }
            .course_title {
              font-family: 'SourceSansPro';
              font-size: 18px;
              font-weight: 700;
              color: #2c2c2c;
              margin-bottom: 15px;
              @include media_query(M) {
                font-family: 'SourceSansPro';
                font-size: 24px;
              }
            }
            .description_wrapper {
              margin-bottom: 15px;
              @include media_query(M) {
                line-height: 1.2;
              }
              .label {
                font-family: 'SourceSansPro';
                font-size: 14px;
                color: #2c2c2c;
                font-weight: 700;
                margin-bottom: 8px;
                @include media_query(M) {
                  font-family: 'SourceSansPro';
                  font-size: 16px;
                }
              }
              .value {
                font-family: 'SourceSansPro';
                font-size: 14px;
                color: #2c2c2c;
                font-weight: 400;
                @include media_query(M) {
                  font-family: 'SourceSansPro';
                  font-size: 16px;
                }
              }
            }
            .upload_details_wrapper {
              width: 100%;
              display: flex;
              justify-content: space-around;
              margin-bottom: 25px;

              .upload_info {
                flex: 33.33%;
                max-width: 33.33%;
                padding: 0 15px;
                border-left: 1px solid #e4e4e4;
                @include media_query(M) {
                  padding: 0 25px;
                }

                &:first-child {
                  padding-left: 0;
                  border: none;
                }
                &:last-child {
                  padding-right: 0;
                }
                .title {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  font-weight: 700;
                  color: #2c2c2c;
                  margin-bottom: 10px;
                  @include media_query(M) {
                    font-family: 'SourceSansPro';
                    font-size: 16px;
                  }
                }
                .info_text {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  font-weight: 500;
                  color: #707070;
                  @include media_query(M) {
                    font-family: 'SourceSansPro';
                    font-size: 16px;
                  }
                }
              }
            }
            .course_status {
              width: 100%;
              height: 65px;
              padding: 15px;
              border-radius: 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 25px;

              &.live {
                background-color: #f0fae5;
                .txt_wrapper {
                  .status_txt {
                    color: #107f47;
                  }
                }
              }
              &.offline {
                background-color: #f0f0f0;
                .txt_wrapper {
                  .status_txt {
                    color: #62625a;
                  }
                }
              }
              &.draft {
                background-color: #fff9f0;
                .txt_wrapper {
                  .status_txt {
                    color: #a36a00;
                  }
                }
              }

              &.archive {
                background-color: #fdf4f4;
                .txt_wrapper {
                  .status_txt {
                    color: #d91734;
                  }
                }
                .status_btn {
                  display: none;
                }
              }
              @include media_query(M) {
                min-height: 72px;
              }

              .txt_wrapper {
                display: inline-block;
                margin-right: 15px;
                .txt {
                  font-family: 'SourceSansPro';
                  display: inline;
                  font-size: 18px;
                  font-weight: 500;
                  color: #2c2c2c;
                  // margin-right: 8px;
                  @include media_query(M) {
                    font-family: 'SourceSansPro';
                    font-size: 24px;
                  }
                }
                .status_txt {
                  font-family: 'SourceSansPro';
                  display: inline;
                  font-size: 18px;
                  font-weight: 700;
                  line-height: 18px;

                  @include media_query(M) {
                    font-family: 'SourceSansPro';
                    font-size: 24px;
                    line-height: 24px;
                  }
                }
              }

              .status_btn {
                font-family: 'SourceSansPro';
                width: 120px;
                height: 35px;
                background-color: #ffffff;
                border: 2px solid #2c2c2c;
                box-sizing: border-box;
                border-radius: 24px;
                font-weight: 700;
                font-size: 14px;
                color: #2c2c2c;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                @include media_query(M) {
                  font-size: 16px;
                  min-width: 172px;
                  height: 50px;
                }
                &:hover {
                  color: #ffffff;
                  background-color: #2c2c2c;
                }
              }
            }
            .adapt_file_wrapper {
              font-family: 'SourceSansPro';
              width: 100%;
              height: 50px;
              background: #f4f4f4;
              border-radius: 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 15px;
              margin-bottom: 25px;
              .txt {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 700;
                color: #2c2c2c;
              }
              .file_name {
                font-size: 14px;
                font-weight: 700;
                color: #2c2c2c;
              }
            }
          }
          ::-webkit-scrollbar {
            width: 5px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: #e4e4e4;
            border-radius: 10px;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #707070;
            border-radius: 10px;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #707070;
          }
        }
      }

      @include media_query(XS) {
        // padding: 0 0px;
      }
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e4e4e4;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #707070;
      border-radius: 10px;
      height: 40%;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #707070;
    }
  }
}

//Common code
.status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  @include media_query(XS) {
    width: auto;
    // min-width: 45px;
    padding: 0 10px;
    height: 25px;
    border-radius: 4px;
  }
  @include media_query(M) {
    border-radius: 5px;
  }
  &.live {
    @include media_query(XS) {
      background-color: #f0fae5;
      color: #107f47;
    }
  }
  &.archive {
    @include media_query(XS) {
      background-color: #fdf4f4;
      color: #d91734;
    }
  }
  &.draft {
    @include media_query(XS) {
      background-color: #fff9f0;
      color: #a36a00;
    }
  }

  &.offline {
    @include media_query(XS) {
      background-color: #f0f0f0;
      color: #5e5e5e;
    }
  }
}
.status_txt {
  font-family: 'SourceSansPro';
  @include media_query(XS) {
    display: block;
    font-size: 14px;
    font-weight: 500;
  }
  @include media_query(M) {
    font-size: 16px;
  }
}
.txt {
  font-family: 'SourceSansPro';
  font-size: 14px;
  font-weight: 500;
  color: #2c2c2c;
}
.img_wrapper {
  width: 100%;
  height: 275px;
  position: relative;
  object-fit: cover;
  margin-bottom: 25px;
  .img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    // background-position: center;
    border-radius: 12px;
  }
  &.empty_state {
    .empty_state_img {
      object-fit: cover;
      background-image: url(../../../styles/images/empty_img_mob.svg);
      @include media_query(M) {
        background-image: url(../../../styles/images/Empty_State_img.svg);
      }
    }
  }
}
// Popup btn
.loading_shimmer_list_wrapper {
  padding: 0px 20px 0px 20px;
}
.loading_shimmer_manage_course {
  margin: 16px 0px 0px 20px;
}
