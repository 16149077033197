@import '../../../styles/common/media_queries';

.modal_wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(112, 112, 112, 0.7);
  z-index: 10;
  .tag_preset_modal {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    background-color: #fff;
    @include media_query(XS) {
      min-width: 100%;
      max-width: 100%;
      min-height: 100vh;
      max-height: 100vh;
      padding: 20px 16px 0px 16px;
    }
    @include media_query(M) {
      min-width: 750px;
      max-width: 767px;
      min-height: 550px;
      max-height: 650px;
      padding: 20px 30px 15px 30px;
    }
    @include media_query(L) {
      min-width: 800px;
      max-width: 950px;
      min-height: 230px;
      max-height: 650px;
    }
    .header_wrapper {
      display: flex;
      @include media_query(XS) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0 25px 0;
      }
      @include media_query(M) {
        flex-direction: row;
        align-items: center;
        padding: 0 25px 45px 0;
      }
      .heading {
        font-family: 'SourceSansPro';
        font-weight: 700;
        color: #2c2c2c;
        padding-right: 25px;
        @include media_query(XS) {
          font-family: 'SourceSansPro';
          font-size: 18px;
          margin-bottom: 24px;
        }
        @include media_query(M) {
          font-family: 'SourceSansPro';
          font-size: 24px;
          margin-bottom: 0;
        }
      }
      .sub_txt {
        @include media_query(XS) {
          display: none;
        }
        @include media_query(M) {
          display: block;
        }
        font-family: 'SourceSansPro';
        font-size: 16px;
        font-weight: 400;
        color: #2c2c2c;
      }
      .action_wrapper {
        // display: flex;
        align-items: center;
        margin-left: auto;
        @include media_query(XS) {
          display: flex;
          justify-content: center;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 16px;
          height: 95px;
          background-color: #ffffff;
          z-index: 1;
        }
        @include media_query(M) {
          display: none;
          position: relative;
          height: auto;
          margin: initial;
          margin-left: auto;
        }
        .audience_grp {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          border-radius: 24px;
          padding: 0 30px;
          cursor: pointer;
          @include media_query(XS) {
            border: 2px solid #2c2c2c;
            min-width: 100%;
            height: 48px;
          }
          @include media_query(M) {
            position: relative;
            min-width: 205px;
            height: 35px;
            margin: 0;
          }
          .txt {
            font-family: 'SourceSansPro';
            font-size: 16px;
            font-weight: 700;
            color: #2c2c2c;
            padding-left: 10px;
            text-transform: capitalize;
          }
        }
      }
    }
    .close_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 32px;
      background-color: #2c2c2c;
      cursor: pointer;
      .close_icon {
        width: 10px;
        height: 10px;
        background-image: url(../../../styles/images/close-white.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .search_main_wrapper {
      position: relative;
      margin-left: auto;
      margin-top: auto;
      @include media_query(XS) {
        width: 100%;
      }
      @include media_query(M) {
        width: auto;
      }
      /* width */
      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #e4e4e4;
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #707070;
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #707070;
      }
      &.active {
        .dropdown_container,
        .search_wrapper {
          border: 2px solid #38539a;
        }
      }
      .dropdown_container {
        position: absolute;
        width: 100%;
        top: 45px;
        background-color: #fff;
        border-radius: 10px;
        z-index: 1;
        border: 2px solid #707070;
        .list_wrapper {
          height: 185px;
          overflow: auto;
          .list {
            display: flex;
            align-items: center;
            font-family: 'SourceSansPro';
            font-size: 14px;
            font-weight: 500;
            color: #2c2c2c;
            padding: 0 16px;
            min-height: 35px;
            cursor: pointer;
            &:hover {
              font-weight: 700;
            }
          }
        }
      }
    }
    .search_wrapper {
      display: flex;
      align-items: center;
      border: 2px solid #2c2c2c;
      border-radius: 10px;
      height: 34px;
      padding: 0 15px;
      @include media_query(XS) {
        width: 100%;
      }
      @include media_query(M) {
        width: 22rem;
      }
      .icon_wrapper {
        margin-left: auto;
        .search {
          width: 18px;
          height: 18px;
          background-image: url(../../../styles/images/search-black.svg);
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }
      .search_input {
        background-color: transparent;
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 500;
        color: #2c2c2c;
        width: 100%;
        border: none;
        outline: none;
        padding-right: 15px;
        &::placeholder {
          font-weight: 400;
          color: #707070;
        }
      }
    }
    .collapsible_wrapper {
      margin-bottom: 15px;
      @include media_query(XS) {
        display: none;
      }
      @include media_query(M) {
        display: block;
      }
      .collapsible {
        display: flex;
        align-items: center;
        padding: 0 25px;
        height: 60px;
        background-color: #f0f0f0;
        border-radius: 10px;
        &.isOpenCollapse {
          border-radius: 10px 10px 0 0;
        }
        .txt {
          font-family: 'SourceSansPro';
          font-size: 24px;
          font-weight: 700;
          color: #2c2c2c;
        }
        .sub_txt {
          font-family: 'SourceSansPro';
          font-size: 16px;
          color: #2c2c2c;
          padding-left: 5px;
        }
      }
      .form_wrapper {
        background-color: #f0f0f0;
      }
      .content_wrapper {
        padding: 24px 25px 15px 25px;
        .top_area_wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          .txt {
            font-family: 'SourceSansPro';
            font-size: 16px;
            color: #949494;
          }
          .action_wrapper {
            display: flex;
            align-items: center;
            margin-left: auto;
            .audience_grp {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 205px;
              height: 35px;
              background: #38539a;
              border-radius: 24px;
              padding: 0 30px;
              cursor: pointer;
              &.disabled {
                pointer-events: none;
                background-color: #d9d9d9;
                color: #5e5e5e;
                border: 2px solid transparent;
                .txt {
                  color: #5e5e5e;
                }
                .add {
                  background-image: url(../../../styles/images/add-gray.svg);
                }
              }
              .txt {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 700;
                color: #fff;
                padding-left: 10px;
              }
              .add {
                width: 12px;
                height: 15px;
                background-image: url(../../../styles/images/add-white.svg);
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }
        }
      }
      .down_arrow {
        width: 23px;
        height: 15px;
        background-image: url(../../../styles/images/down-arrow-slim.svg);
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: auto;
        cursor: pointer;
        &.isOpen {
          transform: rotate(180deg);
        }
      }
    }
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e4e4e4;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #707070;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #707070;
    }
    .tag_list_wrapper {
      overflow: auto;
      padding-right: 10px;
      @include media_query(XS) {
        max-height: calc(100vh - 220px);
      }
      @include media_query(M) {
        max-height: 200px;
      }
      &.tag_collapisble {
        @include media_query(M) {
          max-height: 200px;
        }
        @include media_query(L) {
          max-height: 150px;
        }
      }
      @include media_query(M) {
        max-height: 350px;
      }
      .tag_list {
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid #e4e4e4;
        border-radius: 12px;
        @include media_query(XS) {
          min-height: 95px;
          padding-right: 16px;
          margin-bottom: 16px;
        }
        @include media_query(M) {
          min-height: 100px;
          padding-right: 40px;
          margin-bottom: 10px;
        }
        &:last-child {
          @include media_query(M) {
            margin-bottom: 0;
          }
        }
        .add_wrapper {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          min-width: 55px;
          background: #f1f2ff;
          border-radius: 12px 0px 0px 12px;
          cursor: pointer;
          .add_icon {
            width: 16px;
            height: 16px;
            background-image: url(../../../styles/images/add-rounded.svg);
            background-size: contain;
            background-repeat: no-repeat;
          }
          &:hover {
            background-color: #38539a;
            .add_icon {
              background-image: url(../../../styles/images/add-rounded-white.svg);
            }
          }
        }
        .middle_content {
          padding: 10px 16px 10px 70px;
          .heading_area {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .global {
              width: 18px;
              min-width: 18px;
              height: 18px;
              background-image: url(../../../styles/images/global.svg);
              background-size: contain;
              background-repeat: no-repeat;
            }
            .users_icon {
              width: 22px;
              min-width: 22px;
              height: 18px;
              background-image: url(../../../styles/images/people.svg);
              background-repeat: no-repeat;
              background-size: contain;
            }
            .title {
              font-family: 'SourceSansPro';
              font-weight: 700;
              color: #2c2c2c;
              @include media_query(XS) {
                font-family: 'SourceSansPro';
                font-size: 14px;
                padding-left: 16px;
              }
              @include media_query(M) {
                font-family: 'SourceSansPro';
                font-size: 18px;
                padding-left: 12px;
              }
            }
          }
          .pills_wrapper {
            @include media_query(XS) {
              display: none;
            }
            @include media_query(M) {
              display: flex;
            }
          }
        }
        &.editable_wrapper {
          @include media_query(XS) {
            background: rgba(112, 112, 112, 0.7);
            position: fixed;
            bottom: 0;
            left: 0;
            top: 0;
            right: 0;
            z-index: 2;
            border-radius: 0;
            margin-bottom: 0;
            padding-right: 0;
          }
          @include media_query(M) {
            position: relative;
            background: #f0f0f0;
            border-radius: 10px;
            padding: 25px 40px 25px 25px;
            margin-bottom: 16px;
            z-index: initial;
            padding-right: 16px;
          }
          .form_wrapper {
            @include media_query(XS) {
              background: #ffffff;
              /* Drawer Drop Shadow */

              box-shadow: 0px -1px 10px rgba(44, 44, 44, 0.25);
              border-radius: 52px 52px 0px 0px;
              padding: 55px 16px 20px 16px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: auto;
            }
            @include media_query(M) {
              background: transparent;
              box-shadow: none;
              border-radius: 0;
              padding: 0;
              margin-top: initial;
            }
          }
          .label_xs {
            @include media_query(XS) {
              display: block;
              font-family: 'SourceSansPro';
              font-size: 21px;
              font-weight: 700;
              color: #2c2c2c;
              padding: 0 16px;
              margin-bottom: 12px;
            }
            @include media_query(M) {
              display: none;
            }
          }
          .pills_wrapper {
            @include media_query(XS) {
              margin-bottom: 30px;
            }
            @include media_query(M) {
              margin-bottom: 0;
            }
          }
          .btn_wrapper {
            @include media_query(XS) {
              &.save_wrapper {
                width: 100%;
              }
              .btn_save {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 120px;
                height: 35px;
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 700;
                border: 2px solid #2c2c2c;
                background-color: #fff;
                border-radius: 24px;
                margin: 0 12px;
                padding: 0 30px;
                cursor: pointer;
                &.disabled_save {
                  background-color: #2c2c2c;
                  color: #fff;
                }
              }
            }
            @include media_query(M) {
              display: none;
              width: auto;
            }
          }
          .top_area {
            display: flex;
            align-items: center;
            width: 100%;
          }
        }
      }
    }
  }
  .checkmark_container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    &.justify_left {
      justify-content: left;
    }
    .add_icon {
      width: 12px;
      height: 14px;
      background-image: url(../../../styles/images/add-gray.svg);
      background-repeat: no-repeat;
    }
    .btn {
      display: flex;
      align-items: center;
      min-width: 120px;
      height: 35px;
      font-family: 'SourceSansPro';
      font-size: 16px;
      font-weight: 700;
      border: 2px solid #2c2c2c;
      background-color: #fff;
      border-radius: 24px;
      margin: 0 12px;
      padding: 0 30px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        background-color: #2c2c2c;
        border: 2px solid transparent;
        color: #fff;
      }
      &.disabled {
        pointer-events: none;
        background-color: #d9d9d9;
        color: #5e5e5e;
        border: 2px solid transparent;
      }
    }
  }
  .create_new_preset {
    padding: 25px;
    background-color: #f4f4f4;
    border-radius: 0 0 10px 10px;
  }
  .form_wrapper {
    width: 100%;
  }
  .action_wrapper {
    display: flex;
    align-items: center;
    @include media_query(XS) {
      flex-direction: column;
      justify-content: center;
      margin-left: auto;
    }
    @include media_query(M) {
      flex-direction: row;
      margin-left: auto;
    }
    .divider {
      height: 18px;
      border-right: 1px solid #2c2c2c;
      @include media_query(XS) {
        transform: rotate(90deg);
      }
      @include media_query(M) {
        transform: rotate(180deg);
      }
    }
    .delete_icon {
      width: 16px;
      height: 18px;
      background-image: url(../../../styles/images/delete-black.svg);
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      @include media_query(XS) {
        margin-right: 0;
      }
      @include media_query(M) {
        margin-right: 25px;
      }
      &:hover {
        background-image: url(../../../styles/images/delete-red.svg);
      }
    }
    .close_icon {
      width: 12px;
      height: 14px;
      background-image: url(../../../styles/images/close.svg);
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 25px;
      cursor: pointer;
      &:hover {
        background-image: url(../../../styles/images/close-red.svg);
      }
    }
    .edit_icon {
      width: 21px;
      height: 18px;
      background-image: url(../../../styles/images/edit-black.svg);
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      @include media_query(XS) {
        margin-left: 0;
      }
      @include media_query(M) {
        margin-left: 25px;
      }
      &:hover {
        background-image: url(../../../styles/images/edit-blue.svg);
      }
    }
    .undo_icon {
      width: 16px;
      height: 18px;
      background-image: url(../../../styles/images/undo.svg);
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 25px;
      cursor: pointer;
      &:hover {
        background-image: url(../../../styles/images/undo-blue.svg);
      }
    }
    .tick_icon {
      width: 17px;
      height: 16px;
      background-image: url(../../../styles/images/tick.svg);
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 25px;
      cursor: pointer;
      &:hover {
        background-image: url(../../../styles/images/tick-green.svg);
      }
    }
    &.action_position {
      position: absolute;
      top: 40px;
      right: 40px;
    }
  }
  .form_field {
    display: flex;
    flex-direction: column;
    .label {
      display: flex;
      align-items: center;
      font-family: 'SourceSansPro';
      font-size: 21px;
      font-weight: 500;
      color: #2c2c2c;
      margin-bottom: 12px;
      .users {
        display: flex;
        align-items: center;
        margin-left: auto;
        .txt {
          font-family: 'SourceSansPro';
          font-size: 21px;
          font-weight: 500;
          color: #2c2c2c;
        }
      }
    }
    .input_wrapper {
      .input {
        width: 100%;
        height: 35px;
        border: 2px solid #2c2c2c;
        border-radius: 10px;
        font-family: 'SourceSansPro';
        font-size: 16px;
        font-weight: 700;
        color: #707070;
        padding: 0 12px;
        &::placeholder {
          font-weight: 400;
        }
      }
      .textarea {
        width: 100%;
        height: 70px;
        border: 2px solid #2c2c2c;
        border-radius: 10px;
        font-family: 'SourceSansPro';
        font-size: 16px;
        font-weight: 500;
        color: #707070;
        padding: 10px 12px;
        &::placeholder {
          font-weight: 400;
        }
      }
    }
  }
  .pos_relative {
    position: relative;
  }
  .w_50 {
    width: 49%;
  }
  .mb_10 {
    margin-bottom: 10px;
  }
  .ml_10 {
    margin-left: 10px;
  }
  .flex_end {
    display: flex;
    align-items: flex-end;
  }
  .user {
    width: 16px;
    min-width: 16px;
    height: 18px;
    background-image: url(../../../styles/images/single-user.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  .user_icon {
    width: 23px;
    min-width: 23px;
    height: 18px;
    background-image: url(../../../styles/images/people.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 10px;
  }
}
