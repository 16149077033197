@import '../../../../../styles/common/media_queries';

.App {
  padding: 16px;
}

.Crop-Controls {
  margin-bottom: 10px;
}
.Crop-Controls > * {
  margin-bottom: 3px;
}

img {
  max-width: 100%;
  max-height: 800px;
}

.App_Image_Boundary_Dimensions {
  @include media_query(ML) {
    height: 700px;
    padding: 28px;
  }
}

// img {
//   max-width: 100%;
// }

// .App_Image_Boundary_Dimensions {
//   @include media_query(ML) {
//     height: 700px;
//     padding: 28px;
//   }
// }
