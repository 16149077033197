@import '../../../styles/common/media_queries';

/* Overlay for the background */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    z-index: 999;
  
    &.showOverlay {
      opacity: 1;
      visibility: visible;
    }
  }
  
  /* Side Drawer */
  .sideDrawer {
    position: fixed;
    top: 0;
    right: -60vw; /* Hidden off-screen to the right initially */
    height: 100%;
    width: 60vw;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    transition: right 0.3s ease-in-out;
    z-index: 1000; /* Ensure it is above the overlay */
  
    &.open {
      right: 0; /* Slide into view */
    }

    @include media_query(ML) {
        width: 30vw;
        right: -30vw;
    }
  }
  
  /* Drawer Content */
  .drawerContent {
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
    position: relative;
  
    .closeBtn {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
  