@import '../../../styles/common/media_queries';

.top_area_wrapper {
  display: flex;
  align-items: center;
  @include media_query(XS) {
    margin: 0 15px;
    margin-bottom: 8px;
  }
  @include media_query(M) {
    // display: flex;
    margin: 0;
    margin-bottom: 15px;
    padding: 0 40px;
  }

  @include media_query(L) {
    width: 100%;
  }
  &.lp_top_area_with_drawer {
    &.is_drawer_open {
      @include media_query(L) {
        width: calc(100% - 550px);
        //overflow: hidden;
        overflow: initial;
      }
    }
  }
  &.cst_top_area_with_drawer {
    &.is_drawer_open {
      @include media_query(L) {
        width: calc(100% - 495px);
        // overflow: hidden;
        overflow: initial;
      }
    }
  }
  .right_section_wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &.is_drawer_open {
    @include media_query(L) {
      width: calc(100% - 550px);
      //overflow: hidden;
      overflow: initial;
    }
    // .inner_wrapper {
    //   .drawer_container {
    //     .drawer_wrapper {
    //       display: block;
    //       width: 100%;
    //       @include media_query(L) {
    //         position: relative;
    //         top: -50px;
    //         padding-top: 0;
    //       }
    //       @include media_query(M) {
    //         width: 500px;
    //       }
    //     }
    //   }
    // }
  }

  &.isManageCourse {
    @include media_query(M) {
      margin: 0;
    }
  }

  .txt {
    font-family: 'SourceSansPro';
    font-size: 16px;
    color: #949494;
  }
  .sort_by_wrapper {
    order: 1;
    @include media_query(XS) {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    @include media_query(M) {
      margin-left: 0;
      order: 0;
    }
    .txt {
      @include media_query(XS) {
        display: none;
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 700;
        color: #2c2c2c;
        text-transform: capitalize;
      }
      @include media_query(M) {
        display: none;
      }
    }
    .icon_wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 36px;
      height: 36px;
      @include media_query(XS) {
        background-color: #f4f4f4;
        border-radius: 50%;
      }
      @include media_query(M) {
        background-color: #f4f4f4;
        border-radius: 50%;
        margin-right: 10px;
      }
      .filter {
        width: 18px;
        height: 19px;
        background-image: url(../../../styles/images/filter-black.svg);
        background-repeat: no-repeat;
        cursor: pointer;
        background-size: contain;
      }
      &.selected {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: #fafafa;
        border-radius: 50%;
        .filter {
          background-image: url(../../../styles/images/filter-black.svg);
        }
      }
    }
  }
  .toggle_wrapper {
    display: flex;
    width: 120px;
    height: 32px;
    padding: 1px;
    margin-left: auto;
    border: 1px solid #2c2c2c;
    border-radius: 24px 24px 24px 24px;
    @include media_query(XS) {
      display: none;
    }
    @include media_query(M) {
      display: flex;
    }
    .card_radius {
      border-radius: 20px 0 0 20px;
    }
    .view_radius {
      border-radius: 0 20px 20px 0;
    }

    .toggle_section {
      position: relative;
      top: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: calc(100% + 2px);
      // border: 1px solid #2c2c2c;
      cursor: pointer;

      &.card_selected,
      &.view_selected {
        background-color: #2c2c2c;
        height: calc(100% + 2px);
        .card_view {
          background-size: contain;
          background-image: url(../../../styles/images/card-view-selected.svg);
        }
        .list_view {
          background-size: contain;
          background-image: url(../../../styles/images/list-view-selected.svg);
        }
      }
      &.card_selected {
        left: -1px;
        border-radius: 24px 24px 24px 24px;
      }
      &.view_selected {
        right: -1px;
        border-radius: 24px 24px 24px 24px;
      }
      .card_view {
        width: 20px;
        height: 20px;
        background-image: url(../../../styles/images/card-view.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
      .list_view {
        width: 20px;
        height: 16px;
        background-image: url(../../../styles/images/list-view.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .round_toggle_main_wrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
    order: 2;
    @include media_query(M) {
      order: 0;
      margin-right: 15px;
      margin-left: 0;
    }
    .text {
      display: none;
      font-family: 'SourceSansPro';
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #2c2c2c;
      white-space: nowrap;
      &.active {
        color: #38539a;
      }
      &.disabled {
        color: #707070;
      }
      @include media_query(M) {
        display: inline;
      }
    }
  }
  .btn_wrapper {
    // display: flex;
    align-items: center;
    margin-left: 16px;
    @include media_query(M) {
      display: flex;
      position: relative;
      height: auto;
      padding: 0 0;
    }
    &.margin_left {
      margin-left: auto;
    }
    &.btn_wrapper_xs {
      position: relative;
      //margin-left: auto;
      height: auto;
      width: auto;
      padding: 0 0;
      @include media_query(S) {
        position: fixed;
        display: flex;
        align-items: center;
        background-color: #fff;
        bottom: 0;
        left: 0;
        margin-left: 0;
        width: 100%;
        padding: 0 16px;
        height: 70px;
        z-index: 2;
        box-shadow: 0px -2px 6px rgba(44, 44, 44, 0.1);
      }
    }
    .sort_wrapper {
      // display: flex;
      align-items: center;
      width: 220px;
      height: 35px;
      border: 2px solid #2c2c2c;
      border-radius: 10px;
      padding: 0 15px;
      margin: 0 10px;
      cursor: pointer;
      .txt {
        font-family: 'SourceSansPro';
        font-size: 14px;
        color: #707070;
      }
      @include media_query(XS) {
        display: none;
      }
      @include media_query(M) {
        display: flex;
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 120px;
      background: #38539a;
      border-radius: 24px;
      padding: 0 30px;
      cursor: pointer;
      white-space: nowrap;
      @include media_query(XS) {
        width: 100%;
        height: 35px;
      }
      @include media_query(M) {
        position: relative;
        width: auto;
        margin: 0;
      }
      &.disabled {
        background-color: #d9d9d9;
        cursor: default;
        .txt {
          color: #5e5e5e;
        }
      }
      .txt {
        font-family: 'SourceSansPro';
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        padding-left: 10px;
        text-transform: capitalize;
      }
      .add {
        width: 12px;
        height: 12px;
        background-image: url(../../../styles/images/add-white.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}
