@import '../../styles/common/media_queries';

.sidenav_container {
  position: sticky;
  display: block;
  left: 0;
  // top: 0;
  // bottom: 0;
  height: 100vh;
  width: 65px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 3;
  @include media_query(S) {
    display: flex;
    position: relative;
    align-items: center;
    width: auto;
    min-height: 70px;
    height: auto;
    box-shadow: none;
  }
  @include media_query(M) {
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
  }
  @include media_query(L) {
    position: fixed;
    left: 0;
  }
  &.ipad_view_container {
    @include media_query(M) {
      display: flex;
      width: 300px;
    }
    @include media_query(L) {
      // display: flex;
      width: 65px;
    }
    .toggle_inner_wrapper {
      .toggle_blue {
        transform: rotate(0deg);
      }
    }
    .nav_items {
      .hover_state {
        @include media_query(M) {
          display: none !important;
        }
      }
      .item_wrapper {
        @include media_query(L) {
          &:hover {
            .hover_state {
              display: flex !important;
            }
          }
        }
      }
    }

    .ipad_view_wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 15px 0px 7px 0px;
      height: 100%;
      width: calc(100% - 64px);
      padding-top: 78px;
      // padding: 15px 12px;
      @include media_query(XS) {
        display: none;
      }
      @include media_query(M) {
        display: flex;
        // align-items: center;
        flex-direction: row;
        width: 100%;
        // padding: 15px 15px 15px 15px;
      }
      @include media_query(L) {
        display: none;
      }
      .navlist {
        width: 100%;
        padding: 15px 12px;
        .item {
          font-family: 'SourceSansPro';
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          color: #2c2c2c;
          margin-bottom: 57px;
          cursor: pointer;
          &:first-child {
            margin-bottom: 58px;
          }
          &.zero_margin_class {
            margin-bottom: 15px;
          }
          &.ipad_cst {
            @include media_query(M) {
              margin-bottom: 40px;
              margin-top: -5px;
            }
          }
          .list_wrapper {
            padding: 0 10px;
            .list_item {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  .ipad_view_wrapper {
    @include media_query(XS) {
      display: none;
    }
  }
  .sidenav_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px 0px 7px 0px;
    height: 100%;
    width: 64px;
    @include media_query(S) {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      padding: 15px 15px 15px 15px;
    }
  }
  .icon_wrapper {
    padding: 18px 0;
    cursor: pointer;
    @include media_query(S) {
      padding: 0;
    }
    &.notification_wrapper {
      margin: 0 10px;
      padding-bottom: 25px;
      margin-bottom: 22px;
      border-bottom: 1px solid #e4e4e4;
      @include media_query(S) {
        padding: 0;
        margin: 0;
        margin-left: auto;
        border-bottom: none;
      }
    }
    &:first-child {
      padding-top: 0;
    }
    &.toggle_arrow_wrapper {
      margin-top: 25px;
      margin-top: auto;
      @include media_query(S) {
        display: none;
      }
      @include media_query(L) {
        display: none;
      }
    }
    &.hamburger_wrapper {
      display: none;
      @include media_query(S) {
        display: flex;
        margin-left: 25px;
      }
    }
    &.on_click_hamb {
      .hb_inner_wrapper {
        display: block;
      }
      .hamburger {
        width: 15px;
        height: 15px;
        margin: auto;
        background-image: url(../../styles/images/close_icon_black.svg);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .hb_inner_wrapper {
      display: none;
      position: absolute;
      z-index: 3;
      top: 70px;
      left: 0;
      right: 0;
      bottom: 0;

      @include media_query(M) {
        display: none;
      }
      .navlist {
        padding: 30px;
        width: 100%;
        height: calc(100vh - 70px);
        background-color: #ffffff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) inset;
        .item {
          font-family: 'SourceSansPro';
          font-size: 24px;
          line-height: 28px;
          color: #2c2c2c;
          font-weight: 700;
          margin-bottom: 25px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .item_wrapper {
          &.open_list {
            .list_wrapper {
              display: block;
            }
            .nav_wraper {
              .arrow_icon {
                transform: rotate(180deg);
              }
            }
          }
          .nav_wraper {
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            .item {
              margin-bottom: 0;
            }
            .arrow_icon {
              width: 15px;
              height: 10px;
              background-image: url(../../styles/images/down_arrow_black.svg);
              background-repeat: no-repeat;
              background-size: contain;
              margin-left: auto;
            }
          }
          .list_wrapper {
            display: none;
            padding: 0 15px 15px;
            .list_item {
              font-family: 'SourceSansPro';
              font-size: 18px;
              line-height: 21px;
              color: #2c2c2c;
              font-weight: 500;
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  .hamburger {
    width: 20px;
    height: 15px;
    margin: auto;
    background-image: url(../../styles/images/hamburger.svg);
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .logo {
    width: 38px;
    height: 40px;
    background-image: url(../../styles/images/logo.svg);
    background-repeat: no-repeat;
    @include media_query(M) {
      margin: 0 15px;
    }
  }
  .notification {
    width: 18px;
    height: 20px;
    margin: auto;
    background-image: url(../../styles/images/bell.svg);
    background-repeat: no-repeat;
    &.selected {
      background-image: url(../../styles/images/notificartion_blue.svg);
    }
  }
  .notification_counter {
    height: 20px;
    min-width: fit-content;
    background-color: #38539a;
    color: white;
    border-radius: 20px;
    margin-top: -28px;
    margin-left: 30px;
    margin-bottom: 8px;
    text-align: center;
    font-family: 'SourceSansPro';
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    vertical-align: middle;
    padding: 0 6px;
    @include media_query(M) {
      margin-left: 16px;
      margin-top: -35px;
    }
  }
  .settings {
    width: 26px;
    height: 27px;
    background-image: url(../../styles/images/settings.svg);
    background-repeat: no-repeat;
    margin: auto;
  }
  .toggle_inner_wrapper {
    width: 30px;
    height: 30px;
    margin: auto;
    background-color: #38539a;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .toggle_blue {
      width: 12px;
      height: 15px;
      background-image: url(../../styles/images/toggle_right_blue.svg);
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(180deg);
    }
  }
  .nav_items {
    display: flex;
    flex-direction: column;
    height: 35rem;
    overflow-y: auto;
    overflow-x: hidden;
    @include media_query(S) {
      display: none;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .item_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      min-height: 40px;
      // padding: 20px 0;
      margin: 20px auto 15px auto;
      &:hover {
        .hover_state {
          @include media_query(L) {
            display: flex;
          }
        }
      }
      &:first-child {
        margin-top: 1px;
      }
      &.selected {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 40px;
        height: 40px;
        min-height: 40px;
        background-color: #2c2c2c;
        border-radius: 40% 60% 40% 60%;
        padding: 0;
        .home {
          height: 23px;
          background-image: url(../../styles/images/home-white.svg);
        }
        .peoples {
          height: 23px;
          background-image: url(../../styles/images/people-white.svg);
        }
        .img_gallery {
          background-image: url(../../styles/images/selected_img_gallery.svg);
        }
        .category_skills {
          background-image: url(../../styles/images/category-skills-tags-selected-side-nav.svg);
        }
        .files {
          background-image: url(../../styles/images/book_white.svg);
        }
        .folder {
          background-image: url(../../styles/images/books-white.svg);
        }
      }
      // &.home_hover {
      //   &:hover {
      //     .hover_state {
      //       display: none;
      //       @include media_query(L){
      //         display: flex;
      //       }
      //     }
      //   }
      // }
      // icon hover effect
      // &.files_hover,
      // &.folder_hover,
      // &.people_hover,
      // &.image_hover {
      //   &:hover {
      //     .hover_state {
      //       display: flex;
      //     }
      //   }
      // }
      &.plusicon_hover {
        &:hover .bottom_main_wrapper {
          @include media_query(M) {
            display: flex;
          }
        }
      }
    }
    .hover_state {
      display: flex;
      background-color: #f1f2ff;
      align-items: center;
      min-height: 40px;
      min-width: 160px;
      border-radius: 0 20px 20px 0;
      padding: 10px 20px;
      position: absolute;
      cursor: pointer;
      // top: 110px;
      left: 0px;
      white-space: nowrap;
      .icon_wrapper_sidenav {
        height: 26px;
        width: 26px;
        margin: 3px 15px 0 0;
      }
      .home_blue_icon {
        background-image: url(../../styles/images/home_blue.svg);
        background-repeat: no-repeat;
      }
      .book_blue_icon {
        background-image: url(../../styles/images/Book_blue.svg);
        background-repeat: no-repeat;
      }
      .folder_blue_icon {
        background-image: url(../../styles/images/folder_blue.svg);
        background-repeat: no-repeat;
      }
      .user_blue_icon {
        background-image: url(../../styles/images/Users_blue.svg);
        background-repeat: no-repeat;
      }
      .category_skills_blue_icon {
        background-image: url(../../styles/images/CST-Hover-image.svg);
        background-repeat: no-repeat;
      }
      .image_blue_icon {
        width: 20px;
        height: 20px;
        background-image: url(../../styles/images/image_blue.svg);
        background-repeat: no-repeat;
      }
      display: none;
    }

    .bottom_main_wrapper {
      display: flex;
      background-color: #f1f2ff;
      position: absolute;
      left: 0;

      .bottom_icon_wrapper {
        padding: 8px 20px;
        margin-top: 3px;
        .plus_blue_icon {
          height: 26px;
          width: 26px;
          margin-right: 15px;
          background-image: url(../../styles/images/Plus_blue.svg);
          background-repeat: no-repeat;
        }
      }
      .menu_wrapper {
        position: absolute;
        left: 55px;
        min-width: 170px;
        padding: 8px 15px;
        background-color: #f1f2ff;
        border-radius: 0px 20px 20px 0px;
        z-index: 1;
        .submenu_text {
          font-family: 'SourceSansPro';
          font-size: 16px;
          color: #38539a;
          font-weight: 500;
          &:first-child {
            margin-bottom: 20px;
          }
          &:hover {
            font-weight: 700;
          }
        }
      }
      display: none;
    }
    .menu_txt {
      font-family: 'SourceSansPro';
      font-size: 16px;
      line-height: 24px;
      color: #38539a;
      font-weight: 700;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .home {
      width: 25px;
      height: 22px;
      background-image: url(../../styles/images/home.svg);
      background-repeat: no-repeat;
    }
    .files {
      width: 25px;
      height: 23px;
      background-image: url(../../styles/images/files.svg);
      background-repeat: no-repeat;
    }
    .folder {
      width: 24px;
      height: 22px;
      background-image: url(../../styles/images/folder.svg);
      background-repeat: no-repeat;
    }
    .peoples {
      width: 26px;
      height: 23px;
      margin-right: -1px;
      background-image: url(../../styles/images/people.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }

    .file {
      width: 20px;
      height: 24px;
      background-image: url(../../styles/images/file.svg);
      background-repeat: no-repeat;
    }
    .img_gallery {
      width: 25px;
      height: 17px;
      background-image: url(../../styles/images/img_gallery_icon.svg);
      background-repeat: no-repeat;
    }
    .category_skills {
      width: 20px;
      height: 21px;
      background-image: url(../../styles/images/category-skills-tags-side-nav.svg);
      background-repeat: no-repeat;
    }
    .filter {
      width: 26px;
      height: 27px;
      background-image: url(../../styles/images/filter.svg);
      background-repeat: no-repeat;
    }
    .analytics {
      width: 26px;
      height: 26px;
      background-image: url(../../styles/images/analytics.svg);
      background-repeat: no-repeat;
    }
    .plus {
      width: 24px;
      height: 22px;
      background-image: url(../../styles/images/plus.svg);
      background-repeat: no-repeat;
    }
  }
  .learningPathText {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 320px;
    height: 28px;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
  }
}

// Responsive
