.card {
    border-radius: 12px;
    border: 1px solid #e4e4e4;
    background: #fff;
    padding: 16px;
    font-size: 14px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .title {
    color: #38539a;
    font-weight: 700;
    font-size: 14px;
  }
  
  .details {
    display: flex;
    justify-content: space-between;
  }
  
  .infoContainer {
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
  }
  
  .infoItem {
    span {
      display: block;
    }
  }
  
  .checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  