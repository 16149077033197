@import '../../../styles/common/media_queries';

.search_main_wrapper {
  position: relative;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e4e4e4;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }

  &.active {
    .dropdown_container,
    .search_wrapper {
      border: 2px solid #38539a;
    }
  }

  .dropdown_container {
    position: absolute;
    width: 100%;
    top: 45px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 1;
    border: 2px solid #707070;
    padding: 10px 10px 10px 0;

    .list_wrapper {
      height: 185px;
      overflow: auto;

      .list {
        display: flex;
        align-items: center;
        padding: 0 16px;
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 500;
        color: #2c2c2c;
        cursor: pointer;
        @include media_query(XS) {
          height: auto;
          margin-bottom: 20px;
        }
        @include media_query(M) {
          min-height: 35px;
          margin-bottom: 0;
        }
        &:hover {
          font-weight: 700;
        }
      }
    }
  }
}

.search_wrapper {
  display: flex;
  align-items: center;
  border: 2px solid #2c2c2c;
  border-radius: 10px;
  height: 35px;
  // width: 21rem;
  padding: 0 12px;

  @include media_query(XS) {
    //width: 15.5rem;
  }

  @include media_query(M) {
    //width: 21rem;
  }

  .icon_wrapper {
    margin-left: auto;

    .search {
      width: 18px;
      height: 18px;
      background-image: url(../../../styles/images/search-black.svg);
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  .search_input {
    background-color: transparent;
    font-family: 'SourceSansPro';
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #2c2c2c;
    width: 100%;
    border: none;
    outline: none;
    padding-right: 15px;
    display: flex;
    align-items: center;
    &.search_font_XS {
      @include media_query(XS) {
        font-size: 14px;
      }
      @include media_query(M) {
        font-size: 16px;
      }
    }
    &::placeholder {
      font-weight: 400;
      color: #707070;
    }
  }
  .close_icon {
    width: 12px;
    height: 12px;
    background-image: url(../../../styles/images/close.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }
}
