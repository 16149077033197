@import '../../../styles/common/media_queries';

.modal_wrapper {

  .delete_modal {
    position: relative;
    background-color: #fff;
    max-width: 30%;
    min-height: 150px;
    margin: auto;
    padding: 32px 16px;
    border-radius: 20px;
  }

  .header_wrapper {
    padding-bottom: 4px;
    .heading {
      font-family: 'SourceSansPro';
      font-size: 24px;
      font-weight: 700;
      color: #2c2c2c;
    }
  }

  .sub_header {
    font-size: 16px;
    color: #2C2C2C;
    margin-bottom: 24px;
  }

  .close_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    background-color: transparent;
    cursor: pointer;

    .close_icon {
      width: 14px;
      height: 16px;
      background-image: url(../../../styles/images/close.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .warning_section {
    display: flex;
    background: #fff9f0;
    border: 1px solid #d1b278;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 30px;

    .warning_icon {
      width: 18px;
      height: 18px;
      background-image: url(../../../styles/images/warning-icon.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }

    .message_section {
      padding-left: 5px;

      .mb_5 {
        margin-bottom: 5px;
      }

      .heading {
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 700;
        color: #a36a00;
      }

      .sub_heading {
        font-family: 'SourceSansPro';
        font-size: 14px;
        color: #a36a00;
      }
    }
  }

  .confirm_wrapper {
    padding-bottom: 45px;

    .txt {
      font-family: 'SourceSansPro';
      font-size: 14px;
      color: #2c2c2c;
      margin-bottom: 10px;
    }

    .input_wrapper {
      position: relative;
      .input {
        width: 100%;
        height: 35px;
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 500;
        color: #2c2c2c;
        padding: 0 10px;
        border: 2px solid #2c2c2c;
        border-radius: 10px;
        &.error_input {
          border: 2px solid #d91734;
        }
        &::placeholder {
          font-family: 'SourceSansPro';
          font-weight: 200;
          color: #707070;
        }
      }
      .error_icon {
        display: flex;
        align-items: center;
        transform: translate(-50%, 25%);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 23px;
        height: 23px;
        background-image: url(../../../styles/images/insufficient.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
      .error_msg {
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 500;
        color: #d91734;
        margin-top: 8px;
      }
    }
  }

  .confirm_msg {
    text-align: center;

    .txt {
      display: inline-block;
      vertical-align: middle;
      font-family: 'SourceSansPro';
      font-size: 16px;
      color: #2c2c2c;
    }

    .bold {
      font-family: 'SourceSansPro';
      font-weight: 700;
    }
  }

  .btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;

    .btn {
      min-width: 120px;
      height: 35px;
      font-family: 'SourceSansPro';
      font-size: 16px;
      font-weight: 700;
      border: 2px solid #2c2c2c;
      background-color: #fff;
      border-radius: 24px;
      margin: 0 6px;
      padding: 0 30px;
      cursor: pointer;

      &.active {
        background-color: #2C2C2C;
        border: 2px solid transparent;
        color: #fff;
      }

      &.save_changes {
        background-color: #2c2c2c;
        border: 2px solid transparent;
        color: #fff;
      }

      &.disabled {
        pointer-events: none;
        background-color: #d9d9d9;
        color: #5e5e5e;
        border: 2px solid transparent;
      }
    }
  }
  // }
}