@import '../../styles/common/media_queries';

.audience_mgt_wrapper {
  &.category_main_wrapper {
    width: 100%;
  }
  .search_main_wrapper {
    position: relative;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e4e4e4;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #707070;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #707070;
    }

    &.active {
      .dropdown_container,
      .search_wrapper {
        border: 2px solid #38539a;
      }
    }

    .dropdown_container {
      position: absolute;
      width: 100%;
      top: 45px;
      background-color: #fff;
      border-radius: 10px;
      z-index: 1;
      border: 2px solid #707070;
      padding: 10px 10px 10px 0;

      .list_wrapper {
        height: 185px;
        overflow: auto;

        .list {
          display: flex;
          align-items: center;
          padding: 0 16px;
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 500;
          color: #2c2c2c;
          cursor: pointer;
          @include media_query(XS) {
            height: auto;
            margin-bottom: 20px;
          }
          @include media_query(M) {
            min-height: 35px;
            margin-bottom: 0;
          }
          &:hover {
            font-weight: 700;
          }
        }
      }
    }
  }

  .search_wrapper {
    display: flex;
    align-items: center;
    border: 2px solid #2c2c2c;
    border-radius: 10px;
    height: 34px;
    // width: 21rem;
    padding: 0 15px;

    @include media_query(XS) {
      width: 15.5rem;
    }

    @include media_query(M) {
      width: 21rem;
    }

    .icon_wrapper {
      margin-left: auto;

      .search {
        width: 18px;
        height: 18px;
        background-image: url(../../styles/images/search-black.svg);
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }

    .search_input {
      background-color: transparent;
      font-family: 'SourceSansPro';
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      color: #2c2c2c;
      width: 100%;
      border: none;
      outline: none;
      padding-right: 15px;

      &::placeholder {
        font-weight: 400;
        color: #707070;
      }
    }
  }
  // EMpty screen added
  .am_empty_screen_wrapper {
    height: calc(100vh - 130px);
    padding: 25px;
    padding-top: 0;
    @include media_query(M) {
      padding: 150px 40px 40px 105px;
      height: 100vh;
    }
    .inner_wrapper_empty {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media_query(M) {
        background-color: #fafafa;
      }
    }
    .empty_screen {
      min-width: 160px;
      max-width: 250px;
      @include media_query(M) {
        min-width: 300px;
      }
      .image_wrapper {
        background-repeat: no-repeat;
        width: 140px;
        height: 270px;
        margin: 0 auto;
        margin-bottom: 20px;
        background-size: cover;
        @include media_query(M) {
          min-width: 300px;
          height: 390px;
        }

        &.am_image {
          width: 150px;
          height: 160px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url(../../styles/images/Illustration_am.svg);
          @include media_query(M) {
            width: 300px;
            height: 320px;
          }
        }
      }
    }
  }
  // common class
  .content_wrapper {
    text-align: center;
    color: #2c2c2c;
    .label {
      font-family: 'SourceSansPro';
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    .content {
      font-family: 'SourceSansPro';
      font-size: 14px;
      line-height: 18px;
    }
  }

  // End of empty screen code

  .audience_wrapper {
    @include media_query(XS) {
      margin-top: 15px;
    }

    @include media_query(M) {
      padding-left: 65px;
      padding-top: 150px;
      margin-top: 0;
    }

    @include media_query(L) {
      padding-top: 150px;
      // padding-top: 120px;
      // padding-left: 0;
    }

    // Start of isOpenDrawer
    &.is_drawer_open {
      @include media_query(L) {
        height: 100vh;
        overflow: hidden;
      }

      .top_area_wrapper {
        @include media_query(L) {
          width: calc(100% - 500px);
        }
        .search_main_wrapper {
          width: calc(100% - 345px);
          .search_wrapper {
            width: 100% !important;
          }
        }
      }

      .inner_wrapper {
        .drawer_container {
          .drawer_wrapper {
            display: block;
            width: 100%;

            @include media_query(L) {
              position: relative;
              top: -50px;
              padding-top: 0;
            }
            @include media_query(M) {
              width: 500px;
            }
          }
        }
      }
    }
    // End Of isOpenDrawer

    .inner_wrapper {
      @include media_query(XS) {
        padding: 0px;
      }

      @include media_query(L) {
        display: flex;
      }
      .table_comp_main_wrapper {
        width: 100%;
      }
      .drawer_comp_main_wrapper {
        width: 500px;
      }
    }

    .bold {
      font-family: 'SourceSansPro';
      font-weight: 700;
    }

    .arrow_left {
      width: 10px;
      height: 20px;
      background-image: url(../../styles/images/chevron-left.svg);
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .arrow_right {
      width: 10px;
      height: 20px;
      background-image: url(../../styles/images/chevron-left.svg);
      background-repeat: no-repeat;
      transform: rotate(180deg);
      cursor: pointer;
    }

    .arrow_down {
      width: 10px;
      height: 20px;
      background-image: url(../../styles/images/chevron-left.svg);
      background-repeat: no-repeat;
      transform: rotate(270deg);
      cursor: pointer;
    }

    .right_arrow {
      width: 10px;
      height: 20px;
      background-image: url(../../styles/images/chevron-left.svg);
      background-repeat: no-repeat;
      transform: rotate(270deg);
      cursor: pointer;
    }

    .down_arrow {
      width: 11px;
      height: 7px;
      background-image: url(../../styles/images/arrow-down.svg);
      background-repeat: no-repeat;
      margin-left: auto;
    }

    .ml_auto {
      margin-left: auto;
    }
  }
}