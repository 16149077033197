@import '../../../styles/common/media_queries';

.insturction_text_wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 12px;
  .info_text {
    font-family: 'SourceSansPro';
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: #2c2c2c;
    padding-right: 10px;
    cursor: pointer;
    @include media_query(M) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .left_arrow_icon {
    width: 12px;
    height: 18px;
    background-image: url(../../../styles/images/left_arrow_black.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }
}
.dropdown_container {
  // padding: 20px 0px;
  margin-bottom: 50px;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  position: relative;
  .top_bar {
    width: 100%;
    height: 50px;
    background-color: #f0f0f0;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 20px;
    cursor: pointer;
    .title {
      font-family: 'SourceSansPro';
      font-size: 14px;
      font-weight: 500;
      color: #2c2c2c;
      padding-right: 12px;

      @include media_query(M) {
        font-size: 18px;
        line-height: 21px;
      }
    }
    .info_btn_wrapper {
      position: relative;

      &.isClicked {
        .course_type_read_banner {
          display: block;
        }
      }
      .info_btn {
        width: 20px;
        height: 20px;
        background-image: url(../../../styles/images/info_button.svg);
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }

      .course_type_read_banner {
        display: none;
        position: absolute;
        top: 28px;
        right: -15px;
        width: 180px;
        min-height: 145px;
        background-color: #ffffff;
        border-radius: 12px;
        padding: 16px 5px 16px 16px;
        box-shadow: 0px 2px 16px rgb(44 44 44 / 10%);
        z-index: 1;
        .tooltip {
          position: absolute;
          top: -8px;
          right: 18px;
          width: 15px;
          height: 14px;
          background-image: url(../../../styles/images/tooltip_white_icon.svg);
          background-repeat: no-repeat;
          background-size: contain;
        }
        .title {
          font-family: 'SourceSansPro';
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;
          color: #2c2c2c;
          margin-bottom: 10px;
          @include media_query(M) {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .course_types {
          .banner_details {
            max-width: 100%;
            margin-bottom: 12px;
            &:last-child {
              margin-bottom: 0;
            }
            .item {
              font-family: 'SourceSansPro';
              font-size: 14px;
              @include media_query(M) {
                font-size: 16px;
                line-height: 21px;
              }
            }
          }
        }
      }
    }

    .toggle_arrow {
      position: absolute;
      right: 20px;
      width: 12px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: contain;
      &.big_icon {
        &.up_arrow {
          width: 12px;
          height: 18px;
        }
      }
      &.up_arrow {
        transform: rotate(90deg);
        background-image: url(../../../styles/images/left_arrow_black.svg);
      }
      &.down_arrow {
        transform: rotate(270deg);
        background-image: url(../../../styles/images/left_arrow_black.svg);
      }
    }
  }
  .list_wrapper {
    // position: absolute;
    top: 100%;
    width: 100%;
    height: auto;
    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      width: 100%;
      min-height: 65px;
      border-bottom: 1px solid #e4e4e4;
      &:last-child {
        border: none;
      }

      .instance_status {
        min-width: 95px;
        height: 25px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'SourceSansPro';
        @include media_query(M) {
          font-size: 16px;
        }
        &.live_sts {
          color: #107f47;
          background-color: #f0fae5;
        }
        &.draft_sts {
          color: #a36a00;
          background-color: #fff9f0;
        }
        &.offline_sts {
          color: #62625a;
          background-color: #f0f0f0;
        }
        &.go_live_sts {
          color: #38539a;
          background-color: #f1f2ff;
        }
        &.archive_sts {
          color: #7f3e3e;
          background-color: #ffe2e2;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e4e4e4;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }
}

.item_wrapper {
  display: flex;
  align-items: center;
  max-width: 70%;
  overflow: hidden;
  &.mt_instances {
    align-items: flex-start;
    .course_type_icon {
      margin-top: 5px;
    }
  }
  .item {
    font-family: 'SourceSansPro';
    font-size: 14px;
    font-weight: 500;
    color: #2c2c2c;
    @include media_query(M) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.course_type_icon {
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 8px;

  &.gen_visiblity_icon {
    width: 14px;
    height: 18px;
    flex-shrink: 0;
    background-image: url(../../../styles/images/gen_visibility_filled.svg);
  }
  &.qstart_icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background-image: url(../../../styles/images/qstart_icon_filled.svg);
  }
  &.req_icon {
    width: 18px;
    height: 16px;
    flex-shrink: 0;
    background-image: url(../../../styles/images/req_icon_filled.svg);
  }
  &.recom_icon {
    width: 18px;
    height: 16px;
    flex-shrink: 0;
    background-image: url(../../../styles/images/recom_icon_filled.svg);
  }
}
