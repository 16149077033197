.modal_wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(112, 112, 112, 0.7);
  justify-content: center;
  z-index: 10;
  .custom_overlay {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    position: absolute;
    background-color: white;
    min-height: 736px;
    width: 530px;
    padding: 32px;
    border-radius: 20px;
    background: var(--2-Pure-White, #fff);

    .newRecordHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px;
      .headerTitle {
        color: var(--1-Amway-Black, #2c2c2c);
        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 133.333% */
      }
      .cross_icon {
        width: 14px;
        height: 16px;
        background: url(../../../styles/images/cross_icon_slim.svg);
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }

    .form_main_wrapper {
      .error_wrapper {
        &.form_field {
          .input_wrapper {
            font-family: 'SourceSansPro';
            .input{
              border: 2px solid #d91734;
              padding-right: 35px;
            }
          }
        }
  
        .error_message {
          font-family: 'SourceSansPro';
          color: #d91734;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          padding: 4px 0;
        }
      }
      .success_wrapper{
        &.form_field {
          .input_wrapper {
            font-family: 'SourceSansPro';
            .input{
              border: 2px solid #107F47;
              padding-right: 35px;
            }
          }
        }
        .success_message {
          font-family: 'SourceSansPro';
          color: #107F47;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          padding: 4px 0;
        }
      }

      .form_field {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        &.form_field_row {
          flex-direction: row;
        }
        .label_wrapper {
          font-family: 'SourceSansPro';

          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4px;

          .label_text {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #2c2c2c;
            white-space: nowrap;
            line-height: initial;
          }
        }
        .input_wrapper {
          position: relative;
          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }

          .error_icon_red {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            width: 20px;
            height: 20px;
            background-image: url(../../../styles/images/error_icon_red.svg);
            background-size: cover;
            background-repeat: no-repeat;
          }
          .success_green_tick {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            width: 20px;
            height: 20px;
            background-image: url(../../../styles/images/green_tick_with_circle.svg);
            background-size: cover;
            background-repeat: no-repeat;
          }
          
          .input {
            width: 100%;
            height: 35px;
            border: 2px solid #2c2c2c; // #D9D9D9
            border-radius: 10px;
            color: var(--gray-33-dark-gray-text-on-white-bg, #2c2c2c);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
            padding: 0 12px;
            display: flex;
            align-items: center;
            //text-transform: capitalize;
            &::placeholder {
              color: #707070;
              font-weight: 200;
            }
            &.disabled {
              border: 3px solid #d9d9d9;
              pointer-events: none;
            }
          }
          .textarea {
            width: 100%;
            height: 70px;
            border: 2px solid #2c2c2c;
            border-radius: 10px;
            font-family: 'SourceSansPro';
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            color: #2c2c2c;
            padding: 10px 12px;
            &::placeholder {
              color: #707070;
              font-weight: 200;
            }
          }
        }
        .left_field {
          &.error_wrapper {
            .input_wrapper {
              font-family: 'SourceSansPro';
              .input{
                border: 2px solid #d91734;
                padding-right: 35px;
              }
            }
          }
          flex: 1;
        }
        .right_field {
          &.error_wrapper {
            .input_wrapper {
              font-family: 'SourceSansPro';
              .input{
                border: 2px solid #d91734;
                padding-right: 35px;
              }
            }
          }
          margin-left: 8px;
        }
      }
    }
    .btn_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      .btn {
        min-width: 120px;
        height: 48px;
        font-family: 'SourceSansPro';
        font-size: 16px;
        font-weight: 700;
        outline: none;
        border: 2px solid #2c2c2c;
        background-color: #fff;
        border-radius: 24px;
        margin: 0 6px;
        padding: 0 30px;
        cursor: pointer;
        &.active {
          background-color: #2c2c2c;
          border: 2px solid transparent;
          color: #fff;
        }

        &.save_changes {
          background-color: #2c2c2c;
          border: 2px solid transparent;
          color: #fff;
        }

        &.disabled {
          pointer-events: none;
          background-color: #d9d9d9;
          color: #5e5e5e;
          border: 2px solid transparent;
        }
      }
    }

    @media screen and (max-width: 992px) {
      position: fixed;
      right: -60vw;
      height: 100%;
      width: 60vw;
      border-radius: 0;

      &.tablet_open {
        right: 0;
      }
      .form_main_wrapper {
        .form_field {
          display: flex;
          &.form_field_row {
            flex-direction: column;
          }
          .left_field {
            margin-bottom: 16px;
          }
          .right_field {
            margin-left: 0;
          }
        }
      }
      .btn_wrapper {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 16px;
        .btn {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      height: 90vh;
      overflow-y: auto;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      min-height: 90vh;
      bottom: 0;
    }
  }
}
