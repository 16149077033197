@import '../../../styles/common/media_queries';

.notification_wrapper {
  width: 100%;
  margin: 4px 0 12px;
  font-family: 'SourceSansPro';
  .noti_bar {
    padding: 8px 10px 8px 35px;
    position: relative;
    background-color: #fff9f0;
    border: 1px solid #d1b278;
    border-radius: 12px;
    .left_icon {
      position: absolute;
      top: 12px;
      left: 10px;
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: contain;
      &.warning_filled_icon {
        height: 18px;
        background-image: url(../../../styles/images/warning_filled.svg);
      }
    }
    .text_wrapper {
      .description {
        font-family: 'SourceSansPro';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #a66400;
      }
    }
  }
}
