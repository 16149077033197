@import '../../../styles/common/media_queries';

// EMpty screen added
.empty_container {
  width: 100%;
  height: calc(100vh - 70px);
  padding: 25px;
  font-family: 'SourceSansPro';

  &.courses_screen_height {
    height: calc(100vh - 145px);
    padding-top: 0;
    @include media_query(M) {
      padding-top: 0;
      height: calc(100vh - 150px);
    }
  }
  @include media_query(M) {
    padding: 40px;
    height: 100vh;
    padding-left: 105px;
  }
  &.url_error_wrapper {
    @include media_query(M) {
      padding: 40px;
      background-color: white;
      z-index: 3;
    }
  }
}
.empty_screen_wrapper {
  width: 100%;
  height: 100%;
  background-color: #fafafa;

  // @include media_query(M){
  //   background-color: #FAFAFA;
  // }

  .inner_wrapper_empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media_query(M) {
      // background-color: #FAFAFA;
    }
  }
  .empty_screen {
    min-width: 275px;
    max-width: 290px;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    @include media_query(M) {
      min-width: 465px;
    }
    .image_wrapper {
      background-repeat: no-repeat;
      width: 140px;
      height: 270px;
      margin: 0 auto;
      margin-bottom: 20px;
      background-size: cover;
      @include media_query(M) {
        min-width: 280px;
        height: 260px;
      }

      &.empty_img {
        width: 150px;
        height: 160px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../../../styles/images/illustration_network.svg);

        @include media_query(M) {
          width: 290px;
          height: 240px;
        }
        @include media_query(L) {
          width: 360px;
          height: 300px;
        }
      }
      &.empty_img_medium {
        width: 290px;
        height: 240px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../../../styles/images/illustration_network.svg);
      }
      &.empty_img_large {
        width: 360px;
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../../../styles/images/illustration_network.svg);
      }
      &.empty_img_server_error {
        width: 150px;
        height: 160px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../../../styles/images/illustration_server.svg);
        // background-image: url(../../../styles/images/something_went_wrong.svg);
        @include media_query(M) {
          width: 290px;
          height: 240px;
        }
        @include media_query(L) {
          width: 360px;
          height: 300px;
        }
      }
      &.empty_img_server_error_medium {
        width: 290px;
        height: 240px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../../../styles/images/illustration_server.svg);
        // background-image: url(../../../styles/images/something_went_wrong.svg);
      }
      &.empty_img_server_error_large {
        width: 360px;
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(../../../styles/images/illustration_server.svg);
        // background-image: url(../../../styles/images/something_went_wrong.svg);
      }
    }
    .url_error_img {
      width: 175px;
      height: 170px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../../../styles/images/something_went_wrong.svg);
      @include media_query(M) {
        width: 280px;
        height: 270px;
      }
      @include media_query(L) {
        width: 365px;
        height: 350px;
      }
    }
    .btn_wrapper {
      min-width: 195px;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 195px;
        height: 30px;
        border-radius: 100px;
        background-color: #2c2c2c;
        color: #ffffff;
        font-family: 'SourceSansPro';
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

        @include media_query(M) {
          min-width: 210px;
          height: 36px;
        }
      }
    }
  }
}
// common class
.content_wrapper {
  text-align: center;
  color: #2c2c2c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  .label {
    font-family: 'SourceSansPro';
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    margin-bottom: 4px;
    @include media_query(M) {
      font-size: 24px;
      line-height: 28px;
    }
  }
  .content {
    font-family: 'SourceSansPro';
    font-size: 14px;
    line-height: 18px;
    @include media_query(M) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
