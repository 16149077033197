.outerDiv {
  display: flex;
  align-items: center;
  padding: 11px 0px 11px;
  width: fit-content;
  cursor: pointer;
}

.selectedCheckbox {
  background-image: url(../../../styles/images/selectedCheck.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
}

.unselectedCheckbox {
  background-image: url(../../../styles/images/unselectedCheck.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
  cursor: pointer;
}

.disabledTrueCheckbox {
  background-image: url(../../../styles/images/disabledTrueCheck.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
}

.disabledCheckbox {
  background-image: url(../../../styles/images/disabledCheck.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
}
